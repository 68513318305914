const messages = {
  // clusterBackup
  'cluster.clusterBackups.pageTitle': 'Backups',
  'cluster.clusterBackups.sectionTitle': 'Cluster backups',
  'cluster.clusterBackups.restoreButton': 'Restore',
  'cluster.clusterBackups.noBackupsFailoverText':
    'Looks like there are no backups to display yet.',
  'cluster.clusterBackups.lastDaysOption': 'Last {num} Days',
  'cluster.clusterBackups.lastWeekOption': 'Last Week',
  'cluster.clusterBackups.lastWeeksOption': 'Last {num} Weeks',
  'cluster.clusterBackups.allOption': 'All',
  'cluster.clusterBackups.restoreBackupModalTitle': 'Restore Backup',
  'cluster.clusterBackups.restoreSchemeRadioAllTitle': 'Full cluster',
  'cluster.clusterBackups.restoreSchemeRadioAllText':
    'All tables, views and users will be restored',
  'cluster.clusterBackups.restoreSchemeRadioTablesTitle': 'Choose individual tables',
  'cluster.clusterBackups.restoreSchemeRadioTablesText':
    'Only the selected tables will be restored',
  'cluster.clusterBackups.restoreSchemeRadioTablesDisabledText':
    'There are no tables to restore',
  'cluster.clusterBackups.chooseTablesTitle': 'Choose the tables to restore',
  'cluster.clusterBackups.restoreChecklistTitle': 'Before you restore',
  'cluster.clusterBackups.restoreChecklistFullClusterBreakdownText':
    'A full cluster restore will include users, views, privileges and everything else',
  'cluster.clusterBackups.restoreChecklistDiskSpaceWarningText':
    'Not all data will be copied if there is not enough disk space available on the target cluster',
  'cluster.clusterBackups.restoreChecklistExistingTablesWarningText':
    'This operation will fail if the target cluster contains any of the same table names found in this backup',
  'cluster.clusterBackups.restoreBackupModalSnippetTitle':
    'Run this restore command',
  'cluster.clusterBackups.emptyTablesFailoverText':
    'There are no tables to restore in this backup',
  'cluster.clusterBackups.tablesInBackupText':
    '{num, plural, one {# table} other {# tables}} backed up',
  'cluster.clusterBackups.showTablesInBackupButton': 'view',
  'cluster.clusterBackups.tablesInBackupModalTitle':
    '{length} tables backed up on {created}',
  'cluster.clusterBackups.cloneFromBackupButton': 'Clone',
  'cluster.clusterBackups.cloneFromBackupModalTitle':
    'Clone backup to another cluster',
  'cluster.clusterBackups.cloneFromBackupModalNoClustersText':
    'There are no other clusters to clone this cluster to. {link}',
  'cluster.clusterBackups.cloneFromBackupModalSelectPlaceholder':
    'Choose cluster to clone to',
  'cluster.clusterBackups.cloneFromBackupModalDeployNewText':
    'Need a new cluster to clone this backup to? {link}',
  'cluster.clusterBackups.cloneFromBackupModalDeployNewLink': 'Deploy a new cluster',
  'cluster.clusterBackups.cloneFromBackupModalConfirmButton':
    'Clone to selected cluster',
  'cluster.clusterBackups.cloneFromBackupFailedHelp':
    'Cloning to this cluster failed. {docsLink}',
  'cluster.clusterBackups.cloneFromBackupFailedPartial': 'Why did this happen?',
  'cluster.clusterBackups.editBackupScheduleUITitle': 'Edit backup schedule',
  'cluster.clusterBackups.editBackupScheduleButton': 'Edit backup schedule',
  'cluster.clusterBackups.selectAllOptionsButton': 'Select All',
  'cluster.clusterBackups.deselectAllOptionsButton': 'Deselect All',
  'cluster.clusterBackups.deSelectAllTimeSlotsButton': 'Deselect All',
  'cluster.clusterBackups.timeFormatHelp': 'All times are shown in UTC',
  'cluster.clusterBackups.backupStartTimeText':
    'Select your preferred time slots and a backup will be created in that hour. The precise time within the hour cannot be guaranteed.',
  'cluster.clusterBackups.allSlotsDeselectedHelp':
    'Please select at least one backup time slot for your cluster',
  'cluster.clusterBackups.noTablesSelectedForBackupWarningHelp':
    'At least one table must be selected',
  'cluster.clusterBackups.backupScheduleUpdateInProgressText':
    'Cluster backup schedule update in progress',

  // clusterOverview
  'cluster.clusterOverview.yourClusterAtAGlance': 'Your cluster at a glance',
  'cluster.clusterOverview.cpuUtilization': 'CPU utilization',
  'cluster.clusterOverview.averagePerNode': 'average per node',
  'cluster.clusterOverview.queriesPerSecond': 'Queries per second',
  'cluster.clusterOverview.planLabel': 'Plan',
  'cluster.clusterOverview.cpuLabel': 'CPU',
  'cluster.clusterOverview.nodesLabel': 'Nodes',
  'cluster.clusterOverview.upgradeText': 'Upgrade',
  'cluster.clusterOverview.crfreeLearnMoreLabel': 'Learn more',

  // next steps
  'cluster.nextSteps.title': 'Next steps',
  'cluster.nextSteps.loadingText':
    'Tips on what to do next will be available soon. Please wait while we configure your cluster.',
  'cluster.nextSteps.howToConnectLink':
    'Connecting to your CrateDB Cloud cluster from your software stack is easy. {link}',
  'cluster.nextSteps.howToConnectLinkPartial': 'View code snippets.',
  'cluster.nextSteps.learnToImportLink':
    'Import some data into your cluster using our data import tool. {link}',
  'cluster.nextSteps.exploreTutorialLink':
    'Explore our {tutorialLink} to get acquainted with CrateDB features.',
  'cluster.nextSteps.exploreTutorialLinkPartial': 'beginner tutorials',
  'cluster.nextSteps.howToImportLinkPartial': 'Import data.',
  'cluster.nextSteps.cloneClusterLink':
    'CrateDB Cloud clusters can now be cloned to a new cluster from any backup. {link}',
  'cluster.nextSteps.cloneClusterLinkPartial': 'See my backups.',

  'cluster.nextSteps.metricsAPIDocsHelp':
    'CrateDB Cloud provides a Prometheus-compatible {apiDocsLink} for cluster metrics. ' +
    'Please see {tutorialLink} for an easy step-by-step setup guide.',
  'cluster.nextSteps.metricsAPIDocsLinkPartial': 'API endpoint',
  'cluster.nextSteps.metricsTutorialLinkPartial': 'this tutorial',
  'cluster.nextSteps.connectingToYourCluster': 'Connecting to your cluster',

  // modal
  'cluster.nextSteps.howToImportTitle': 'Learn how to import data to your cluster',

  // clusterDetail
  'cluster.clusterDetail.clusterOverviewTab': 'Overview',
  'cluster.clusterDetail.clusterSql': 'SQL',
  'cluster.clusterDetail.clusterJobs': 'Jobs',
  'cluster.clusterDetail.clusterImportTab': 'Import',
  'cluster.clusterDetail.clusterExportTab': 'Export',
  'cluster.clusterDetail.clusterLearnTab': 'Tutorials',
  'cluster.clusterDetail.clusterManageTab': 'Manage',
  'cluster.clusterDetail.clusterScaleTab': 'Scale',
  'cluster.clusterDetail.clusterBackupsTab': 'Backups',
  'cluster.clusterDetail.resumeClusterButton': 'Resume cluster',
  'cluster.clusterDetail.featureAvailableOnClusterRunning':
    'The cluster must be running for this feature to be available.',
  'cluster.clusterDetail.gcConnectionError':
    'An error occured white connecting to Grand Central.',
  'cluster.clusterDetail.updatePassword': 'Update password',

  // clusterDetail notifications
  'cluster.clusterDetail.notificationAsideCRfreeClusterText':
    'This is a free-tier cluster',
  'cluster.clusterDetail.notificationAsideCRfreeClusterLink': 'What does this mean?',

  // ClusterImport
  'cluster.clusterImport.formatColumnHeaderText': 'Format',
  'cluster.clusterImport.typeColumnSourceLabel': 'Source',
  'cluster.clusterImport.typeColumnUrlLabel': 'URL',
  'cluster.clusterImport.typeColumnFileLabel': 'File',
  'cluster.clusterImport.typeColumnS3Label': 'S3',
  'cluster.clusterImport.typeColumnAzureBlobLabel': 'Azure',
  'cluster.clusterImport.dateColumnHeaderText': 'Date imported',
  'cluster.clusterImport.detailsModalTitle': '{fileName}, {date}',
  'cluster.clusterImport.showDetailsButton': 'Show details',
  'cluster.clusterImport.fileNameColumnHeaderText': 'File name',
  'cluster.clusterImport.tableNameColumnHeaderText': 'Table name',
  'cluster.clusterImport.statusColumnHeaderText': 'Status',
  'cluster.clusterImport.fileDeletedfailoverText': 'Deleted file',
  'cluster.clusterImport.formatHelpCollapseTitle':
    'Are you trying to import a {fileFormat} file?',
  'cluster.clusterImport.formatHelp':
    'Some notes for importing a {fileFormat} file:',
  'cluster.clusterImportKnownImportDomainHelpCollapseTitle':
    'Are you trying to import data from {knownDomain}?',
  'cluster.clusterImport.helpCollapseClosedButton': 'Show help',
  'cluster.clusterImport.helpCollapseOpenButton': 'Hide help',
  'cluster.clusterImport.formattingRulesJSONHint1Text':
    'JSON Documents, JSON Arrays and {link}-formatted files are supported. ',
  'cluster.clusterImport.formattingRulesJSONHint2Text':
    'JSON Lines-formatted files can be of unlimited size, but each line cannot exceed 5 MB.',
  'cluster.clusterImport.formattingRulesJSONHint3Text':
    'Non-JSON Lines documents cannot exceed 50 MB in size.',
  'cluster.clusterImport.formattingRulesJSONHint1Partial': 'JSON lines',
  'cluster.clusterImport.formattingRulesCSVHint1Text':
    'Comma, tab and pipe delimiters are supported',
  'cluster.clusterImport.unsupportedFormatText':
    '.{fileFormat} file imports are not currently supported. We support JSON, CSV (and dialects) and parquet formats.',
  'cluster.clusterImport.googleDocsSupportCSVFormatTitle':
    'If the data in your {location} is formatted as CSV',
  'cluster.clusterImport.googleDocsSupportIntroductionText':
    'There are a few steps you can take to publish CSV data from {location} so it can be imported into your CrateDB cluster.',
  'cluster.clusterImport.googleDocsSupportSteps1Text': `Open the CSV document you want to import in Google Docs.`,
  'cluster.clusterImport.googleDocsSupportSteps2Text': `From the file menu select Share → Publish to web.`,
  'cluster.clusterImport.googleDocsSupportSteps3Text': `In the modal dialog choose the “Link” tab.`,
  'cluster.clusterImport.googleDocsSupportSteps4Text': `Choose either the entire document or the specific sheet you want to import.`,
  'cluster.clusterImport.googleDocsSupportSteps5Text': `Choose .csv as the format. This step is important as without this, we will be unable to parse the document.`,
  'cluster.clusterImport.googleDocsSupportSteps6Text': `Click 'Publish'.`,
  'cluster.clusterImport.googleDocsSupportSteps7Text': `Copy the public link to the document and use this as the import URL in the Cloud Console.`,
  'cluster.clusterImport.googleDocsSupportOtherFormatTitle':
    'If the data in your Google Doc is formatted as JSON or Parquet',
  'cluster.clusterImport.dropboxSupportIntroductionText':
    'Importing files from Dropbox is possible by creating a sharing URL and altering a URL query prameter to serve the file as a download. The steps are as follows:',
  'cluster.clusterImport.dropboxSupportSteps1Text': `Navigate to the folder containing the file you want to share.`,
  'cluster.clusterImport.dropboxSupportSteps2Text': `Right click the file > "Share" > "Share with Dropbox."`,
  'cluster.clusterImport.dropboxSupportSteps3Text': `Keep the default "Anyone with this link can view" option.`,
  'cluster.clusterImport.dropboxSupportSteps4Text': `Click the "Create and copy link".`,
  'cluster.clusterImport.dropboxSupportSteps5Text': `Change the query parameter at the end of the URL from dl=0 to dl=1.`,
  'cluster.clusterImport.dropboxSupportSteps6Text': `Copy the public link to the document and use this as the import URL in the Cloud Console.`,
  'cluster.clusterImport.internalIpSupportIntroductionText':
    'Importing from internal IPs is not supported and will be blocked',

  'cluster.clusterImport.importMethodNotSupportedHelp':
    'Importing directly from {location} is not supported.',
  'cluster.clusterImport.importFromFileSystemHelp':
    'Please download the file to your local file system, and upload that file using the upload a file option from the previous screen.',
  'cluster.clusterImport.noAutomaticImportGoogleDrivePartial':
    'a JSON document or Parquet archive',
  'cluster.clusterImport.noAutomaticImportSharepointPartial': 'Sharepoint',
  'cluster.clusterImport.noAutomaticImportOneDrivePartial': 'OneDrive storage',
  'cluster.clusterImport.noAutomaticImportKagglePartial': 'datasets from Kaggle',

  'cluster.clusterImport.formattingRulesDocsText':
    'See the {link} for more details on how to import data',
  'cluster.clusterImport.docsLinkPartial': 'docs',
  'cluster.clusterImport.quickStatusSettingUpImportText': 'Preparing your import',
  'cluster.clusterImport.quickStatusImportInProgressFilesText':
    '{file} of {total} files imported',
  'cluster.clusterImport.quickStatusImportInProgressRecordsText':
    '{records} of {total} records imported',
  'cluster.clusterImport.quickStatusImportSucceededText': 'Succeeded',
  'cluster.clusterImport.quickStatusImportFailedText': 'Failed',
  'cluster.clusterImport.detailStatusImportSucceededText':
    '{records} records successfully imported from {source}.',
  'cluster.clusterImport.detailStatusImportFailedText':
    'An error occurred importing this from {source}. ',
  'cluster.clusterImport.detailStatusImportFailedImportedText':
    'Successfully imported {records} records. ',
  'cluster.clusterImport.detailStatusImportFailedFailedText':
    'Failed to import {records} records. ',
  'cluster.clusterImport.importFromFile.fileUploadFailedError':
    'There was a problem uploading the file',
  'cluster.clusterImport.importFromFile.sectionTitle': 'Import data from a file',
  'cluster.clusterImport.importFromFile.sectionDescription':
    'Uploaded files are securely stored for 3 days and then automatically removed. ',
  'cluster.clusterImport.importFromFile.sectionAdditionalDescription': 'Learn more.',
  'cluster.clusterImport.importFromFile.reimportSectionTitle':
    'Re-import a recent file',
  'cluster.clusterImport.importFromFile.selectReimportPlaceholderText':
    'Select recently uploaded file',
  'cluster.clusterImport.importFromFile.reimportedFileLabelText': '{name} ({size})',
  'cluster.clusterImport.importFromFile.uploadInProgressSectionTitle':
    'Uploading your file',
  'cluster.clusterImport.importFromFile.uploadInProgressSectionDescription':
    "This may take a few minutes, please don't refresh your browser",
  'cluster.clusterImport.importFromUrl.importFromURLButton': 'Import from a URL',
  'cluster.clusterImport.importFromUrl.sectionTitle': 'Import data from a URL',
  'cluster.clusterImport.importFromUrl.importInProgressTitle': 'Uploading your file',
  'cluster.clusterImport.importFromUrl.importInProgressText':
    "This may take a few minutes, don't refresh your browser until this process completes",
  'cluster.clusterImport.importFromUrl.failedImportCollapseTitle':
    'Why did this import fail?',
  'cluster.clusterImport.importFromUrl.maxFileSizeExceededHelp':
    '{bytes} exceeds the maximum allowed size',
  'cluster.clusterImport.importFromUrl.copyUrlSuccessMessage': 'URL copied',
  'cluster.clusterImport.chooseImportSourceTitle':
    'How would you like to import your data?',
  'cluster.clusterImport.addNewImportButton': 'Import new data',
  'cluster.clusterImport.viewExistingImportsButton': 'View existing imports',
  'cluster.clusterImport.importFromUrl.ImportLogSectionTitle': 'Import history',
  'cluster.clusterImport.importFromUrl.urlLabel': 'Import URL',
  'cluster.clusterImport.importFromUrl.urlPlaceholder':
    'https://s3.amazonaws.com/my.import.data.gz',
  'cluster.clusterImport.importFromUrl.emptyURLError': 'URL is a required field',
  'cluster.clusterImport.emptyTableError': 'Table is a required field',
  'cluster.clusterImport.importFromUrl.invalidURLError': 'Please enter a valid URL',
  'cluster.clusterImport.retryFailedImportSectionTitle': 'Retry a failed import',
  'cluster.clusterImport.tableLabel': 'Table name',
  'cluster.clusterImport.schemaEvolution': 'Schema evolution',
  'cluster.clusterImport.schemaEvolutionHelp':
    'Automatically generates table schemas from imported files. New columns are added to existing tables as needed.',
  'cluster.clusterImport.tableNamePlaceholder': 'my_import_data',
  'cluster.clusterImport.tableNameInvalidError': 'Invalid table name',
  'cluster.clusterImport.formatOptionsLabel': 'Format',
  'cluster.clusterImport.compressionOptionsLabel': 'Compression',
  'cluster.clusterImport.submitButton': 'Import',
  'cluster.clusterImport.registeringImportButton': 'Setting up import job',
  'cluster.clusterImport.removeFromHistoryButton': 'Remove from history',
  'cluster.clusterImport.importAgainButton': 'Import again',
  'cluster.clusterImport.downloadImportSummaryButton': 'Download import report',
  'cluster.clusterImport.importFromFile.fileUploadUIHelpText': 'Drag and drop or',
  'cluster.clusterImport.importFromFile.fileUploadUIButton': 'browse',
  'cluster.clusterImport.importFromFile.allowedFileSizeText': 'Max file size 1GB. ',
  'cluster.clusterImport.importFromFile.allowedFileFormatsText':
    'CSV (and dialects), JSON-Lines and Parquet files supported',
  'cluster.clusterImport.useDemoDataHelp':
    "Don't have your own data yet? {demoDataButton}",
  'cluster.clusterImport.useDemoDataPartial': 'Use our demo data set',
  'cluster.clusterImport.demoDataExplanationText':
    'The New York City {link} for July of 2019 will be imported into the given table ' +
    '(about 6.3M records). ' +
    'This may take a few minutes. ' +
    'Follow {blog_link} for some example queries you can run to explore this dataset.',
  'cluster.clusterImport.createdTableLabel': 'Auto-created table',
  'cluster.clusterImport.createdTableHelpText':
    'A table was automatically created based on the input data. The ' +
    'importer tries hard, but does not always manage to detect all the ' +
    'data types correctly. If this looks wrong, please adjust the below ' +
    'SQL and try creating the table manually.',
  'cluster.clusterImport.successful': 'Successful',
  'cluster.clusterImport.failed': 'Failed',

  'cluster.clusterImport.importFromS3.formTitle': 'Import data from an S3 bucket',
  'cluster.clusterImport.importFromS3.bucketNameField': 'Bucket name',
  'cluster.clusterImport.importFromS3.bucketNameRequiredText':
    'Bucket name is required',
  'cluster.clusterImport.importFromS3.pathToFileField': 'Path to file(s)',
  'cluster.clusterImport.importFromS3.pathToFileHelp':
    'Wildcards in folder and filenames are supported, e.g. /path/*/*.json',
  'cluster.clusterImport.importFromS3.pathToFilePlaceholder':
    '/path/to/filename.ext',
  'cluster.clusterImport.importFromS3.cannotListPathsError':
    'Could not list S3 objects: {error}',
  'cluster.clusterImport.importFromS3.pathRequiredText': 'Path is required',
  'cluster.clusterImport.importFromS3.secretField': 'Secret',
  'cluster.clusterImport.importFromS3.secretPlaceholder': 'Choose a secret',
  'cluster.clusterImport.importFromS3.secretRequiredText': 'Secret is required',
  'cluster.clusterImport.importFromS3.endpointField':
    'Endpoint (for non-AWS S3 buckets only)',
  'cluster.clusterImport.importFromS3.endpointPlaceholder': 'https://s3.example.com',
  'cluster.clusterImport.importFromS3.cannotListBucketsError':
    'Could not list buckets: {error}',

  'cluster.clusterImport.importFromAzureBlob.formTitle':
    'Import data from an Azure Storage Container',
  'cluster.clusterImport.importFromAzureBlob.containerNameField': 'Container name',
  'cluster.clusterImport.importFromAzureBlob.containerNameRequiredText':
    'Container name is required',
  'cluster.clusterImport.importFromAzureBlob.cannotListContainersError':
    'Could not list containers: {error}',
  'cluster.clusterImport.importFromAzureBlob.cannotListPathsError':
    'Could not list blobs: {error}',

  'cluster.clusterImport.clearAllButton': 'Clear completed jobs',
  'cluster.clusterImport.clearAllModalTitle': 'Clear all completed import jobs',
  'cluster.clusterImport.clearAllModalDescription':
    'Clearing jobs will remove them from your import history. It will not affect the data held in your cluster.',
  'cluster.clusterImport.clearAllInProgressText': 'Clearing completed jobs',

  // clusterExport
  'cluster.clusterExport.newExportSectionTitle': 'Export data',
  'cluster.clusterExport.newExportSectionDescription':
    'A maximum of 1GB per file will be exported. If the data in the table or view exceeds this limit, then the data will be truncated at 1GB.',
  'cluster.clusterExport.addNewExportButton': 'Export data',
  'cluster.clusterExport.relationColumnHeaderText': 'Relation name',
  'cluster.clusterExport.fileSizeColumnHeaderText': 'File size',
  'cluster.clusterExport.dateExportedColumnHeaderText': 'Date exported',
  'cluster.clusterExport.exportForm.tableOrViewLabel': 'Table or view name',
  'cluster.clusterExport.exportForm.tableOrViewPlaceholder':
    'The table or view name you wish to export',
  'cluster.clusterExport.exportForm.emptyTableNameError':
    'Table or view is a required field',
  'cluster.clusterExport.exportForm.submitButton': 'Export',
  'cluster.clusterExport.exportForm.registeringImportButton':
    'Setting up export job',
  'cluster.clusterExport.quickStatusSettingUpExportText': 'Preparing your export',
  'cluster.clusterExport.quickStatusExportInProgressRecordsText':
    '{records} records exported',
  'cluster.clusterExport.quickStatusExportSucceededText': 'Succeeded',
  'cluster.clusterExport.quickStatusExportFailedText': 'Failed',
  'cluster.clusterExport.removeExportButton': 'Remove',
  'cluster.clusterExport.showDetailsButton': 'Show details',
  'cluster.clusterExport.detailsModalTitle': '{relation}, {date}',
  'cluster.clusterExport.detailStatusExportSucceededText':
    '{records} records successfully exported.',
  'cluster.clusterExport.detailStatusExportFailedText':
    'An error occurred after exporting {records} records. ',
  'cluster.clusterExport.helpCollapseClosedButton': 'Show help',
  'cluster.clusterExport.helpCollapseOpenButton': 'Hide help',
  'cluster.clusterExport.downloadExportLink': 'Download this export',

  // cluster scale
  'cluster.clusterScale.scaleClusterButton': 'Scale cluster',
  'cluster.clusterScale.crfreeInfoText':
    'Free tier clusters cannot be scaled. They will be suspended if they are not used for 7 days, and deleted after 7 more days of inactivity.',
  'cluster.clusterScale.nonCrfreeInfoText':
    'There is no limit to the number of times you can scale your cluster, but only one dimension can be changed at a time.',
  'cluster.clusterScale.cloneToUpgradedClusterText':
    'Clone data to an upgraded cluster',
  'cluster.clusterScale.currentClusterPrice': 'Current configuration',
  'cluster.clusterScale.newClusterPrice': 'New configuration',
  'cluster.clusterScale.nodeComputeSizeTitle': 'Node compute size',
  'cluster.clusterScale.learnMoreAboutClusterTypes':
    'Learn more about cluster types',
  'cluster.clusterScale.nodeStorageSizeTitle': 'Node storage size',
  'cluster.clusterScale.crfreeIncreaseStorageInfoText':
    'To increase the storage size, upgrade to a paid cluster.',
  'cluster.clusterScale.storageCannotBeDecreasedInfoText':
    'Storage can be increased but not decreased. To decrease storage consider cloning into a new cluster.',
  'cluster.clusterScale.numberOfNodesText': 'Number of nodes',
  'cluster.clusterScale.perHour': 'per hour',
  'cluster.clusterScale.perNodePerHour': 'per node per hour',
  'cluster.clusterScale.increaseNodesRequiresDedicates':
    'To increase the number of nodes, upgrade to a dedicated cluster.',
  'cluster.clusterScale.clusterScaleInProgress':
    'Your cluster is being scaled. This may take a few minutes.',

  // clusterUpgrade
  'cluster.clusterUpgrade.currentVersionTitle': 'Current CrateDB version',
  'cluster.clusterUpgrade.availableUpgradesTitle': 'Available upgrades',
  'cluster.clusterUpgrade.upgradeButton': 'Upgrade',
  'cluster.clusterUpgrade.cannotSkipVersionsTooltip':
    'CrateDB versions can only be upgraded one minor version at a time',
  'cluster.clusterUpgrade.upgradeModalTitle': 'Upgrade to cluster {version}',
  'cluster.clusterUpgrade.upgradeModalBodyText':
    'Please be aware that cluster upgrades cannot be undone. Do you want to continue?',
  'cluster.clusterUpgrade.noAvailableUpgradesText':
    'There are no available upgrades.',
  'cluster.clusterUpgrade.releaseNotesLinkIconAltText':
    'Opens release notes for {version} in new tab.',
  'cluster.clusterUpgrade.upgradeInProgressIndicatorHelp':
    'Upgrade in progress: {status}',
  'cluster.clusterUpgrade.upgradeInProgressIndicatorNoStatusHelp':
    'Upgrade in progress.',
  'cluster.clusterUpgrade.upgradeFailedIndicatorHelp': 'Cluster upgrade failed.',

  // suspendCluster
  'cluster.suspendCluster.clusterIsSuspendedHelp': '{name} is currently suspended ', // keep the trailing space
  'cluster.suspendCluster.clusterIsSuspendedActionPrompt':
    'Go to {link} to resume cluster.',
  'cluster.suspendCluster.clusterIsSuspendedActionPromptLinkText': 'Preferences',
  'cluster.suspendCluster.suspendClusterModalTitle': 'Suspend cluster',
  'cluster.suspendCluster.suspendClusterModalText':
    'Please note that suspending a cluster can take a few minutes. Your cluster will be suspended and inaccessible, but no data will be lost. You will be charged only for storage for the duration the cluster is in a suspended state.',
  'cluster.suspendCluster.suspendClusterModalButton': 'Suspend your cluster',
  'cluster.suspendCluster.resumeClusterModalTitle': 'Resume your cluster',
  'cluster.suspendCluster.resumeClusterModalText':
    'Please note that resuming a suspended cluster can take a few minutes. Once your cluster is reactivated, your compute consumption will be billable again.',
  'cluster.suspendCluster.resumeClusterModalButton': 'Resume cluster',
  'cluster.suspendCluster.suspendClusterProgressText':
    'Your cluster is being suspended',
  'cluster.suspendCluster.resumeClusterProgressText':
    'Your cluster is being resumed',

  // manage cluster: allowlist
  'cluster.clusterManage.ipAllowlistSectionTitle': 'IP allowlist',
  'cluster.clusterManage.ipAllowlistSectionDescription':
    'Limit cluster access to specific IP addresses or CIDR blocks. Your cluster is publicly accessible if no restrictions are added.',
  'cluster.clusterManage.ipAllowlistEmptyTitle': 'IP allowlist is currently empty',
  'cluster.clusterManage.ipAllowlistCIDRColumnHeading': 'CIDR blocks',
  'cluster.clusterManage.ipAllowlistDescriptionColumnHeading': 'Description',
  'cluster.clusterManage.ipAllowlistCIDRPlaceholder': 'IP address range',
  'cluster.clusterManage.ipAllowlistCIDRErrorText': 'Invalid CIDR block',
  'cluster.clusterManage.ipAllowlistDescriptionPlaceholder': 'Name or description',
  'cluster.clusterManage.addAnotherAddressButton': 'Add another address',
  'cluster.clusterManage.addPublicIPButton': 'Add my public IP address',
  'cluster.clusterManage.saveWarningText':
    'This cluster will be accessible only from the listed IP addresses. All other IP addresses will be blocked. It may take a few minutes for these changes to take effect.',
  'cluster.clusterManage.saveEmptyWarningText':
    'Warning: this cluster will be globally accessible from any IP address. It may take a few minutes for these changes to take effect.',

  // manage cluster: upgrade
  'cluster.clusterManage.upgradeTitle': 'Upgrade CrateDB',
  'cluster.clusterManage.upgradeButtonText': 'Upgrade',
  'cluster.clusterManage.noUpgradeAvailableText':
    'This cluster is running the latest version of CrateDB ({version}) from the {channel} channel. Read the ',
  'cluster.clusterManage.noUpgradeAvailableReleaseNotesText': 'release notes',
  'cluster.clusterManage.upgradeModalText1':
    'You are about to upgrade your cluster to CrateDB {version}',
  'cluster.clusterManage.upgradeModalText2':
    'Cluster upgrades cannot be undone. Are you sure you want to continue?',
  'cluster.clusterManage.upgradeDescriptionText1':
    'Upgrading your cluster will take a few minutes. ' +
    'If you have 3 or more nodes, your cluster will not experience any downtime. ' +
    'Single and two-node clusters will be offline for the duration of the upgrade.',
  'cluster.clusterManage.upgradeDescriptionText2':
    'CrateDB versions can only be upgraded one minor version at a time and cannot be downgraded.',
  'cluster.clusterManage.upgradeDescriptionText3':
    'After upgrading to a new minor or major version, the next scheduled backup operation ' +
    'will perform a full (non-incremental) backup. This may take some time depending on ' +
    'the amount of storage used.',
  'cluster.clusterManage.currentVersionLabel': 'Current version',
  'cluster.clusterManage.targetVersionLabel': 'Target version',
  'cluster.clusterManage.releaseNotesText': 'Release notes',
  'cluster.clusterManage.upgradeInProgressText': 'Your cluster is being upgraded',
  'cluster.clusterManage.upgradeInProgressVersionText':
    'Your cluster is being upgraded to v{version}',
  'cluster.clusterManage.upgradeFailedText':
    'The recent cluster upgrade request failed. Please try again.',

  // manage cluster: request private link
  'cluster.clusterManage.privateLinksTitle': 'Private links',
  'cluster.clusterManage.privateLinksBodyText':
    '{aws} and {azure} Private Links are supported in CrateDB Cloud. A private endpoint, or private link, is a mechanism that allows a secure, private connection to your cluster. Effectively, it allows you to bypass the public internet when accessing the environment where your cluster is deployed. Read more about them {readmore}.',
  'cluster.clusterManage.privateLinksButtonText': 'Request private link',
  'cluster.clusterManage.privateLinksModalTitle': 'Request a private link',
  'cluster.clusterManage.privateLinksModalProviderLabel': 'Provider',
  'cluster.clusterManage.privateLinksModalProviderAWSOption': 'Amazon Web Services',
  'cluster.clusterManage.privateLinksModalProviderAzureOption': 'Azure',
  'cluster.clusterManage.privateLinksModalProviderRequiredText':
    'Please select your provider',
  'cluster.clusterManage.privateLinksModalMessageLabel':
    'Please tell us your AWS Account ID or Azure Subscription ID and any other instructions you may have',

  // manage cluster: suspend/resume
  'cluster.clusterManage.resumeClusterButton': 'Resume',
  'cluster.clusterManage.suspendClusterButton': 'Suspend',
  'cluster.clusterManage.resumeClusterText':
    'Resuming a suspended cluster can take a few minutes. Once your cluster is reactivated, your compute consumption will be billable again.',
  'cluster.clusterManage.suspendClusterText':
    'Suspended clusters are inaccessbile, but no data will be lost. You will be charged only for storage for the duration your cluster is in a suspended state.',
  'cluster.clusterManage.resumeClusterTitle': 'Resume Cluster',
  'cluster.clusterManage.suspendClusterTitle': 'Suspend cluster',
  'cluster.clusterManage.clusterBeingResumedText': 'Your cluster is being resumed',
  'cluster.clusterManage.clusterBeingSuspendedText':
    'Your cluster is being suspended',
  'cluster.clusterManage.clusterSuspendDisallowedText':
    'This cluster cannot be suspended.',

  // manage cluster: delete cluster
  'cluster.clusterManage.deleteClusterTitle': 'Delete cluster',
  'cluster.clusterManage.deleteClusterText':
    'Deleting a cluster cannot be undone. All data will be lost.',
  'cluster.clusterManage.deleteClusterButton': 'Delete',
  'cluster.clusterManage.clusterDeletionProtectionEnabledWarningText':
    'Deletion protection is currently enabled for this cluster.',
  'cluster.clusterManage.deleteClusterConfirmationText':
    'Confirm the deletion of this cluster by entering the name below.',
  'cluster.clusterManage.deleteProtectionWarningPartial':
    'Delete protection settings',
  'cluster.clusterManage.deleteClusterModalButton': 'Delete cluster',
  'cluster.clusterManage.deleteClusterModalTitle': 'Delete cluster',
  'cluster.clusterManage.deleteClusterInProgressText': 'Deleting cluster {name}',
  'cluster.clusterManage.deleteClusterSuccessText': 'Cluster successfully deleted',

  // manage cluster: deletion protection
  'cluster.clusterManage.deletionProtectionTitle': 'Deletion protection',
  'cluster.clusterManage.deletionProtectionEnabledText':
    'Deletion protection is currently activated for this cluster. It cannot be accidentally deleted while this enabled.',
  'cluster.clusterManage.deletionProtectionDisabledText':
    'Deletion protection is currently disabled for this cluster. Be aware that this cluster can be deleted while in this state.',

  // manage cluster: cluster credentials
  'cluster.clusterCredentials.clusterCredentialsSectionTitle': 'Credentials',
  'cluster.clusterCredentials.warnEnterClusterPasswordError':
    'Please enter a password for the cluster.',
  'cluster.clusterCredentials.passwordTooShortError':
    'Please enter {num, plural, one {# more character} other {# more characters}}.',
  'cluster.clusterCredentials.autoGeneratePasswordButton': 'Auto-generate',
  'cluster.clusterCredentials.enterNewPasswordPlaceholder': 'Enter a new password',
  'cluster.clusterCredentials.copiedToClipboardText': 'Copied',

  // cluster checklist
  'clusterChecklist.checklistTitle': 'Cluster configuration',
  'clusterChecklist.readMoreLink': 'learn more',
  'clusterChecklist.whatDoesThisMeanLink': 'what does this mean?',
  'clusterChecklist.backupsSuccessText': 'Backups enabled',
  'clusterChecklist.backupsWarningText': 'Protect your data with cluster backups',
  'clusterChecklist.crfreeText': 'This is a free-tier cluster',
  'clusterChecklist.deletionProtectionSuccessText': 'Deletion protection enabled',
  'clusterChecklist.deletionProtectionWarningText':
    'Prevent accidental cluster deletion',
  'clusterChecklist.ipAllowlistSuccessText': 'IP allowlist enabled',
  'clusterChecklist.ipAllowlistWarningText':
    'Secure your cluster with an IP allowlist',
  'clusterChecklist.notificationEmailsSuccessText': 'Notification emails enabled',
  'clusterChecklist.notificationEmailsWarningText': 'Setup notification emails',
  'clusterChecklist.paymentMethodSuccessText': 'Payment method enabled',
  'clusterChecklist.paymentMethodWarningText':
    'Add a payment method to your account',
  'clusterChecklist.versionUpgradeAvailable':
    'A CrateDB version upgrade is available',
  'clusterChecklist.versionUpgradeLink': 'upgrade',
  'clusterChecklist.unreplicatedTables': 'This cluster has unreplicated tables',
  'clusterChecklist.unreplicatedTablesModalIntroText':
    'Some of your tables do not have any replicas configured. This means that a failure of any of the nodes' +
    ' in your cluster would lead to data in these tables being unavailable.',
  'clusterChecklist.unreplicatedTablesModalText':
    'This cluster has {count, plural, one {# unreplicated table} other {# unreplicated tables}}:',
  'clusterChecklist.unreplicatedTablesModalLearnMoreText':
    'To learn more about replication, please visit our ',
  'clusterChecklist.unreplicatedTablesModalLearnMoreLink': 'documentation',

  // cluster access
  'accessCluster.openAdminUIButton': 'Open Admin UI',
  'accessCluster.connectViaAdminUISuspendedHelp':
    'The Admin UI is not accessible when your cluster is suspended.',
  'accessCluster.connectViaAdminUINoIPHelp':
    'The Admin UI is not accessible when your cluster does not have an external IP address.',
  'accessCluster.modalLearnMoreLink': 'Learn more about {what} in our {link}.',
  'accessCluster.modalLearnMoreLinkPartial': 'documentation',

  // learn
  'clusterLearn.getStartedTitle': 'CrateDB Tutorials',
  'clusterLearn.filterBySubject': 'Filter by subject',
  'clusterLearn.readingTime':
    'Reading time: {count, plural, one {# minute} other {# minutes}}',
  'clusterLearn.tags': 'Tags',
  'clusterLearn.clear': 'Clear',
  'clusterLearn.backToTutorials': 'Back to tutorials',
  'clusterLearn.backToTutorialsLinkPrefix': 'Check out our other',
  'clusterLearn.backToTutorialsLinkSuffix': 'CrateDB tutorials.',
  'clusterLearn.oneClick.oneClickTab': 'One-click Import',
  'clusterLearn.oneClick.manualTab': 'Manual',
  'clusterLearn.oneClick.introText':
    'Create the table and import all the data used in this tutorial, directly into your cluster with a single click:',
  'clusterLearn.oneClick.inProgressText': 'Data import in progress',
  'clusterLearn.oneClick.errorText':
    'Sorry, there was a problem importing data into your cluster',

  // integrations
  'clusterIntegrations.integrations': 'Integrations',
  'clusterIntegrations.requestIntegration': 'Request Integration',
  'clusterIntegrations.realTime': 'Real Time',
  'clusterIntegrations.regular': 'Regular',
  'clusterIntegrations.import': 'Import',
  'clusterIntegrations.openTutorial': 'Open tutorial',
  'clusterIntegrations.preview': 'Preview',
  'clusterIntegrations.contactUs': 'Contact us',

  'clusterIntegrations.hubspotRequestIntegration.requestIntegration':
    'Request Integration',
  'clusterIntegrations.hubspotContactUs.contactUs':
    'Request Information - {integrationName}',

  'clusterIntegrations.integrations.mongoDb.name': 'MongoDB',
  'clusterIntegrations.integrations.mongoDb.description':
    'Real-Time Integration with MongoDB (Atlas) change streams.',
  'clusterIntegrations.integrations.automatedAmazonS3.name': 'Amazon S3',
  'clusterIntegrations.integrations.automatedAmazonS3.description':
    'Automatically import data from an S3 bucket in near real-time.',
  'clusterIntegrations.integrations.amazonS3.name': 'Amazon S3',
  'clusterIntegrations.integrations.amazonS3.description':
    'Import data from an S3 bucket.',
  'clusterIntegrations.integrations.azureStorage.name': 'Azure Storage Container',
  'clusterIntegrations.integrations.azureStorage.description':
    'Import data from an Azure storage container.',
  'clusterIntegrations.integrations.debezium.name': 'Debezium',
  'clusterIntegrations.integrations.debezium.description':
    'Integrate with CDC streams from other databases using Debezium and Kafka.',
};

export default messages;
