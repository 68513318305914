import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Text } from '@crate.io/crate-gc-admin';
import ConfirmDelete from '../../../components/ConfirmDelete';
import SectionContainer from '../../../components/SectionContainer';
import { useGetClustersId, useGetOrganizationsIdClusters } from '../../../swrHooks';
import { apiDelete } from '../../../api';
import { organizationClustersList } from '../../../constants/paths';
import useMessage from '../../../hooks/useMessage';

function DeleteCluster() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clusterId, organizationId } = useParams();
  const { showLoadingMessage, showSuccessMessage } = useMessage();
  const { data: cluster } = useGetClustersId(clusterId);
  const { data: clusters, mutate: mutateClusters } =
    useGetOrganizationsIdClusters(organizationId);
  const [displayModal, setDisplayModal] = useState(false);

  const handleDeleteCluster = async () => {
    message.destroy();
    setDisplayModal(false);

    showLoadingMessage(
      formatMessage(
        { id: 'cluster.clusterManage.deleteClusterInProgressText' },
        { name: cluster.name },
      ),
    );

    const { success } = await apiDelete(`/api/v2/clusters/${cluster.id}/`);

    if (success) {
      message.destroy();

      showSuccessMessage(
        formatMessage({ id: 'cluster.clusterManage.deleteClusterSuccessText' }),
      );

      mutateClusters(
        clusters.filter(filteredCluster => filteredCluster.id !== cluster.id),
      );
      navigate(organizationClustersList.build({ organizationId }));
    }
  };

  const renderActions = () => {
    if (cluster?.deletion_protected) {
      return (
        <div className="my-2">
          <LockOutlined
            className="text-xl text-crate-border-mid"
            data-testid="protection-enabled-icon"
          />
        </div>
      );
    }

    return (
      <Button
        kind={Button.kinds.TERTIARY}
        onClick={() => {
          setDisplayModal(true);
        }}
        disabled={!cluster}
        warn
      >
        <FormattedMessage id="cluster.clusterManage.deleteClusterButton" />
      </Button>
    );
  };

  return (
    <>
      <SectionContainer
        actions={renderActions()}
        title={<FormattedMessage id="cluster.clusterManage.deleteClusterTitle" />}
      >
        <Text>
          <FormattedMessage
            id={
              cluster?.deletion_protected
                ? 'cluster.clusterManage.clusterDeletionProtectionEnabledWarningText'
                : 'cluster.clusterManage.deleteClusterText'
            }
          />
        </Text>
      </SectionContainer>

      {cluster && (
        <ConfirmDelete
          confirmText={cluster.name}
          prompt={formatMessage({
            id: 'cluster.clusterManage.deleteClusterConfirmationText',
          })}
          onCancel={() => setDisplayModal(false)}
          onConfirm={handleDeleteCluster}
          title={<FormattedMessage id="cluster.clusterManage.deleteClusterTitle" />}
          visible={displayModal}
        />
      )}
    </>
  );
}

export default DeleteCluster;
