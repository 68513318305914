import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function DisplayDate({ isoDate, format }) {
  return <>{moment(isoDate).format(format)}</>;
}

DisplayDate.propTypes = {
  isoDate: PropTypes.string.isRequired,
  format: PropTypes.string,
};

DisplayDate.defaultProps = {
  format: 'MMMM Do YYYY, HH:mm',
};

export default DisplayDate;
