import Path from 'path-parser';

export const root = new Path('/');

const organizationRoot = '/account/organizations/:organizationId';
const clusterRoot = `${organizationRoot}/projects/:projectId/clusters/:clusterId`;

// account setup
export const acceptInvite = new Path('/accept-invite/');
export const accountIndexPage = new Path('/account');
export const accountSettings = new Path('/account/settings');
export const azureUnauthorized = new Path('/azure/unauthorized');
export const createOrganization = new Path('/account/create-organization');
export const marketplaceSetup = new Path(
  '/account/marketplace-setup/:subscriptionId',
);

// cluster
export const clusterOverview = new Path(`${clusterRoot}/overview`);
export const clusterSQLConsole = new Path(`${clusterRoot}/sql`);
export const clusterAutomation = new Path(`${clusterRoot}/automation`);
export const clusterIntegrations = new Path(`${clusterRoot}/integrations`);
export const clusterImport = new Path(`${clusterRoot}/import`);
export const clusterExport = new Path(`${clusterRoot}/export`);
export const clusterBackup = new Path(`${clusterRoot}/backup`);
export const clusterScale = new Path(`${clusterRoot}/scale`);
export const clusterManage = new Path(`${clusterRoot}/manage`);
export const clusterTutorials = new Path(`${clusterRoot}/tutorials`);

// deploy
export const deployOrganizationCluster = new Path(`${organizationRoot}/deploy`);

// organization
export const organizationAuditLogs = new Path(`${organizationRoot}/audit-logs`);
export const organizationBilling = new Path(`${organizationRoot}/billing`);
export const organizationClustersList = new Path(`${organizationRoot}/clusters`);
export const organizationRegions = new Path(`${organizationRoot}/regions`);
export const organizationSettings = new Path(`${organizationRoot}/settings`);
export const organizationUsage = new Path(`${organizationRoot}/usage`);
export const organizationUsers = new Path(`${organizationRoot}/users`);
