import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Text } from '@crate.io/crate-gc-admin';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import stripeLogo from '../../../assets/logo_stripe_purple.svg';
import { PAYMENT_PROVIDER_BUTTON_TEST_ID } from '../../../constants/defaults';
import { paymentMethodPropType } from '../../../models';
import useMessage from '../../../hooks/useMessage';
import { apiPost } from '../../../api';
import HubspotFormRequestEnableBankTransfer from '../../HubspotForms/HubspotFormRequestEnableBankTransfer';

function BankTransferButton({
  disabled,
  isSelected,
  isAvailable,
  onClick,
  paymentMethod,
  onSubmitCallback,
}) {
  const [showRequestBankTransferModal, setShowRequestBankTransferModal] =
    useState(false);

  const { organizationId } = useParams();
  const { formatMessage } = useIntl();
  const { showLoadingMessage, showSuccessMessage } = useMessage();

  const { subscription } = paymentMethod;

  const styles = cx(
    'border border-crate-border-mid duration-300 flex items-center mb-2 px-6 py-4 rounded text-neutral-800 text-left transition-opacity w-full disabled:text-neutral-500 disabled:border-crate-border-mid/50',
    {
      'bg-crate-blue/10': isSelected,
      'hover:border-crate-blue hover:bg-crate-blue/5': !isSelected && isAvailable,
      'hover:cursor-default border-crate-border-mid/50': !isAvailable,
    },
  );

  const handleConfirm = async () => {
    showLoadingMessage(
      formatMessage({ id: 'organization.paymentMethods.addingBankTransferText' }),
    );

    const { success } = await apiPost(
      `/api/v2/stripe/bank-transfer/organizations/${organizationId}/setup/`,
    );

    if (success) {
      showSuccessMessage(
        formatMessage({
          id: 'organization.paymentMethods.addingBankTransferSuccessText',
        }),
      );
    }

    onSubmitCallback(success);
  };

  return (
    <>
      {!isAvailable ? (
        <div
          className={styles}
          data-testid={PAYMENT_PROVIDER_BUTTON_TEST_ID.STRIPE_BANK_TRANSFER}
        >
          <img
            src={stripeLogo}
            className="mr-5 h-14 w-16 object-contain opacity-60 grayscale"
            alt="Stripe logo"
          />

          {/* Payment method is not available */}
          <div className="flex w-full items-center justify-between gap-4">
            <div>
              <Text pale>
                <FormattedMessage id="subscriptions.bankTransfer.thisSubscriptionIsDisabledText" />
              </Text>
            </div>

            <Button
              kind={Button.kinds.TERTIARY}
              size={Button.sizes.SMALL}
              onClick={() => {
                setShowRequestBankTransferModal(true);
              }}
            >
              <FormattedMessage id="subscriptions.bankTransfer.askToEnable" />
            </Button>
          </div>
        </div>
      ) : (
        <button
          className={styles}
          data-testid={PAYMENT_PROVIDER_BUTTON_TEST_ID.STRIPE_BANK_TRANSFER}
          disabled={disabled}
          onClick={() => {
            if (subscription) {
              onClick(paymentMethod);
            } else {
              handleConfirm();
            }
          }}
          type="button"
        >
          <img
            src={stripeLogo}
            className={cx('mr-5 h-14 w-16 object-contain', {
              'opacity-60 grayscale': disabled,
            })}
            alt="Stripe logo"
          />

          {/* Payment disabled */}
          {disabled && (
            <Text pale truncate>
              <FormattedMessage id="subscriptions.bankTransfer.useThisSubscriptionText" />
            </Text>
          )}

          {/* Payment enabled and a subscription exists */}
          {!disabled && (
            <Text truncate>
              <FormattedMessage id="deploy.choosePaymentProvider.useBankTransferText" />
            </Text>
          )}
        </button>
      )}

      <Modal
        footer={null}
        onCancel={() => setShowRequestBankTransferModal(false)}
        open={showRequestBankTransferModal}
        title={<FormattedMessage id="subscriptions.enableBankTransferModal.title" />}
      >
        <HubspotFormRequestEnableBankTransfer
          onFormComplete={() => setShowRequestBankTransferModal(false)}
          onFormCancel={() => setShowRequestBankTransferModal(false)}
          hiddenFields={{
            cluster_configuration: [`organization: ${organizationId}`].join('\n'),
          }}
        />
      </Modal>
    </>
  );
}

BankTransferButton.propTypes = {
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isAvailable: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onSubmitCallback: PropTypes.func.isRequired,
  paymentMethod: paymentMethodPropType.isRequired,
};

BankTransferButton.defaultProps = {
  disabled: false,
  isSelected: false,
  isAvailable: false,
};

export default BankTransferButton;
