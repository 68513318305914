import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, message, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, NoDataView } from '@crate.io/crate-gc-admin';
import noData from '../../../assets/no_data.svg';
import awsLogo from '../../../assets/logo_aws.svg';
import azureLogo from '../../../assets/logo_azure_2021.svg';
import gcpLogo from '../../../assets/logo_gcp.svg';
import GlobalLoading from '../../../components/GlobalLoading';
import useMessage from '../../../hooks/useMessage';
import {
  useGetOrganizations,
  useGetSubscriptionsId,
  useGetUsersMe,
} from '../../../swrHooks';
import { apiPatch, apiPost } from '../../../api';
import {
  DEFAULT_PLAN_TYPE,
  SUBSCRIPTION_PROVIDER,
} from '../../../constants/defaults';
import { organizationClustersList } from '../../../constants/paths';
import TestSWRIsFetching from '../../../components/TestSWRIsFetching';

function MarketplaceSetup() {
  const { subscriptionId } = useParams();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { showLoadingMessage, showSuccessMessage } = useMessage();
  const [orgCreationInProgress, setOrgCreationInProgress] = useState(false);
  const [assignSubscriptionInProgress, setAssignSubscriptionInProgress] =
    useState(false);

  const {
    data: user,
    isValidating: userIsValidating,
    mutate: mutateUser,
  } = useGetUsersMe();

  const {
    data: organizations,
    isValidating: organizationsIsValidating,
    mutate: mutateOrganizations,
  } = useGetOrganizations();
  const {
    data: subscription,
    isValidating: subscriptionIsValidating,
    mutate: mutateSubscription,
  } = useGetSubscriptionsId(subscriptionId);

  const assignSubscriptionToOrganization = useCallback(
    async organizationId => {
      message.destroy();

      const { success } = await apiPatch(
        `/api/v2/subscriptions/${subscriptionId}/assign-org/`,
        { organization_id: organizationId },
      );

      if (success) {
        showSuccessMessage(
          formatMessage({
            id: 'account.subscription.marketplaceSubscriptionConnected',
          }),
        );

        // try/catch here to prevent inconsistent test fails
        // with the old version of msw
        try {
          // mutate the subscription and organization
          await mutateSubscription();
          await mutateOrganizations();
        } catch (e) {
          //
        }

        // redirect to the selected org's cluster list
        navigate(organizationClustersList.build({ organizationId }));
      }
    },
    [
      formatMessage,
      navigate,
      mutateOrganizations,
      mutateSubscription,
      showSuccessMessage,
      subscriptionId,
    ],
  );

  const createOrganization = useCallback(
    async organizationName => {
      showLoadingMessage(
        formatMessage(
          { id: 'account.createOrganization.creationInProgress' },
          { name: organizationName },
        ),
      );

      const { success, data: newOrganization } = await apiPost(
        '/api/v2/organizations/',
        {
          name: organizationName,
          plan_type: DEFAULT_PLAN_TYPE,
        },
      );

      message.destroy();

      if (success) {
        showSuccessMessage(
          formatMessage(
            { id: 'account.createOrganization.createdSuccessfully' },
            { name: newOrganization.name },
          ),
        );

        await mutateUser();
        await mutateOrganizations([...organizations, newOrganization]);
      }
    },
    [
      formatMessage,
      mutateOrganizations,
      mutateUser,
      organizations,
      showLoadingMessage,
      showSuccessMessage,
    ],
  );

  const getProviderLogo = provider => {
    if (provider === SUBSCRIPTION_PROVIDER.AWS) {
      return awsLogo;
    }
    if (provider === SUBSCRIPTION_PROVIDER.AZURE) {
      return azureLogo;
    }
    if (provider === SUBSCRIPTION_PROVIDER.GCP) {
      return gcpLogo;
    }
    return noData;
  };

  useEffect(() => {
    // do nothing until we have all the data we need
    if (!organizations || !subscription || !user) {
      return;
    }

    // the user is not assigned to an organization, so create one.
    // when this completes, swr mutates, and this useEffect will
    // re-run with organizations.length === 1
    if (organizations.length === 0 && !orgCreationInProgress) {
      setOrgCreationInProgress(true);
      createOrganization(`${user.email}'s Organization`);
      return;
    }

    // the user is assigned to a single organization. auto-assign
    // this subscription to that organization
    if (organizations.length === 1 && !assignSubscriptionInProgress) {
      setAssignSubscriptionInProgress(true);
      assignSubscriptionToOrganization(organizations[0].id);
    }

    // in the case where the user is assigned to more than one
    // organization, do nothing. the user will be prompted to
    // manually select an org from the UI below
  }, [
    assignSubscriptionInProgress,
    assignSubscriptionToOrganization,
    createOrganization,
    organizations,
    orgCreationInProgress,
    setOrgCreationInProgress,
    subscription,
    user,
  ]);

  // if the user belongs to more than one organization, show
  // a menu for them to choose which org
  if (organizations && subscription && user && organizations.length > 1) {
    return (
      <div className="m-auto max-w-[356px]">
        <NoDataView
          description={
            <FormattedMessage
              id="account.selectOrganization.formText"
              values={{ provider: subscription.provider }}
            />
          }
          imageSrc={getProviderLogo(subscription.provider)}
        >
          <Form
            layout="horizontal"
            onFinish={formValues => {
              assignSubscriptionToOrganization(formValues.organizationId);
            }}
            aria-label="select organization form"
            data-testid="org-select-form"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Form.Item name="organizationId" initialValue={organizations[0].id}>
              <Select
                aria-label="organizationId"
                options={organizations.map(organization => ({
                  label: organization.name,
                  value: organization.id,
                }))}
              />
            </Form.Item>
            <Form.Item>
              <Button kind={Button.kinds.PRIMARY} type={Button.types.SUBMIT}>
                <FormattedMessage id="account.selectOrganization.formSubmitButton" />
              </Button>
            </Form.Item>
          </Form>
        </NoDataView>
        <TestSWRIsFetching
          fetchStatusList={[
            organizationsIsValidating,
            subscriptionIsValidating,
            userIsValidating,
          ]}
        />
      </div>
    );
  }

  // show the loading screen while the useEffects handle
  // the logic of what to do next
  return (
    <GlobalLoading>
      <TestSWRIsFetching
        fetchStatusList={[
          organizationsIsValidating,
          subscriptionIsValidating,
          userIsValidating,
        ]}
      />
    </GlobalLoading>
  );
}

export default MarketplaceSetup;
