import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Heading, Text } from '@crate.io/crate-gc-admin';
import { CRATEDB_CLOUD_IMPORT_DATA_DOCS } from '../../../../constants/links';
import dropboxLogo from '../../../../assets/logo_dropbox.svg';
import googleDocsLogo from '../../../../assets/logo_google_docs.png';
import googleDriveLogo from '../../../../assets/logo_google_drive.png';
import kaggleLogo from '../../../../assets/logo_kaggle.svg';
import microsoftLogo from '../../../../assets/microsoft_logo.svg';

const KNOWN_IMPORT_DOMAINS = {
  DROPBOX: 'dropbox',
  GOOGLE_DOCS: 'docs.google',
  GOOGLE_DRIVE: 'drive.google',
  INTERNAL_IP: 'internalIP',
  KAGGLE: 'kaggle',
  ONE_DRIVE: 'onedrive',
  SHAREPOINT: 'sharepoint',
};

const KNOWN_IMPORT_DOMAINS_TO_DISPLAY_NAMES = {
  [KNOWN_IMPORT_DOMAINS.GOOGLE_DOCS]: 'Google Docs',
  [KNOWN_IMPORT_DOMAINS.GOOGLE_DRIVE]: 'Google Drive',
  [KNOWN_IMPORT_DOMAINS.DROPBOX]: 'Dropbox',
  [KNOWN_IMPORT_DOMAINS.INTERNAL_IP]: 'an internal IP',
  [KNOWN_IMPORT_DOMAINS.KAGGLE]: 'Kaggle',
  [KNOWN_IMPORT_DOMAINS.ONE_DRIVE]: 'Microsoft OneDrive',
  [KNOWN_IMPORT_DOMAINS.SHAREPOINT]: 'Microsoft Sharepoint',
};

const ALIGNMENT_OPTIONS = {
  DIALOG: 'DIALOG',
  FILE_OBJECT: 'FILE_OBJECT',
  SECTION: 'SECTION',
};

const alignmentOptionsMap = {
  [ALIGNMENT_OPTIONS.DIALOG]: 'pl-0',
  [ALIGNMENT_OPTIONS.FILE_OBJECT]: 'pl-[18px]',
  [ALIGNMENT_OPTIONS.SECTION]: 'pl-3',
};

const KNOWN_IMPORT_DOMAINS_HELP = {
  [KNOWN_IMPORT_DOMAINS.GOOGLE_DOCS]: {
    icon: (
      <img
        alt="Google Docs logo"
        className="-ml-0.5 mr-1.5 w-5"
        src={googleDocsLogo}
      />
    ),
    instructions: (
      <div data-testid="google-docs-instructions">
        <Heading className="mb-2" level={Heading.levels.h4}>
          <FormattedMessage
            id="cluster.clusterImport.googleDocsSupportCSVFormatTitle"
            values={{
              location: 'Google Docs',
            }}
          />
        </Heading>
        <Text className="mb-2 max-w-xl" pale>
          <FormattedMessage
            id="cluster.clusterImport.googleDocsSupportIntroductionText"
            values={{
              location: 'Google Docs',
            }}
          />
        </Text>
        <ol className="mb-4 list-decimal pl-8 text-neutral-500">
          {[...Array(7).keys()].map(index => (
            <li key={`cluster.clusterImport.googleDocsSupportSteps${index + 1}Text`}>
              <FormattedMessage
                id={`cluster.clusterImport.googleDocsSupportSteps${index + 1}Text`}
              />
            </li>
          ))}
        </ol>
        <Heading className="mb-2" level={Heading.levels.h4}>
          <FormattedMessage id="cluster.clusterImport.googleDocsSupportOtherFormatTitle" />
        </Heading>
        <Text className="mb-4 max-w-xl" pale>
          <FormattedMessage id="cluster.clusterImport.importFromFileSystemHelp" />
        </Text>
      </div>
    ),
  },
  [KNOWN_IMPORT_DOMAINS.GOOGLE_DRIVE]: {
    icon: (
      <img
        alt="Google Drive logo"
        className="-ml-0.5 mr-1.5 w-5"
        src={googleDriveLogo}
      />
    ),
    instructions: (
      <div data-testid="google-drive-instructions">
        <Heading className="mb-2" level={Heading.levels.h4}>
          <FormattedMessage
            id="cluster.clusterImport.googleDocsSupportCSVFormatTitle"
            values={{
              location: 'Google Drive',
            }}
          />
        </Heading>
        <Text className="mb-2 max-w-xl" pale>
          <FormattedMessage
            id="cluster.clusterImport.googleDocsSupportIntroductionText"
            values={{
              location: 'Google Drive',
            }}
          />
        </Text>
        <ol className="mb-4 list-decimal pl-8 text-neutral-500">
          {[...Array(7).keys()].map(index => (
            <li key={`cluster.clusterImport.googleDocsSupportSteps${index + 1}Text`}>
              <FormattedMessage
                id={`cluster.clusterImport.googleDocsSupportSteps${index + 1}Text`}
              />
            </li>
          ))}
        </ol>
        <Heading className="mb-2" level={Heading.levels.h4}>
          <FormattedMessage id="cluster.clusterImport.googleDocsSupportOtherFormatTitle" />
        </Heading>
        <Text className="mb-4 max-w-xl" pale>
          <Text className="mb-4 max-w-xl" pale>
            <FormattedMessage id="cluster.clusterImport.importFromFileSystemHelp" />
          </Text>
        </Text>
      </div>
    ),
  },
  [KNOWN_IMPORT_DOMAINS.DROPBOX]: {
    icon: (
      <img alt="Dropbox logo" className="-ml-0.5 mr-1.5 w-5" src={dropboxLogo} />
    ),
    instructions: (
      <div data-testid="dropbox-instructions">
        <Text className="mb-2 max-w-xl" pale>
          <FormattedMessage id="cluster.clusterImport.dropboxSupportIntroductionText" />
        </Text>
        <ol className="mb-4 list-decimal pl-8 text-neutral-500">
          {[...Array(6).keys()].map(index => (
            <li key={`cluster.clusterImport.dropboxSupportSteps${index + 1}Text`}>
              <FormattedMessage
                id={`cluster.clusterImport.dropboxSupportSteps${index + 1}Text`}
              />
            </li>
          ))}
        </ol>
      </div>
    ),
  },
  [KNOWN_IMPORT_DOMAINS.INTERNAL_IP]: {
    icon: <QuestionCircleOutlined className="mr-2 text-base text-neutral-600" />,
    instructions: (
      <div data-testid="internalIp-instructions">
        <Text className="mb-2 max-w-xl" pale>
          <FormattedMessage id="cluster.clusterImport.internalIpSupportIntroductionText" />
        </Text>
      </div>
    ),
  },
  [KNOWN_IMPORT_DOMAINS.KAGGLE]: {
    icon: <img alt="Kaggle logo" className="-ml-3 w-20" src={kaggleLogo} />,

    instructions: (
      <div data-testid="kaggle-instructions">
        <Text className="mb-2 max-w-xl" pale>
          <FormattedMessage
            id="cluster.clusterImport.importMethodNotSupportedHelp"
            values={{
              location: (
                <FormattedMessage id="cluster.clusterImport.noAutomaticImportKagglePartial" />
              ),
            }}
          />
        </Text>
      </div>
    ),
  },
  [KNOWN_IMPORT_DOMAINS.ONE_DRIVE]: {
    icon: (
      <img alt="Microsoft logo" className="-ml-0.5 mr-1.5 w-5" src={microsoftLogo} />
    ),
    instructions: (
      <div data-testid="onedrive-instructions">
        <Text className="mb-2 max-w-xl" pale>
          <FormattedMessage
            id="cluster.clusterImport.importMethodNotSupportedHelp"
            values={{
              location: (
                <FormattedMessage id="cluster.clusterImport.noAutomaticImportOneDrivePartial" />
              ),
            }}
          />{' '}
          <FormattedMessage id="cluster.clusterImport.importFromFileSystemHelp" />
        </Text>
      </div>
    ),
  },
  [KNOWN_IMPORT_DOMAINS.SHAREPOINT]: {
    icon: (
      <img alt="Microsoft logo" className="-ml-0.5 mr-1.5 w-5" src={microsoftLogo} />
    ),
    instructions: (
      <div data-testid="sharepoint-instructions">
        <Text className="mb-2 max-w-xl" pale>
          <FormattedMessage
            id="cluster.clusterImport.importMethodNotSupportedHelp"
            values={{
              location: (
                <FormattedMessage id="cluster.clusterImport.noAutomaticImportSharepointPartial" />
              ),
            }}
          />{' '}
          <FormattedMessage id="cluster.clusterImport.importFromFileSystemHelp" />
        </Text>
      </div>
    ),
  },
};

const { Panel } = Collapse;

function KnownImportDomainHelp({ alignment, knownDomain }) {
  const [helpIsVisible, setHelpIsVisible] = useState(false);

  return (
    <Collapse onChange={() => setHelpIsVisible(!helpIsVisible)}>
      <Panel
        header={
          <div
            className={cx(
              'flex',
              'items-center',
              'justify-between',
              alignmentOptionsMap[alignment],
            )}
            data-testid="known-import-host-pre-import-help"
          >
            <div className="flex items-center">
              {KNOWN_IMPORT_DOMAINS_HELP[knownDomain].icon}
              <div className="text-neutral-500">
                <FormattedMessage
                  id="cluster.clusterImportKnownImportDomainHelpCollapseTitle"
                  values={{
                    knownDomain: KNOWN_IMPORT_DOMAINS_TO_DISPLAY_NAMES[knownDomain],
                  }}
                />
              </div>
            </div>
            <div className="text-crate-blue">
              {helpIsVisible ? (
                <FormattedMessage id="cluster.clusterImport.helpCollapseOpenButton" />
              ) : (
                <FormattedMessage id="cluster.clusterImport.helpCollapseClosedButton" />
              )}
            </div>
          </div>
        }
        key="1"
        showArrow={false}
      >
        <div className="px-2">
          {KNOWN_IMPORT_DOMAINS_HELP[knownDomain].instructions}
        </div>
        <Text className="px-2" pale>
          <FormattedMessage
            id="cluster.clusterImport.formattingRulesDocsText"
            values={{
              link: (
                <a
                  href={CRATEDB_CLOUD_IMPORT_DATA_DOCS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="cluster.clusterImport.docsLinkPartial" />
                </a>
              ),
            }}
          />
        </Text>
      </Panel>
    </Collapse>
  );
}

KnownImportDomainHelp.propTypes = {
  // the alignment prop
  // is a workaround for the scenario when the KnownImportDomainHelp
  // component is displayed underneath the attached file and we
  // want the icons of both components to neatly align, vertically
  alignment: PropTypes.oneOf(Object.values(ALIGNMENT_OPTIONS)),
  knownDomain: PropTypes.oneOf(Object.values(KNOWN_IMPORT_DOMAINS)),
};

KnownImportDomainHelp.defaultProps = {
  alignment: ALIGNMENT_OPTIONS.DIALOG,
  knownDomain: KNOWN_IMPORT_DOMAINS.DROPBOX,
};

export default KnownImportDomainHelp;
