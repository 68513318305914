import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { head, isEmpty } from 'lodash/fp';
import { FormattedMessage } from 'react-intl';
import { Button } from '@crate.io/crate-gc-admin';
import {
  useGetClustersId,
  useGetClustersIdExportjobs,
  useGetOrganizationsIdFiles,
} from '../../../swrHooks';
import ConstrainWidth from '../../../components/ConstrainWidth/ConstrainWidth';
import LoadingContainer from '../../../components/LoadingContainer';
import ExportJobsList from './ExportJobsList';
import ViewContainerActions from '../../../components/ViewContainerActions';
import CreateExportForm from './CreateExportForm/CreateExportForm';
import { apiDelete, apiPost } from '../../../api';
import { OPERATION_STATES } from '../../../constants/defaults';
import {
  getClusterAsyncDeployInProgress,
  getClusterAsyncResumeInProgress,
  getClusterAsyncSuspendInProgress,
} from '../../../utils/data/cluster';
import { useAnalytics } from '../../../hooks';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';

function ClusterExport() {
  const { clusterId, organizationId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);
  const { trackEvent } = useAnalytics();

  // isCreatingExportJob is the intermediate state when the form has
  // been submitted but we are still waiting for an export job object
  // to be returned from the API so we can show the export jobs list.
  const [isCreatingExportJob, setIsCreatingExportJob] = useState(false);

  const [exportJourneyIsActive, setExportJourneyIsActive] = useState(false);

  const { data: exportJobs, mutate: mutateExportJobs } =
    useGetClustersIdExportjobs(clusterId);

  const [prevLatestExportJobStatus, setPrevLatestExportJobStatus] = useState(null);

  const { mutate: mutateFiles } = useGetOrganizationsIdFiles(organizationId);

  const clusterHasNoExportJobs = isEmpty(exportJobs);

  const latestExportJob = useMemo(() => head(exportJobs), [exportJobs]);

  const isCreateExportJobBlocked =
    cluster?.suspended ||
    getClusterAsyncResumeInProgress(cluster) ||
    getClusterAsyncSuspendInProgress(cluster) ||
    getClusterAsyncDeployInProgress(cluster);

  useEffect(() => {
    if (
      latestExportJob?.status === OPERATION_STATES.SUCCEEDED &&
      latestExportJob?.status !== prevLatestExportJobStatus
    ) {
      // refetch files only when an export job succeeds, so the files get
      // synced only once.
      mutateFiles()
        .then(() => mutateExportJobs())
        .catch(() => {});
    }
    setPrevLatestExportJobStatus(latestExportJob?.status);
  }, [latestExportJob, mutateFiles, prevLatestExportJobStatus, mutateExportJobs]);

  const handleExportFlowCancel = () => {
    setExportJourneyIsActive(false);
  };

  const handleExportFormFinish = async ({ compression, format, source }) => {
    setIsCreatingExportJob(true);
    trackEvent(USER_TRACKING_EVENTS.CLICKED_EXPORT_DATA);

    const { success, data } = await apiPost(
      `/api/v2/clusters/${clusterId}/export-jobs/`,
      {
        compression,
        destination: {
          format,
        },
        source,
      },
    );

    if (success) {
      mutateExportJobs([data, ...exportJobs]);
      setExportJourneyIsActive(false);
      setIsCreatingExportJob(false);
    }
  };

  const handleExportJobDelete = async exportJob => {
    await apiDelete(`/api/v2/clusters/${clusterId}/export-jobs/${exportJob.id}/`);

    mutateExportJobs(
      exportJobs.filter(existingexportJob => existingexportJob.id !== exportJob.id),
    );
  };

  return (
    <ConstrainWidth>
      <LoadingContainer
        loading={!exportJobs}
        render={() => (
          <>
            {!clusterHasNoExportJobs && (
              <ViewContainerActions>
                {exportJourneyIsActive ? (
                  <Button
                    kind={Button.kinds.TERTIARY}
                    onClick={handleExportFlowCancel}
                  >
                    <FormattedMessage id="common.cancel" />
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setExportJourneyIsActive(true);
                    }}
                  >
                    <FormattedMessage id="cluster.clusterExport.addNewExportButton" />
                  </Button>
                )}
              </ViewContainerActions>
            )}

            {/* no previous export jobs to list: show new export form */}
            {(exportJourneyIsActive || clusterHasNoExportJobs) && (
              <CreateExportForm
                isCreatingExportJob={isCreatingExportJob}
                onFormFinish={handleExportFormFinish}
                isCreateExportJobBlocked={isCreateExportJobBlocked}
              />
            )}

            {/* previous export jobs to list: show export jobs */}
            {!exportJourneyIsActive && !clusterHasNoExportJobs && (
              <ExportJobsList
                exportJobs={exportJobs}
                onDelete={handleExportJobDelete}
              />
            )}
          </>
        )}
      />
    </ConstrainWidth>
  );
}

export default ClusterExport;
