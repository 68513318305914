import React from 'react';
import PropTypes from 'prop-types';
import GlobalLoadingAnimation from './GlobalLoadingAnimation';

function GlobalLoading({ children }) {
  return (
    <div
      className="fixed left-0 top-0 z-50 h-screen w-screen bg-neutral-800"
      data-testid="loading"
    >
      <GlobalLoadingAnimation>{children}</GlobalLoadingAnimation>
    </div>
  );
}

GlobalLoading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

GlobalLoading.defaultProps = {
  children: null,
};

export default GlobalLoading;
