import { crashCodeSnippet, psqlCodeSnippet } from './cli';
import { javaCodeSnippet } from './java';
import {
  javaScriptNodeCrateCodeSnippet,
  javaScriptNodePostgresCodeSnippet,
} from './javaScript';
import {
  pythonAIOPGCodeSnippet,
  pythonAsyncPGCodeSnippet,
  pythonCratePythonCodeSnippet,
  pythonCratePythonPandasCodeSnippet,
  pythonCratePythonSqlAlchemyCodeSnippet,
  pythonPsycopg2CodeSnippet,
  pythonPsycopg3AsyncCodeSnippet,
  pythonPsycopg3SyncCodeSnippet,
} from './python';
import rubyCrateRubyCodeSnippet from './ruby';
import { phpPdoCodeSnippet, phpDbalCodeSnippet } from './php';
import {
  edgeK8sCodeSnippet,
  edgeK8sOutsideClusterCodeSnippet,
  edgeK8sExposedNodeCodeSnippet,
} from './edge';
import { Cluster } from '../../../types';

const connectToClusterCodeSnippets = [
  {
    displayName: 'Edge',
    language: 'http',
    intro: (
      <>
        <p>
          It looks like your cluster does not have a IP address assigned to it.
          CrateDB Edge creates a Kubernetes service of <code>type=LoadBalancer</code>{' '}
          and expects there to be an implementation that will give your service an
          external IP address.
        </p>
        <p>
          If that is not the case, you can still access your cluster in one of the
          following ways:
        </p>
      </>
    ),
    snippets: [
      {
        name: 'From within the kubernetes cluster, connect using this service name:',
        code: edgeK8sCodeSnippet,
        docsLink: null, // 'https://cratedb.com',
      },
      {
        name: 'From outside of the kubernetes cluster, you can port-forward to one of the pods:',
        code: edgeK8sOutsideClusterCodeSnippet,
        docsLink: null,
      },
      {
        name: 'Or access the service exposed on one of the Kubernetes nodes:',
        code: edgeK8sExposedNodeCodeSnippet,
        docsLink: null,
      },
    ],
    reachableClusterOnly: false,
  },
  {
    displayName: 'Parameters',
    language: 'plaintext',
    snippets: [
      {
        name: 'Host',
        code: (cluster: Cluster) => cluster.fqdn.slice(0, -1),
      },
      {
        name: 'Port (PostgreSQL)',
        code: () => '5432',
        docsLinkName: 'the PostgreSQL interface',
        docsLink:
          'https://cratedb.com/docs/crate/reference/en/latest/interfaces/postgres.html',
      },
      {
        name: 'Port (HTTPS)',
        code: () => '4200',
        docsLinkName: 'the HTTPS interface',
        docsLink:
          'https://cratedb.com/docs/crate/reference/en/latest/interfaces/http.html',
      },
      {
        name: 'Database',
        code: () => 'crate',
      },
      {
        name: 'Username',
        code: (cluster: Cluster) => cluster.username,
      },
      {
        name: 'Password',
        password: true,
      },
    ],
    reachableClusterOnly: true,
  },
  {
    displayName: 'CLI',
    language: 'bash',
    snippets: [
      {
        name: 'Crash',
        code: crashCodeSnippet,
        docsLink: 'https://cratedb.com/docs/crate/crash/en/latest/',
      },
      {
        name: 'psql',
        code: psqlCodeSnippet,
        docsLink: 'https://www.postgresql.org/docs/current/app-psql.html',
      },
    ],
    reachableClusterOnly: true,
  },
  {
    displayName: 'Python',
    language: 'python',
    snippets: [
      {
        name: 'crate-python',
        code: pythonCratePythonCodeSnippet,
        docsLink: 'https://cratedb.com/docs/python/en/latest/index.html',
      },
      {
        name: 'crate-python-sqlalchemy',
        code: pythonCratePythonSqlAlchemyCodeSnippet,
        docsLink: 'https://cratedb.com/docs/python/en/latest/sqlalchemy.html',
      },
      {
        name: 'crate-python-pandas',
        code: pythonCratePythonPandasCodeSnippet,
        docsLink: 'https://pandas.pydata.org/',
      },
      {
        name: 'psycopg2',
        code: pythonPsycopg2CodeSnippet,
        docsLink: 'https://www.psycopg.org/docs/',
      },
      {
        name: 'psycopg3-sync',
        code: pythonPsycopg3SyncCodeSnippet,
        docsLink: 'https://www.psycopg.org/psycopg3/docs/',
      },
      {
        name: 'psycopg3-async',
        code: pythonPsycopg3AsyncCodeSnippet,
        docsLink: 'https://www.psycopg.org/psycopg3/docs/advanced/async.html',
      },
      {
        name: 'aiopg',
        code: pythonAIOPGCodeSnippet,
        docsLink: 'https://aiopg.readthedocs.io/',
      },
      {
        name: 'asyncpg',
        code: pythonAsyncPGCodeSnippet,
        docsLink: 'https://magicstack.github.io/asyncpg/current/',
      },
    ],
    reachableClusterOnly: true,
  },
  {
    displayName: 'Ruby',
    language: 'ruby',
    snippets: [
      {
        name: 'crate_ruby',
        code: rubyCrateRubyCodeSnippet,
        docsLink: 'https://github.com/crate/crate_ruby',
      },
    ],
    reachableClusterOnly: true,
  },
  {
    displayName: 'Java',
    language: 'java',
    snippets: [
      {
        name: 'JDBC',
        code: javaCodeSnippet,
        docsLink: 'https://cratedb.com/docs/jdbc/en/latest/index.html',
      },
    ],
    reachableClusterOnly: true,
  },
  {
    displayName: 'Node.js',
    language: 'javascript',
    snippets: [
      {
        name: 'node-postgres',
        code: javaScriptNodePostgresCodeSnippet,
        docsLink: 'https://www.npmjs.com/package/pg',
      },
      {
        name: 'node-crate',
        code: javaScriptNodeCrateCodeSnippet,
        docsLink: 'https://www.npmjs.com/package/node-crate',
      },
    ],
    reachableClusterOnly: true,
  },
  {
    displayName: 'PHP',
    language: 'php',
    snippets: [
      {
        name: 'PDO',
        code: phpPdoCodeSnippet,
        docsLink: 'https://cratedb.com/docs/pdo/en/latest/',
      },
      {
        name: 'DBAL',
        code: phpDbalCodeSnippet,
        docsLink: 'https://cratedb.com/docs/dbal/en/latest/',
      },
    ],
    reachableClusterOnly: true,
  },
] as const;

export default connectToClusterCodeSnippets;
