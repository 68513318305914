import React, { useState } from 'react';
import { Modal } from 'antd';
import { CopyOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Heading, Text } from '@crate.io/crate-gc-admin';
import CopyToClipboard from '../CopyToClipboard';

function ShowTemporaryCredentials({
  username,
  password,
  clearCredentialsCallback,
  translationPrefix,
}) {
  const [showCredentialsVisible, setShowCredentialsVisible] = useState(true);

  const [passwordIsVisible, setPasswordIsVisible] = useState(false);

  const handleCancel = () => {
    setShowCredentialsVisible(false);
    clearCredentialsCallback();
  };

  const togglePassword = () => {
    setPasswordIsVisible(!passwordIsVisible);
  };

  return (
    <Modal
      onCancel={handleCancel}
      open={showCredentialsVisible}
      title={
        <FormattedMessage id={`${translationPrefix}.creationInProgressTitle`} />
      }
      okButtonProps={{ visible: false }}
      cancelButtonProps={{ disabled: false }}
      footer={[
        <Button key="close" onClick={handleCancel}>
          <FormattedMessage id="common.close" />
        </Button>,
      ]}
      width={900}
    >
      <FormattedMessage id={`${translationPrefix}.credentialsHelp`} />
      <Heading className="mb-2 mt-6" level={Heading.levels.h4}>
        <FormattedMessage id={`${translationPrefix}.username`} />
      </Heading>
      <div className="my-2.5 flex w-full justify-between rounded-sm bg-neutral-100 p-2.5">
        <Text>{username}</Text>
        <CopyToClipboard textToCopy={username}>
          <div className="mr-2 flex items-center text-crate-blue">
            <CopyOutlined className="mr-1" />
            <Text displayAs={Text.elements.span}>
              <FormattedMessage id="common.copy" />
            </Text>
          </div>
        </CopyToClipboard>
      </div>

      <Heading className="mb-2 mt-6" level={Heading.levels.h4}>
        <FormattedMessage id={`${translationPrefix}.password`} />
      </Heading>
      <div className="my-2.5 flex w-full justify-between rounded-sm bg-neutral-100 p-2.5">
        <Text
          className={cx({
            relative: !passwordIsVisible,
            'top-1': !passwordIsVisible,
          })}
        >
          {passwordIsVisible ? password : '•'.repeat(password.length)}
        </Text>
        <span>
          <button
            className="mr-4 inline-flex items-center text-crate-blue"
            onClick={togglePassword}
            type="button"
          >
            {passwordIsVisible ? (
              <EyeInvisibleOutlined className="mr-1" />
            ) : (
              <EyeOutlined className="mr-1" />
            )}
            <Text displayAs={Text.elements.span}>
              <FormattedMessage
                id={passwordIsVisible ? 'common.hide' : 'common.show'}
              />
            </Text>
          </button>
          <CopyToClipboard textToCopy={password}>
            <span className="inline-flex items-center text-crate-blue">
              <CopyOutlined className="mr-1" />
              <Text displayAs={Text.elements.span}>
                <FormattedMessage id="common.copy" />
              </Text>
            </span>
          </CopyToClipboard>
        </span>
      </div>
    </Modal>
  );
}

ShowTemporaryCredentials.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  clearCredentialsCallback: PropTypes.func.isRequired,
  translationPrefix: PropTypes.string,
};

ShowTemporaryCredentials.defaultProps = {
  translationPrefix: 'showTemporaryCredentials',
};

export default ShowTemporaryCredentials;
