import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutoComplete, Checkbox, Divider, Form, Input, Select, Space } from 'antd';
import { Button } from '@crate.io/crate-gc-admin';

import AddSecretDrawer from '../../../organization/OrganizationSettings/AddSecretDrawer';
import SectionContainer from '../../../../components/SectionContainer';
import InfoPopover from '../../../../components/InfoPopover';
import ImportSourceSwitch from '../ImportSourceSwitch';
import SupportedFormatHelp from '../SupportedFormatHelp';
import UnsupportedFormatHelp from '../UnsupportedFormatHelp';
import {
  useGetIntegrationsAzureBlobStorageContainers,
  useGetOrganizationsIdSecrets,
  useGetIntegrationsAzureBlobs,
} from '../../../../swrHooks';
import {
  COMPRESSION_OPTIONS,
  FORMAT_OPTIONS,
  INGEST_JOURNEY_STATES,
  STEVEDORE_FILE_FORMATS,
  UNSUPPORTED_FILE_TYPES,
  FILE_COMPRESSION_FORMATS,
} from '../constants';
import { FILE_NAME_REGEXP } from '../../../../constants/regex';
import { importJobPropType } from '../../../../models';
import TestSWRIsFetching from '../../../../components/TestSWRIsFetching';
import { SECRET_TYPES } from '../../../../constants/defaults';
import INPUT_SANITIZATION from '../../../../constants/inputSanitization';

function AzureBlobImportForm({
  changeIngestSource,
  isCreatingImportJob,
  jobToRetry,
  onFormFinish,
  organizationId,
  isCreateImportJobBlocked,
}) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const selectedSecretId = Form.useWatch('secret', form);
  const selectedContainer = Form.useWatch('container_name', form);
  const [addSecretDrawerVisible, setAddSecretDrawerVisible] = useState(false);
  const [supportedFileFormat, setSupportedFileFormat] = useState(
    STEVEDORE_FILE_FORMATS.JSON,
  );
  const [unsupportedFileFormat, setUnsupportedFileFormat] = useState(false);
  const { data: secrets, isValidating: isSecretsValidating } =
    useGetOrganizationsIdSecrets(organizationId);
  const { data: containerNames, isValidating: loadingContainerNames } =
    useGetIntegrationsAzureBlobStorageContainers(selectedSecretId);
  const [pathPrefix, setPathPrefix] = useState();
  const { data: paths, isValidating: loadingPaths } = useGetIntegrationsAzureBlobs(
    selectedSecretId,
    selectedContainer,
    pathPrefix,
  );
  const [pathOptions, setPathOptions] = useState([]);

  const supportedFileTypes = useMemo(
    () => Object.values(STEVEDORE_FILE_FORMATS),
    [],
  );
  const unsupportedFileTypes = useMemo(
    () => Object.values(UNSUPPORTED_FILE_TYPES),
    [],
  );
  const supportedCompressionTypes = useMemo(
    () => Object.values(FILE_COMPRESSION_FORMATS),
    [],
  );

  const handleFormatChange = value => {
    setSupportedFileFormat(value);
    setUnsupportedFileFormat(null);
  };

  const handlePathChange = value => {
    const enteredPath = value.toLowerCase();
    const fileMatch = enteredPath.match(FILE_NAME_REGEXP);

    if (
      supportedFileTypes.includes(fileMatch?.groups.format) &&
      !supportedCompressionTypes.includes(fileMatch?.groups.format)
    ) {
      form.setFieldsValue({
        format: fileMatch.groups.format,
        compression: fileMatch.groups.compression
          ? STEVEDORE_FILE_FORMATS.GZIP
          : 'none',
      });
      setSupportedFileFormat(fileMatch.groups.format);
    } else if (unsupportedFileTypes.includes(fileMatch?.groups.format)) {
      setUnsupportedFileFormat(fileMatch.groups.format);
    } else {
      setSupportedFileFormat(null);
      setUnsupportedFileFormat(null);
    }
  };

  useEffect(() => {
    if (jobToRetry && jobToRetry.type === 'azureblob') {
      form.setFieldsValue({
        container_name: jobToRetry.azureblob.container_name,
        compression: jobToRetry.compression,
        blob_name: jobToRetry.azureblob.blob_name,
        format: jobToRetry.format,
        secret: jobToRetry.azureblob.secret_id,
        table: jobToRetry.destination.table,
      });

      if (supportedFileTypes.includes(jobToRetry.format)) {
        setSupportedFileFormat(jobToRetry.format);
      }
    }

    return function clearformFieldsOnUnmount() {
      form.resetFields();
    };
  }, [form, jobToRetry, supportedFileTypes]);

  useEffect(() => {
    const containers = containerNames?.containers;
    if (containers && containers.length === 1) {
      // only one bucket, just pre-fill the field.
      form.setFields([
        {
          name: 'container_name',
          errors: null,
          value: containerNames.containers[0].name,
        },
      ]);
    } else if (containers && containers.length > 1) {
      const current = form.getFieldValue('container_name');
      const matches = containerNames.containers
        .map(b => b.name)
        .filter(v => v.startsWith(current));

      // current typed in value not in list of buckets, reset
      if (matches.length === 0) {
        form.setFields([{ name: 'container_name', value: '' }]);
      }
    }
  }, [form, containerNames]);

  const handleKeyDrawerClosed = secret => {
    setAddSecretDrawerVisible(false);
    form.setFieldsValue({
      secret,
    });
  };

  const customDropDownRender = menu => {
    return (
      <>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <Space
          style={{
            padding: '0 10px 4px',
          }}
        >
          <Button
            onClick={() => setAddSecretDrawerVisible(true)}
            kind={Button.kinds.TERTIARY}
            type="button"
          >
            <FormattedMessage id="organization.secrets.addSecretButton" />
          </Button>
        </Space>
      </>
    );
  };

  const getContainerNames = () => {
    if (!containerNames) {
      return null;
    }

    return containerNames.containers?.map(container => ({
      label: container.name,
      value: container.name,
    }));
  };

  const getPathOptions = (searchText = null) => {
    if (!paths) {
      return null;
    }

    let filteredPaths = 'blobs' in paths ? paths.blobs : [];
    if (searchText) {
      filteredPaths = filteredPaths.filter(path => path.startsWith(searchText));
    }

    // Retrieve again the list of files names when:
    // - searchText is not the last used prefix which means that we don't have a relevant list of files
    // - the list of blobs might not contain all possible options (because of the 5k limit)
    if (
      !searchText.startsWith(pathPrefix ?? '') ||
      (paths.blobs.length >= 5000 && filteredPaths.length <= 8)
    ) {
      setPathPrefix(searchText);
      filteredPaths = 'blobs' in paths ? paths.blobs : [];
    }

    return filteredPaths.slice(0, 8).map(blob => ({
      label: blob,
      value: blob,
    }));
  };

  return (
    <>
      <SectionContainer
        actions={
          <ImportSourceSwitch
            changeIngestSource={changeIngestSource}
            currentIngestSource={INGEST_JOURNEY_STATES.AZUREBLOB_INGEST_SELECTED}
          />
        }
        title={
          <FormattedMessage id="cluster.clusterImport.importFromAzureBlob.formTitle" />
        }
      >
        <Form
          autoComplete="off"
          disabled={isCreatingImportJob}
          form={form}
          initialValues={{
            compression: 'none',
            format: 'json',
          }}
          layout="vertical"
          name="import-data-from-azureblob-form"
          id="import-data-from-azureblob-form"
          aria-label="import data from azureblob form"
          onFinish={onFormFinish}
        >
          <div className="grid grid-cols-2 gap-x-4 lg:grid-cols-4">
            {/* secret */}
            <div className="col-span-2 flex items-center justify-between gap-2">
              <Form.Item
                className="w-full"
                label={
                  <FormattedMessage id="cluster.clusterImport.importFromS3.secretField" />
                }
                name="secret"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'cluster.clusterImport.importFromS3.secretRequiredText',
                    }),
                  },
                ]}
              >
                <Select
                  options={secrets
                    ?.filter(secret => secret.type === SECRET_TYPES.AZURE)
                    .map(secret => ({
                      label: `${secret.name} (${secret.description})`,
                      value: secret.id,
                    }))}
                  loading={!secrets}
                  onChange={value => {
                    setPathOptions([]);
                    form.setFieldsValue({ secret: value });
                  }}
                  name={
                    <FormattedMessage id="cluster.clusterImport.importFromS3.secretField" />
                  }
                  placeholder={
                    <FormattedMessage id="cluster.clusterImport.importFromS3.secretPlaceholder" />
                  }
                  dropdownRender={customDropDownRender}
                />
              </Form.Item>
            </div>

            {/* table name */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={<FormattedMessage id="cluster.clusterImport.tableLabel" />}
              name="table"
              rules={[INPUT_SANITIZATION.TABLE_NAME]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.tableNamePlaceholder',
                })}
              />
            </Form.Item>

            {/* container name */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={
                <FormattedMessage id="cluster.clusterImport.importFromAzureBlob.containerNameField" />
              }
              validateStatus={loadingContainerNames ? 'validating' : 'success'}
              hasFeedback={loadingContainerNames}
              name="container_name"
            >
              <AutoComplete
                options={getContainerNames()}
                filterOption={(inputValue, option) =>
                  option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
                }
                notFoundContent={
                  containerNames?.error && (
                    <FormattedMessage
                      id="cluster.clusterImport.importFromAzureBlob.cannotListContainersError"
                      values={{ error: containerNames.error }}
                    />
                  )
                }
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.importFromAzureBlob.containerNameField',
                })}
              />
            </Form.Item>

            {/* format */}
            <Form.Item
              label={
                <FormattedMessage id="cluster.clusterImport.formatOptionsLabel" />
              }
              name="format"
            >
              <Select onChange={handleFormatChange} options={FORMAT_OPTIONS} />
            </Form.Item>

            {/* compression */}
            <Form.Item
              label={
                <FormattedMessage id="cluster.clusterImport.compressionOptionsLabel" />
              }
              name="compression"
            >
              <Select options={COMPRESSION_OPTIONS} />
            </Form.Item>

            {/* file path */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={
                <FormattedMessage id="cluster.clusterImport.importFromS3.pathToFileField" />
              }
              help={
                <FormattedMessage id="cluster.clusterImport.importFromS3.pathToFileHelp" />
              }
              validateStatus={loadingPaths ? 'validating' : 'success'}
              hasFeedback={loadingPaths}
              name="blob_name"
              rules={[INPUT_SANITIZATION.GENERIC_FILE_PATH]}
            >
              <AutoComplete
                options={pathOptions}
                onSearch={value => setPathOptions(getPathOptions(value))}
                onFocus={event => setPathOptions(getPathOptions(event.target.value))}
                onChange={value => {
                  setPathOptions(getPathOptions(value));
                  handlePathChange(value);
                }}
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.importFromS3.pathToFilePlaceholder',
                })}
                filterOption={(inputValue, option) =>
                  option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
                }
                notFoundContent={
                  paths?.error && (
                    <FormattedMessage
                      id="cluster.clusterImport.importFromAzureBlob.cannotListPathsError"
                      values={{ error: paths.error }}
                    />
                  )
                }
              />
            </Form.Item>

            {/* create or update table */}
            <Form.Item
              className="col-span-2"
              colon={false}
              name="create_table"
              initialValue
              valuePropName="checked"
            >
              <div className="mt-8">
                <Checkbox defaultChecked>
                  <FormattedMessage id="cluster.clusterImport.schemaEvolution" />
                </Checkbox>
                <InfoPopover
                  content={
                    <div>
                      <FormattedMessage id="cluster.clusterImport.schemaEvolutionHelp" />
                    </div>
                  }
                  title={
                    <FormattedMessage id="cluster.clusterImport.schemaEvolution" />
                  }
                />
              </div>
            </Form.Item>

            {/* submit button */}
            <div className="col-span-2 pt-8 text-right lg:col-span-4">
              <Button
                disabled={isCreateImportJobBlocked}
                loading={isCreatingImportJob}
                form="import-data-from-azureblob-form"
                type={Button.types.SUBMIT}
              >
                {isCreatingImportJob ? (
                  <FormattedMessage id="cluster.clusterImport.registeringImportButton" />
                ) : (
                  <FormattedMessage id="cluster.clusterImport.submitButton" />
                )}
              </Button>
            </div>
          </div>
        </Form>
      </SectionContainer>
      {supportedFileFormat && (
        <SupportedFormatHelp fileFormat={supportedFileFormat} />
      )}
      {unsupportedFileFormat && (
        <UnsupportedFormatHelp fileFormat={unsupportedFileFormat} />
      )}
      <AddSecretDrawer
        onClose={handleKeyDrawerClosed}
        organizationId={organizationId}
        isVisible={addSecretDrawerVisible}
        secretType={SECRET_TYPES.AZURE}
      />
      <TestSWRIsFetching
        fetchStatusList={[isSecretsValidating, loadingPaths, loadingContainerNames]}
      />
    </>
  );
}

AzureBlobImportForm.propTypes = {
  changeIngestSource: PropTypes.func.isRequired,
  isCreatingImportJob: PropTypes.bool.isRequired,
  jobToRetry: importJobPropType,
  onFormFinish: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  isCreateImportJobBlocked: PropTypes.bool,
};

AzureBlobImportForm.defaultProps = {
  jobToRetry: null,
  isCreateImportJobBlocked: false,
};

export default AzureBlobImportForm;
