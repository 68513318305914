import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Text } from '@crate.io/crate-gc-admin';
import { CRATEDB_CLOUD_IMPORT_DATA_DOCS } from '../../../../constants/links';
import { UNSUPPORTED_FILE_TYPES } from '../constants';

const ALIGNMENT_OPTIONS = {
  DIALOG: 'DIALOG',
  FILE_OBJECT: 'FILE_OBJECT',
  SECTION: 'SECTION',
};
const alignmentOptionsMap = {
  [ALIGNMENT_OPTIONS.DIALOG]: 'pl-0',
  [ALIGNMENT_OPTIONS.FILE_OBJECT]: 'pl-[18px]',
  [ALIGNMENT_OPTIONS.SECTION]: 'pl-3',
};

const { Panel } = Collapse;

function UnsupportedFormatHelp({ alignment, fileFormat }) {
  const [rulesAreVisible, setRulesAreVisible] = useState(false);
  return (
    <Collapse onChange={() => setRulesAreVisible(!rulesAreVisible)}>
      <Panel
        header={
          <div
            className={cx(
              'flex',
              'items-center',
              'justify-between',
              alignmentOptionsMap[alignment],
            )}
            data-testid="unsupported-formatting-pre-import-help"
          >
            <div className="flex items-center">
              <QuestionCircleOutlined className="mr-2 text-base text-neutral-600" />
              <div className="text-neutral-500">
                <FormattedMessage
                  id="cluster.clusterImport.formatHelpCollapseTitle"
                  values={{
                    fileFormat,
                  }}
                />
              </div>
            </div>
            <div className="text-crate-blue">
              {rulesAreVisible ? (
                <FormattedMessage id="cluster.clusterImport.helpCollapseOpenButton" />
              ) : (
                <FormattedMessage id="cluster.clusterImport.helpCollapseClosedButton" />
              )}
            </div>
          </div>
        }
        key="1"
        showArrow={false}
      >
        <div className="px-4">
          <Text className="mb-2" pale>
            <FormattedMessage
              id="cluster.clusterImport.unsupportedFormatText"
              values={{
                fileFormat,
              }}
            />
          </Text>
        </div>

        <Text className="px-4" pale>
          <FormattedMessage
            id="cluster.clusterImport.formattingRulesDocsText"
            values={{
              link: (
                <a
                  href={CRATEDB_CLOUD_IMPORT_DATA_DOCS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="cluster.clusterImport.docsLinkPartial" />
                </a>
              ),
            }}
          />
        </Text>
      </Panel>
    </Collapse>
  );
}

UnsupportedFormatHelp.propTypes = {
  // the alignment prop
  // is a workaround for the scenario when the UnsupportedFormatHelp
  // component is displayed underneath the attached file and we
  // want the icons of both components to neatly align, vertically
  alignment: PropTypes.oneOf(Object.values(ALIGNMENT_OPTIONS)),
  fileFormat: PropTypes.oneOf(Object.values(UNSUPPORTED_FILE_TYPES)),
};

UnsupportedFormatHelp.defaultProps = {
  alignment: ALIGNMENT_OPTIONS.DIALOG,
  fileFormat: null,
};

export default UnsupportedFormatHelp;
