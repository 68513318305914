import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Text } from '@crate.io/crate-gc-admin';
import amexLogo from '../../../assets/logo_amex.svg';
import discoverLogo from '../../../assets/logo_discover.svg';
import jcbLogo from '../../../assets/logo_jcb.svg';
import mastercardLogo from '../../../assets/logo_mastercard.svg';
import stripeLogo from '../../../assets/logo_stripe_purple.svg';
import unionpayLogo from '../../../assets/logo_unionpay.svg';
import visaLogo from '../../../assets/logo_visa.svg';
import { PAYMENT_PROVIDER_BUTTON_TEST_ID } from '../../../constants/defaults';
import { paymentMethodPropType } from '../../../models';

function BankCardButton({ disabled, isSelected, onClick, paymentMethod }) {
  const { card } = paymentMethod;
  const styles = cx(
    'border border-crate-border-mid duration-300 flex items-center mb-2 px-6 py-4 rounded text-neutral-800 text-left transition-opacity w-full disabled:text-neutral-500 disabled:border-crate-border-mid/50',
    {
      'bg-crate-blue/10': isSelected,
      'hover:border-crate-blue hover:bg-crate-blue/5': !isSelected,
    },
  );

  const paymentNetworkLogos = {
    amex: amexLogo,
    discover: discoverLogo,
    jcb: jcbLogo,
    mastercard: mastercardLogo,
    unionpay: unionpayLogo,
    visa: visaLogo,
  };

  return (
    <button
      className={styles}
      data-testid={PAYMENT_PROVIDER_BUTTON_TEST_ID.STRIPE}
      disabled={disabled}
      onClick={() => onClick(paymentMethod)}
      type="button"
    >
      {paymentMethod.is_setup && (
        <div className="flex items-center">
          <img
            src={paymentNetworkLogos[card.brand] || stripeLogo}
            className={`mr-5 h-12 w-16 object-contain ${disabled ? 'opacity-60 grayscale' : ''}`}
            alt={`Payment method logo: ${card.brand}`}
            data-testid="payment-method-logo"
          />
          <div className="ml-4 truncate text-left">
            <div>
              <FormattedMessage
                id="organization.paymentMethods.bankCardLast4"
                values={{ last4: card.last4 }}
              />
            </div>
            <div>
              <FormattedMessage
                id="organization.paymentMethods.bankCardExpiry"
                values={{
                  month: card.exp_month,
                  year: card.exp_year,
                }}
              />
            </div>
          </div>
        </div>
      )}
      {!paymentMethod.is_setup && (
        <>
          <img
            src={stripeLogo}
            className={`mr-5 h-12 w-16 object-contain ${disabled ? 'opacity-60 grayscale' : ''}`}
            alt="Stripe logo"
          />
          <Text className="max-w-[310px] text-left">
            <FormattedMessage id="deploy.choosePaymentProvider.useCreditCardText" />
          </Text>
        </>
      )}
    </button>
  );
}

BankCardButton.propTypes = {
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  paymentMethod: paymentMethodPropType.isRequired,
};

BankCardButton.defaultProps = { disabled: false, isSelected: false };

export default BankCardButton;
