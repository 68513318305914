import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@crate.io/crate-gc-admin';
import { OPERATION_STATES } from '../../../../constants/defaults';
import { importJobPropType } from '../../../../models';

function ImportjobsListActions({ importJob, onDelete, onShowDetails }) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleRemoveImportJob = () => {
    setIsDeleting(true);
    onDelete(importJob);
  };

  switch (importJob.status) {
    case OPERATION_STATES.SENT:
    case OPERATION_STATES.REGISTERED:
    case OPERATION_STATES.IN_PROGRESS:
      return (
        <Button
          kind={Button.kinds.TERTIARY}
          size={Button.sizes.SMALL}
          className="text-crate-blue"
          loading={isDeleting}
          onClick={handleRemoveImportJob}
        >
          <FormattedMessage id="common.cancel" />
        </Button>
      );
    default:
      return (
        <Button
          kind={Button.kinds.TERTIARY}
          size={Button.sizes.SMALL}
          className="text-crate-blue"
          onClick={() => onShowDetails(importJob)}
        >
          <FormattedMessage id="cluster.clusterImport.showDetailsButton" />
        </Button>
      );
  }
}

ImportjobsListActions.propTypes = {
  importJob: importJobPropType.isRequired,
  onDelete: PropTypes.func.isRequired,
  onShowDetails: PropTypes.func.isRequired,
};

export default ImportjobsListActions;
