import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutoComplete, Checkbox, Divider, Form, Input, Select, Space } from 'antd';
import { Button } from '@crate.io/crate-gc-admin';

import AddSecretDrawer from '../../../organization/OrganizationSettings/AddSecretDrawer';
import SectionContainer from '../../../../components/SectionContainer';
import InfoPopover from '../../../../components/InfoPopover';
import ImportSourceSwitch from '../ImportSourceSwitch';
import SupportedFormatHelp from '../SupportedFormatHelp';
import UnsupportedFormatHelp from '../UnsupportedFormatHelp';
import {
  useGetIntegrationsAwsS3buckets,
  useGetIntegrationsAwsS3objects,
  useGetOrganizationsIdSecrets,
} from '../../../../swrHooks';
import {
  COMPRESSION_OPTIONS,
  FORMAT_OPTIONS,
  INGEST_JOURNEY_STATES,
  STEVEDORE_FILE_FORMATS,
  UNSUPPORTED_FILE_TYPES,
  FILE_COMPRESSION_FORMATS,
} from '../constants';
import { FILE_NAME_REGEXP } from '../../../../constants/regex';
import { CRATEDB_CLOUD_IMPORT_S3_DOCS } from '../../../../constants/links';
import { importJobPropType } from '../../../../models';
import TestSWRIsFetching from '../../../../components/TestSWRIsFetching';
import { SECRET_TYPES } from '../../../../constants/defaults';
import INPUT_SANITIZATION from '../../../../constants/inputSanitization';

function S3ImportForm({
  changeIngestSource,
  isCreatingImportJob,
  jobToRetry,
  onFormFinish,
  organizationId,
  isCreateImportJobBlocked,
}) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const selectedSecretId = Form.useWatch('secret', form);
  const selectedBucket = Form.useWatch('bucket', form);
  const [addSecretDrawerVisible, setAddSecretDrawerVisible] = useState(false);
  const [supportedFileFormat, setSupportedFileFormat] = useState(
    STEVEDORE_FILE_FORMATS.JSON,
  );
  const [unsupportedFileFormat, setUnsupportedFileFormat] = useState(false);
  const [endpointURL, setEndpointURL] = useState(null);
  const { data: secrets, isValidating: isSecretsValidating } =
    useGetOrganizationsIdSecrets(organizationId);
  const { data: bucketNames, isValidating: loadingBucketNames } =
    useGetIntegrationsAwsS3buckets(selectedSecretId, endpointURL);
  const [pathPrefix, setPathPrefix] = useState();
  const { data: paths, isValidating: loadingPaths } = useGetIntegrationsAwsS3objects(
    selectedSecretId,
    endpointURL,
    selectedBucket,
    pathPrefix,
  );
  const [pathOptions, setPathOptions] = useState([]);

  const supportedFileTypes = useMemo(
    () => Object.values(STEVEDORE_FILE_FORMATS),
    [],
  );
  const unsupportedFileTypes = useMemo(
    () => Object.values(UNSUPPORTED_FILE_TYPES),
    [],
  );
  const supportedCompressionTypes = useMemo(
    () => Object.values(FILE_COMPRESSION_FORMATS),
    [],
  );

  const handleFormatChange = value => {
    setSupportedFileFormat(value);
    setUnsupportedFileFormat(null);
  };

  const handlePathChange = value => {
    const enteredPath = value.toLowerCase();
    const fileMatch = enteredPath.match(FILE_NAME_REGEXP);

    if (
      supportedFileTypes.includes(fileMatch?.groups.format) &&
      !supportedCompressionTypes.includes(fileMatch?.groups.format)
    ) {
      form.setFieldsValue({
        format: fileMatch.groups.format,
        compression: fileMatch.groups.compression
          ? STEVEDORE_FILE_FORMATS.GZIP
          : 'none',
      });
      setSupportedFileFormat(fileMatch.groups.format);
    } else if (unsupportedFileTypes.includes(fileMatch?.groups.format)) {
      setUnsupportedFileFormat(fileMatch.groups.format);
    } else {
      setSupportedFileFormat(null);
      setUnsupportedFileFormat(null);
    }
  };

  useEffect(() => {
    if (jobToRetry && jobToRetry.type === 's3') {
      form.setFieldsValue({
        bucket: jobToRetry.s3.bucket,
        compression: jobToRetry.compression,
        endpoint: jobToRetry.s3.endpoint || '',
        file_path: jobToRetry.s3.file_path,
        format: jobToRetry.format,
        secret: jobToRetry.s3.secret_id,
        table: jobToRetry.destination.table,
      });

      if (supportedFileTypes.includes(jobToRetry.format)) {
        setSupportedFileFormat(jobToRetry.format);
      }
    }

    return function clearformFieldsOnUnmount() {
      form.resetFields();
    };
  }, [form, jobToRetry, supportedFileTypes]);

  useEffect(() => {
    const buckets = bucketNames?.buckets;
    if (buckets && buckets.length === 1) {
      // only one bucket, just pre-fill the field.
      form.setFields([
        { name: 'bucket', errors: null, value: bucketNames.buckets[0].name },
      ]);
    } else if (buckets && buckets.length > 1) {
      const current = form.getFieldValue('bucket');
      const matches = bucketNames.buckets
        .map(b => b.name)
        .filter(v => v.startsWith(current));

      // current typed in value not in list of buckets, reset
      if (matches.length === 0) {
        form.setFields([{ name: 'bucket', value: '' }]);
      }
    }
  }, [form, bucketNames]);

  const handleKeyDrawerClosed = secret => {
    setAddSecretDrawerVisible(false);
    form.setFieldsValue({
      secret,
    });
  };

  const getBucketNames = () => {
    if (!bucketNames) {
      return null;
    }

    return bucketNames.buckets?.map(bucket => ({
      label: bucket.name,
      value: bucket.name,
    }));
  };

  const getPathOptions = (searchText = null) => {
    if (!paths) {
      return null;
    }

    let filteredPaths = 'objects' in paths ? paths.objects : [];
    if (searchText) {
      filteredPaths = filteredPaths.filter(path => path.startsWith(searchText));
    }

    // Retrieve again the list of objects names when:
    // - searchText is not the last used prefix which means that we don't have a relevant list of files
    // - the list of objects might not contain all possible options (because of the 1k limit)
    if (
      !searchText.startsWith(pathPrefix ?? '') ||
      (paths.objects.length >= 1000 && filteredPaths.length <= 8)
    ) {
      setPathPrefix(searchText);
      filteredPaths = 'objects' in paths ? paths.objects : [];
    }

    return filteredPaths.slice(0, 8).map(object => ({
      label: object,
      value: object,
    }));
  };

  const setEndpoint = e => {
    setEndpointURL(e.target.value);
  };

  const customDropDownRender = menu => {
    return (
      <>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <Space
          style={{
            padding: '0 10px 4px',
          }}
        >
          <Button
            onClick={() => setAddSecretDrawerVisible(true)}
            kind={Button.kinds.TERTIARY}
            type="button"
          >
            <FormattedMessage id="organization.secrets.addSecretButton" />
          </Button>
        </Space>
      </>
    );
  };

  return (
    <>
      <SectionContainer
        actions={
          <ImportSourceSwitch
            changeIngestSource={changeIngestSource}
            currentIngestSource={INGEST_JOURNEY_STATES.S3_INGEST_SELECTED}
          />
        }
        title={
          <FormattedMessage id="cluster.clusterImport.importFromS3.formTitle" />
        }
      >
        {/* uncomment the following "learn more" link when we have a URL to link to */}
        {false && (
          <div className="-mt-4 mb-4 text-neutral-500">
            <a href={CRATEDB_CLOUD_IMPORT_S3_DOCS} target="_blank" rel="noreferrer">
              <FormattedMessage id="cluster.clusterImport.importFromFile.sectionAdditionalDescription" />
            </a>
          </div>
        )}
        <Form
          autoComplete="off"
          disabled={isCreatingImportJob}
          form={form}
          initialValues={{
            compression: 'none',
            format: 'json',
          }}
          layout="vertical"
          name="import-data-from-s3-form"
          id="import-data-from-s3-form"
          aria-label="import data from s3 form"
          onFinish={onFormFinish}
        >
          <div className="grid grid-cols-2 gap-x-4 lg:grid-cols-4">
            {/* secret */}
            <div className="col-span-2 flex items-center justify-between gap-2">
              <Form.Item
                className="w-full"
                label={
                  <FormattedMessage id="cluster.clusterImport.importFromS3.secretField" />
                }
                name="secret"
                rules={[
                  {
                    required: true,
                    message: formatMessage({
                      id: 'cluster.clusterImport.importFromS3.secretRequiredText',
                    }),
                  },
                ]}
              >
                <Select
                  options={secrets
                    ?.filter(secret => secret.type === SECRET_TYPES.AWS)
                    .map(secret => ({
                      label: `${secret.name} (${secret.description})`,
                      value: secret.id,
                    }))}
                  loading={!secrets}
                  onChange={value => {
                    setPathOptions([]);
                    form.setFieldsValue({ secret: value });
                  }}
                  name={
                    <FormattedMessage id="cluster.clusterImport.importFromS3.secretField" />
                  }
                  placeholder={
                    <FormattedMessage id="cluster.clusterImport.importFromS3.secretPlaceholder" />
                  }
                  dropdownRender={customDropDownRender}
                />
              </Form.Item>
            </div>

            {/* table name */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={<FormattedMessage id="cluster.clusterImport.tableLabel" />}
              name="table"
              rules={[INPUT_SANITIZATION.TABLE_NAME]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.tableNamePlaceholder',
                })}
              />
            </Form.Item>

            {/* bucket name */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={
                <FormattedMessage id="cluster.clusterImport.importFromS3.bucketNameField" />
              }
              validateStatus={loadingBucketNames ? 'validating' : 'success'}
              hasFeedback={loadingBucketNames}
              name="bucket"
            >
              <AutoComplete
                options={getBucketNames()}
                filterOption={(inputValue, option) =>
                  option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
                }
                notFoundContent={
                  bucketNames?.error && (
                    <FormattedMessage
                      id="cluster.clusterImport.importFromS3.cannotListBucketsError"
                      values={{ error: bucketNames.error }}
                    />
                  )
                }
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.importFromS3.bucketNameField',
                })}
              />
            </Form.Item>

            {/* format */}
            <Form.Item
              label={
                <FormattedMessage id="cluster.clusterImport.formatOptionsLabel" />
              }
              name="format"
            >
              <Select onChange={handleFormatChange} options={FORMAT_OPTIONS} />
            </Form.Item>

            {/*  compression */}
            <Form.Item
              label={
                <FormattedMessage id="cluster.clusterImport.compressionOptionsLabel" />
              }
              name="compression"
            >
              <Select options={COMPRESSION_OPTIONS} />
            </Form.Item>

            {/* file path */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={
                <FormattedMessage id="cluster.clusterImport.importFromS3.pathToFileField" />
              }
              help={
                <FormattedMessage id="cluster.clusterImport.importFromS3.pathToFileHelp" />
              }
              validateStatus={loadingPaths ? 'validating' : 'success'}
              hasFeedback={loadingPaths}
              name="file_path"
            >
              <AutoComplete
                options={pathOptions}
                onSearch={value => setPathOptions(getPathOptions(value))}
                onFocus={event => setPathOptions(getPathOptions(event.target.value))}
                onChange={value => {
                  setPathOptions(getPathOptions(value));
                  handlePathChange(value);
                }}
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.importFromS3.pathToFilePlaceholder',
                })}
                filterOption={(inputValue, option) =>
                  option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
                }
                notFoundContent={
                  paths?.error && (
                    <FormattedMessage
                      id="cluster.clusterImport.importFromS3.cannotListPathsError"
                      values={{ error: paths.error }}
                    />
                  )
                }
              />
            </Form.Item>

            {/* create or update table */}
            <Form.Item
              className="col-span-2"
              colon={false}
              name="create_table"
              initialValue
              valuePropName="checked"
            >
              <div className="mt-8">
                <Checkbox defaultChecked>
                  <FormattedMessage id="cluster.clusterImport.schemaEvolution" />
                </Checkbox>{' '}
                <InfoPopover
                  content={
                    <div>
                      <FormattedMessage id="cluster.clusterImport.schemaEvolutionHelp" />
                    </div>
                  }
                  title={
                    <FormattedMessage id="cluster.clusterImport.schemaEvolution" />
                  }
                />
              </div>
            </Form.Item>

            {/* endpoint */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={
                <FormattedMessage id="cluster.clusterImport.importFromS3.endpointField" />
              }
              name="endpoint"
              rules={[{ ...INPUT_SANITIZATION.GENERIC_URL, required: false }]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.importFromS3.endpointPlaceholder',
                })}
                onBlur={setEndpoint}
              />
            </Form.Item>

            {/* submit button */}
            <div className="col-span-2 pt-8 text-right">
              <Button
                disabled={isCreateImportJobBlocked}
                loading={isCreatingImportJob}
                form="import-data-from-s3-form"
                type={Button.types.SUBMIT}
              >
                {isCreatingImportJob ? (
                  <FormattedMessage id="cluster.clusterImport.registeringImportButton" />
                ) : (
                  <FormattedMessage id="cluster.clusterImport.submitButton" />
                )}
              </Button>
            </div>
          </div>
        </Form>
      </SectionContainer>
      {supportedFileFormat && (
        <SupportedFormatHelp fileFormat={supportedFileFormat} />
      )}
      {unsupportedFileFormat && (
        <UnsupportedFormatHelp fileFormat={unsupportedFileFormat} />
      )}
      <AddSecretDrawer
        onClose={handleKeyDrawerClosed}
        organizationId={organizationId}
        isVisible={addSecretDrawerVisible}
        secretType={SECRET_TYPES.AWS}
      />
      <TestSWRIsFetching
        fetchStatusList={[isSecretsValidating, loadingPaths, loadingBucketNames]}
      />
    </>
  );
}

S3ImportForm.propTypes = {
  changeIngestSource: PropTypes.func.isRequired,
  isCreatingImportJob: PropTypes.bool.isRequired,
  jobToRetry: importJobPropType,
  onFormFinish: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  isCreateImportJobBlocked: PropTypes.bool,
};

S3ImportForm.defaultProps = {
  jobToRetry: null,
  isCreateImportJobBlocked: false,
};

export default S3ImportForm;
