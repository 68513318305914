export const EMAIL_REGEXP =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// creates name capturing groups for the last word in a string (format)
// optionally followed by a .gz or .gzip extension (compression)
export const FILE_NAME_REGEXP =
  /\.(?<format>[A-Za-z]+)(?:\.(?<compression>gz(?:ip)?))?(?:\?\w*=?\w*&?)?$/;

export const KNOWN_IMPORT_DOMAIN_REGEXP =
  /https?:\/\/(www\.)?(?:[A-Za-z0-9-]+\.)*(?<domain>docs.google|drive.google|dropbox|kaggle|onedrive|sharepoint)(?=.com)/;

export const INTERNAL_IP_REGEXP =
  /localhost|(\b[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}/;
