function fileUploader({ attachedFile, onUpdate, request, url }) {
  return new Promise((resolve, reject) => {
    const updateProgress = event => {
      onUpdate(event.loaded);
    };
    const completeUpload = () => resolve();
    const failUpload = () => reject();
    const cancelUpload = () => {
      reject();
    };

    request.upload.addEventListener('progress', updateProgress);
    request.upload.addEventListener('load', completeUpload);
    request.upload.addEventListener('error', failUpload);
    request.upload.addEventListener('abort', cancelUpload);

    request.open('put', url);
    request.send(attachedFile);
  });
}

export default fileUploader;
