import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Loader } from '@crate.io/crate-gc-admin';
import DisplayDate from '../DisplayDate';
import SectionContainer from '../SectionContainer';
import { fromCents } from '../../utils';
import getTotalCredits from '../../utils/data/credits';
import { useGetOrganizationsIdCredits } from '../../swrHooks';
import { PRICE_OPTIONS_2DP } from '../../constants/defaults';

function CreditsRemaining() {
  const { organizationId } = useParams();
  const { formatNumber } = useIntl();
  const { data: credits, isLoading: creditsLoading } =
    useGetOrganizationsIdCredits(organizationId);

  return (
    <SectionContainer>
      <div className="flex justify-between font-bold">
        <div>
          <FormattedMessage id="organization.consumption.creditsRemainingText" />
        </div>
        <div data-testid="remaining-credits">
          {creditsLoading && (
            <Loader size={Loader.sizes.SMALL} color={Loader.colors.PRIMARY} />
          )}
          {!creditsLoading &&
            formatNumber(fromCents(getTotalCredits(credits)), PRICE_OPTIONS_2DP)}
        </div>
      </div>
      {
        // Only show the expiration date if we've got a single credit assigned.
        // This is because if you have multiple credits, it gets a bit funky
        // w.r.t. which expiration date to display here.
        // Organisations do not normally have multiple credits,
        // but it is supported.
      }
      {credits && credits.length === 1 && getTotalCredits(credits) > 0 && (
        <div className="text-neutral-500" data-testid="remaining-credits-expiry">
          <FormattedMessage
            id="organization.consumption.creditsExpireText"
            values={{
              date: <DisplayDate isoDate={credits[0].expiration_date} />,
            }}
          />
        </div>
      )}
    </SectionContainer>
  );
}

export default CreditsRemaining;
