import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { crateBlue } from '../../constants/colors';

function LabelWithTooltip({ message, label, showIcon }) {
  return (
    <div className="inline-block">
      <Tooltip title={message} placement="topRight" align={{ offset: [12, 0] }}>
        <div className="flex items-center">
          <span className="mr-1">{label}</span>
          {showIcon && <InfoCircleOutlined style={{ color: crateBlue }} />}
        </div>
      </Tooltip>
    </div>
  );
}

LabelWithTooltip.propTypes = {
  message: PropTypes.node.isRequired,
  label: PropTypes.node.isRequired,
  showIcon: PropTypes.bool,
};

LabelWithTooltip.defaultProps = {
  showIcon: true,
};

export default LabelWithTooltip;
