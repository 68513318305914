import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

function TextDivider({ className, text }) {
  const dividerClasses = cx('relative', className);

  return (
    <div className={dividerClasses}>
      <div className="border-t" />
      <div className="absolute -top-2.5 w-full text-center">
        <span className="bg-white px-3">{text}</span>
      </div>
    </div>
  );
}

TextDivider.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

TextDivider.defaultProps = {
  className: '',
};

export default TextDivider;
