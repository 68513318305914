export const FILE_COMPRESSION_TYPES = {
  GZIP: 'application/gzip',
  XGZIP: 'application/x-gzip',
};

export const SUPPORTED_FILE_TYPES = {
  ...FILE_COMPRESSION_TYPES,
  CSV: 'text/csv',
  JSON: 'application/json',
  PARQUET: 'parquet', // no MIME type registered
};

export const UNSUPPORTED_FILE_TYPES = {
  SQL: 'sql',
  doc: 'doc',
  docx: 'docx',
  xls: 'xls',
  xlsx: 'xlsx',
};

export const FILE_COMPRESSION_FORMATS = {
  GZIP: 'gzip',
  GZ: 'gz',
};

export const STEVEDORE_FILE_FORMATS = {
  ...FILE_COMPRESSION_FORMATS,
  CSV: 'csv',
  JSON: 'json',
  PARQUET: 'parquet',
};

// used to map the MIME type (or extension for parquet files)
// of the attached file to Select values in the file import form.
// We get the MIME type (or extension for parquet files)
// from the file object and use this map to automatically
// set the compression or format values, and show the formatting rules
// when the file is attached
export const MIME_TYPES_TO_STEVEDORE_FORMATS = {
  [SUPPORTED_FILE_TYPES.CSV]: STEVEDORE_FILE_FORMATS.CSV,
  [SUPPORTED_FILE_TYPES.GZIP]: STEVEDORE_FILE_FORMATS.GZIP,
  [SUPPORTED_FILE_TYPES.JSON]: STEVEDORE_FILE_FORMATS.JSON,
  [SUPPORTED_FILE_TYPES.PARQUET]: STEVEDORE_FILE_FORMATS.PARQUET, // no MIME type registered for parquet files
};

export const FIELD_NAMES = {
  COMPRESSION_OPTIONS: 'compression',
  CREATE_TABLE: 'create_table',
  DESTINATION_NAMESPACE: 'destination',
  FORMAT_OPTIONS: 'format',
  URL: 'url',
  URL_NAMESPACE: 'url',
  TABLE_NAME: 'table',
  TYPE: 'type',
};

export const FORMAT_OPTIONS = [
  {
    label: 'CSV and variants',
    value: STEVEDORE_FILE_FORMATS.CSV,
  },
  {
    label: 'Parquet',
    value: STEVEDORE_FILE_FORMATS.PARQUET,
  },
  {
    label: 'JSON',
    value: STEVEDORE_FILE_FORMATS.JSON,
  },
];

export const COMPRESSION_OPTIONS = [
  {
    label: 'none',
    value: 'none',
  },
  {
    label: 'Gzip',
    value: STEVEDORE_FILE_FORMATS.GZIP,
  },
];

export const DEFAULT_FIELD_VALUES = {
  [FIELD_NAMES.COMPRESSION_OPTIONS]: 'none',
  [FIELD_NAMES.FORMAT_OPTIONS]: STEVEDORE_FILE_FORMATS.JSON,
  [FIELD_NAMES.TABLE_NAME]: '',
};

export const DEMO_DATA_FORM_VALUES = {
  url: 'https://s3.amazonaws.com/crate.sampledata/nyc.yellowcab/yellow_tripdata_2019-07.parquet',
  tableName: 'nyc_taxi',
  format: STEVEDORE_FILE_FORMATS.PARQUET,
  compression: 'none',
};

export const INGEST_JOURNEY_STATES = {
  DISPLAY_INGESTS: 'DISPLAY_INGESTS',
  URL_INGEST_SELECTED: 'URL_INGEST_SELECTED',
  FILE_INGEST_SELECTED: 'FILE_INGEST_SELECTED',
  S3_INGEST_SELECTED: 'S3_INGEST_SELECTED',
  AZUREBLOB_INGEST_SELECTED: 'AZUREBLOB_INGEST_SELECTED',
};

export const INGEST_JOURNEY_QUERY_PARAM = 'source';
