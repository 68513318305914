import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Loader } from '@crate.io/crate-gc-admin';

function LoadingContainer({ loaderAlignment, loading, render, isViewContainer }) {
  const classes = cx('w-full', {
    'pt-[calc(50vh-80px)]': isViewContainer,
  });

  return loading ? (
    <Loader
      align={loaderAlignment}
      color={Loader.colors.PRIMARY}
      size={Loader.sizes.MEDIUM}
      className={classes}
    />
  ) : (
    render()
  );
}

LoadingContainer.loaderAlignment = { CENTER: 'center', LEFT: 'left' };

LoadingContainer.propTypes = {
  loaderAlignment: PropTypes.oneOf([Loader.alignment.LEFT, Loader.alignment.CENTER]),
  loading: PropTypes.bool.isRequired,
  render: PropTypes.func.isRequired,
  // to avoid ambiguity, try  to use the isViewContainer
  // prop only for the main ViewContainer loading state
  isViewContainer: PropTypes.bool,
};

LoadingContainer.defaultProps = {
  isViewContainer: false,
  loaderAlignment: LoadingContainer.loaderAlignment.LEFT,
};

export default LoadingContainer;
