import {
  EDGE_PRODUCT_NAME,
  HEALTH_STATES,
  OPERATION_STATES,
  OPERATION_TYPES,
} from '../../constants/defaults';
import { normalizeWhitelist } from './IPs';

export const getClusterAsyncInProgress = cluster => {
  if (cluster) {
    return !!cluster.health.running_operation;
  }

  return false;
};

export const getClusterAsyncIPAllowlistInProgress = cluster => {
  if (cluster) {
    return cluster.health.running_operation === OPERATION_TYPES.ALLOWED_CIDR_UPDATE;
  }

  return false;
};

export const getClusterAsyncDeployInProgress = cluster => {
  if (cluster) {
    return cluster.health.running_operation === OPERATION_TYPES.CREATE;
  }

  return false;
};

export const getClusterAsyncResumeInProgress = cluster => {
  if (cluster) {
    return (
      cluster.suspended &&
      cluster.health.running_operation === OPERATION_TYPES.SUSPEND
    );
  }

  return false;
};

export const getClusterAsyncSuspendInProgress = cluster => {
  if (cluster) {
    return (
      !cluster.suspended &&
      cluster.health.running_operation === OPERATION_TYPES.SUSPEND
    );
  }

  return false;
};

export const getClusterAsyncUpgradeInProgress = cluster => {
  if (cluster) {
    return cluster.health.running_operation === OPERATION_TYPES.UPGRADE;
  }

  return false;
};

export const getClusterAdminUIIsReachable = cluster => {
  if (
    cluster.suspended ||
    !cluster.external_ip ||
    cluster.health.status === HEALTH_STATES.UNREACHABLE ||
    getClusterAsyncDeployInProgress(cluster)
  )
    return false;
  return true;
};

export const getClusterAvailableUpgrades = (cluster, availableUpgrades) => {
  if (
    cluster &&
    availableUpgrades?.crate_versions &&
    availableUpgrades.crate_versions[cluster.channel]
  ) {
    return availableUpgrades.crate_versions[cluster.channel];
  }
  return null;
};

export const getClusterHasUnreplicatedTables = (cluster, unreplicated) => {
  if (cluster && unreplicated) {
    return cluster.num_nodes > 1 && unreplicated.data.result.length > 0;
  }

  return false;
};

export const getClusterIsShared = (cluster, products) => {
  if (!cluster || !products) return false;

  return products
    .find(
      product =>
        !product.deprecated &&
        product.kind === 'cluster' &&
        product.offer === 'stripe' &&
        product.name === cluster.product_name,
    )
    ?.tags.includes('shared');
};

export const getClusterIsEdge = cluster => {
  return cluster?.product_name === EDGE_PRODUCT_NAME;
};

export const getClusterProject = (cluster, projects) => {
  if (cluster && projects) {
    return projects.find(project => project.id === cluster.project_id);
  }
  return null;
};

export const getClusterRegion = (cluster, projects, regions) => {
  if (cluster && projects && regions) {
    const project = getClusterProject(cluster, projects);

    // note: it is possible for the project here to be null immediately
    // after a new cluster has been created, hence we need this check
    if (project) {
      return regions.find(region => region.name === project.region);
    }
  }
  return null;
};

export const getClusterTotalStorage = cluster => {
  if (cluster) {
    return cluster.hardware_specs.disk_size_per_node_bytes * cluster.num_nodes;
  }

  return null;
};

export const getClusterGcDomain = cluster => {
  const gcDomain = cluster.fqdn.replace('.', '.gc.').replace(/\.$/, '');
  return gcDomain;
};

export const getClusterGcUrl = cluster => {
  return `https://${getClusterGcDomain(cluster)}`;
};

export const getClusterDeployIsComplete = cluster => {
  return (
    cluster?.last_async_operation?.type !== OPERATION_TYPES.CREATE ||
    (cluster?.last_async_operation?.type === OPERATION_TYPES.CREATE &&
      cluster?.last_async_operation?.status === OPERATION_STATES.SUCCEEDED)
  );
};

export const getClusterIsSuspendedOrSuspending = cluster => {
  // the second condition below returns true if the cluster is in the
  // process of being suspended, but this hasn't completed yet
  return (
    cluster.suspended ||
    (cluster?.last_async_operation?.type === OPERATION_TYPES.SUSPEND &&
      cluster?.last_async_operation?.status !== OPERATION_STATES.SUCCEEDED &&
      cluster?.last_async_operation?.status !== OPERATION_STATES.FAILED)
  );
};

// retreive the current whitelist from the cluster object
export const getClusterIpWhitelist = cluster => {
  return cluster?.ip_whitelist ? normalizeWhitelist(cluster.ip_whitelist || []) : [];
};

// check if ip is in cluster ip whitelist
export const isIpInClusterIpWhiteList = (cluster, ipAddress) => {
  const clusterIpWhitelist = getClusterIpWhitelist(cluster);

  // If cluster ip whitelist is empty, then cluster is globally accessible
  return (
    clusterIpWhitelist.length === 0 ||
    clusterIpWhitelist.some(ipRange => ipRange.cidr === `${ipAddress?.ip}/32`)
  );
};
