import { Input, Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CopyOutlined } from '@ant-design/icons';
import { SubmitButtonGroup, Text } from '@crate.io/crate-gc-admin';
import CopyToClipboard from '../CopyToClipboard';
import INPUT_SANITIZATION from '../../constants/inputSanitization';

function ConfirmDelete({
  title,
  visible,
  disclaimer,
  children,
  prompt,
  confirmText,
  onCancel,
  onConfirm,
}) {
  const [form] = Form.useForm();
  const [confirmEnabled, setConfirmEnabled] = useState(false);

  const inputChanged = event => {
    setConfirmEnabled(event.target.value === confirmText);
  };

  const resetState = () => {
    form.setFieldsValue({ 'confirm-entity-name': '' });
    setConfirmEnabled(false);
  };

  const handleConfirm = () => {
    if (form.getFieldValue('confirm-entity-name') === confirmText) {
      resetState();
      onConfirm();
    }
  };

  const handleCancel = () => {
    resetState();
    onCancel();
  };

  return (
    <Modal
      closable={false}
      footer={
        <div className="py-1">
          <SubmitButtonGroup
            disabled={!confirmEnabled}
            form="confirm-delete"
            onCancel={handleCancel}
            warn
          />
        </div>
      }
      onCancel={handleCancel}
      title={title}
      open={visible}
    >
      <Form
        aria-label="confirm-delete"
        id="confirm-delete"
        onChange={inputChanged}
        onFinish={handleConfirm}
        form={form}
      >
        {children && children}
        <Text className="mb-4">{prompt}</Text>
        <div className="mb-4 flex rounded-md border border-neutral-300 bg-neutral-100">
          <code className="flex-grow p-2">{confirmText}</code>
          <CopyToClipboard textToCopy={confirmText}>
            <div className="border-l border-neutral-300 bg-white px-3 py-2 text-crate-blue hover:text-[#23bfde]">
              <CopyOutlined />
            </div>
          </CopyToClipboard>
        </div>
        <Form.Item
          name="confirm-entity-name"
          rules={[INPUT_SANITIZATION.GENERIC_OPTIONAL_TEXT]}
        >
          <Input aria-label="confirm entity name" placeholder={confirmText} />
        </Form.Item>
        <Text className="mb-6">
          {disclaimer || (
            <FormattedMessage id="confirmDelete.defaultDisclaimerText" />
          )}
        </Text>
      </Form>
    </Modal>
  );
}

ConfirmDelete.propTypes = {
  title: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  disclaimer: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  prompt: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmDelete.defaultProps = {
  disclaimer: null,
  children: null,
};

export default ConfirmDelete;
