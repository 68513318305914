import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';
import { isNull, last } from 'lodash/fp';
import { Button, Loader, Text } from '@crate.io/crate-gc-admin';
import FileImportForm from './FileImportForm';
import fileUploader from './fileUploader';
import ImportJobsList from './ImportJobsList';
import AzureBlobImportForm from './AzureBlobImportForm';
import S3ImportForm from './S3ImportForm';
import URLImportForm from './URLImportForm';
import ConstrainWidth from '../../../components/ConstrainWidth/ConstrainWidth';
import LoadingContainer from '../../../components/LoadingContainer';
import SectionContainer from '../../../components/SectionContainer';
import ViewContainerActions from '../../../components/ViewContainerActions';

import { INGEST_JOURNEY_QUERY_PARAM, INGEST_JOURNEY_STATES } from './constants';
import { useAnalytics } from '../../../hooks';
import {
  useGetClustersId,
  useGetClustersIdImportjobs,
  useGetOrganizationsIdFiles,
} from '../../../swrHooks';
import { apiDelete, apiPost } from '../../../api';
import { FILE_STATES } from '../../../constants/defaults';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';
import {
  getClusterAsyncDeployInProgress,
  getClusterAsyncResumeInProgress,
  getClusterAsyncSuspendInProgress,
} from '../../../utils/data/cluster';

const {
  DISPLAY_INGESTS,
  URL_INGEST_SELECTED,
  FILE_INGEST_SELECTED,
  S3_INGEST_SELECTED,
  AZUREBLOB_INGEST_SELECTED,
} = INGEST_JOURNEY_STATES;

function ClusterImport() {
  const location = useLocation();
  const { clusterId, organizationId } = useParams();
  const { data: cluster } = useGetClustersId(clusterId);
  const { trackEvent } = useAnalytics();

  const sourceQueryParam = new URLSearchParams(location.search).get(
    INGEST_JOURNEY_QUERY_PARAM,
  );

  // isCreatingImportJob is the intermediate state when the form has
  // been submitted but we are still waiting for an import job object
  // to be returned from the API so we can show the import jobs UI.
  const [isCreatingImportJob, setIsCreatingImportJob] = useState(false);

  // fileUploadProgress is the state when we have the pre-signed URL
  // and have started the upload. This state could last a several minutes
  // for larger files
  const [fileUploadProgress, setFileUploadProgress] = useState(null);
  const [jobToRetry, setJobToRetry] = useState(null);
  const [ingestJourneyState, setIngestJourneyState] = useState(false);
  const [ingestJourneyIsActive, setIngestJourneyIsActive] = useState(false);
  const [prefillDemoData, setPrefillDemoData] = useState(false);
  const [attachedFile, setAttachedFile] = useState(null);
  const [errorPostingFile, setErrorPostingFile] = useState(null);

  // clear completed import jobs
  const [showClearJobsModal, setShowClearJobsModal] = useState(false);
  const [importJobsToBeCleared, setImportJobsToBeCleared] = useState([]);

  // swr
  const { data: importJobs, mutate: mutateImportJobs } =
    useGetClustersIdImportjobs(clusterId);
  const { data: files, mutate: mutateFiles } =
    useGetOrganizationsIdFiles(organizationId);
  const clusterHasPreviousImportJobs = !isEmpty(importJobs);

  const uploadRequest = useMemo(() => {
    // this must be memoized to prevent instantiating
    // new requests on every render, if we don't memoize
    // we lose a reference to the XMLHttpRequest and we can't cancel
    return new XMLHttpRequest();
  }, []);

  const isCreateImportJobBlocked =
    cluster?.suspended ||
    getClusterAsyncResumeInProgress(cluster) ||
    getClusterAsyncSuspendInProgress(cluster) ||
    getClusterAsyncDeployInProgress(cluster);

  useEffect(() => {
    if (!importJobs) return;

    // set the screen state on initial page load, based on
    // whether the user has previous import jobs to display or not.

    // Don't set the screen state if we are mid-import
    // so that multiple, concurrent imports are possible without
    // the screen automatically switching to the list view when a
    // background import job finishes while we are creating another import.
    if (
      !ingestJourneyIsActive &&
      !isCreatingImportJob &&
      isNull(fileUploadProgress)
    ) {
      if (sourceQueryParam) {
        setIngestJourneyState(sourceQueryParam);
      } else {
        setIngestJourneyState(
          clusterHasPreviousImportJobs ? DISPLAY_INGESTS : FILE_INGEST_SELECTED,
        );
      }
    }
  }, [
    importJobs,
    clusterHasPreviousImportJobs,
    ingestJourneyIsActive,
    isCreatingImportJob,
    fileUploadProgress,
    sourceQueryParam,
  ]);

  const startNewImportFlow = (type, { demoData } = { demoData: false }) => {
    setJobToRetry(null);
    setIngestJourneyState(type);
    setIngestJourneyIsActive(true);
    setPrefillDemoData(demoData);
  };

  const handleAttachFile = file => {
    setAttachedFile(file);
  };

  const handleRemoveAttachedFile = () => {
    setAttachedFile(null);
  };

  const handleCancelUpload = () => {
    uploadRequest.abort();
    setFileUploadProgress(null);
  };

  const handleFileUploadError = error => {
    setAttachedFile(null);
    setIsCreatingImportJob(false);
    setErrorPostingFile(error);
  };

  const handleFileFormFinish = async config => {
    // Disable File form while we wait for the
    // pre-signed URL to upload our file to
    setIsCreatingImportJob(true);
    trackEvent(USER_TRACKING_EVENTS.CLICKED_IMPORT_DATA);

    let createFileUploadData;
    // If the file has an id, it's already been uploaded and we're just re-importing
    if (!attachedFile.id) {
      const { success: createFileUploadSuccess, data: uploadResult } = await apiPost(
        `/api/v2/organizations/${organizationId}/files/`,
        {
          file_size: attachedFile.size,
          name: attachedFile.name,
        },
      );

      if (!createFileUploadSuccess) {
        handleFileUploadError(uploadResult);
        return;
      }
      createFileUploadData = uploadResult;

      mutateFiles([createFileUploadData, ...files]);

      try {
        await fileUploader({
          attachedFile,
          onUpdate: percentComplete => {
            // We have the pre-signed URL
            // now as soon as progress starts
            // being reported, change state
            // and pass progress to fileImportForm
            setFileUploadProgress(percentComplete);
            setIsCreatingImportJob(false);
          },
          request: uploadRequest,
          url: createFileUploadData.upload_url,
        });
      } catch (error) {
        return;
      }
    } else {
      setFileUploadProgress(100);
      setIsCreatingImportJob(false);
      createFileUploadData = { id: attachedFile.id };
    }

    setIngestJourneyIsActive(false);

    const { success, data } = await apiPost(
      `/api/v2/clusters/${cluster?.id}/import-jobs/`,
      {
        ...config,
        file: {
          id: createFileUploadData.id,
        },
      },
    );

    if (success) {
      // The file is now uploaded and the import has begun
      setFileUploadProgress(null);
      mutateImportJobs([data, ...importJobs]);
    } else {
      handleFileUploadError({
        message: (
          <FormattedMessage id="cluster.clusterImport.importFromFile.fileUploadFailedError" />
        ),
        succes: false,
      });
    }

    setAttachedFile(null);
  };

  const handleS3FormFinish = async config => {
    // Disable S3 form while we wait for the API
    // call to complete
    setIsCreatingImportJob(true);
    trackEvent(USER_TRACKING_EVENTS.CLICKED_IMPORT_DATA);

    const payload = {
      compression: config.compression,
      destination: {
        create_table: config.create_table,
        table: config.table,
      },
      format: config.format,
      s3: {
        bucket: config.bucket,
        file_path: config.file_path,
        secret_id: config.secret,
      },
      type: 's3',
    };

    if (config.endpoint) {
      payload.s3.endpoint = config.endpoint;
    }

    const { success, data } = await apiPost(
      `/api/v2/clusters/${clusterId}/import-jobs/`,
      payload,
    );

    if (success) {
      mutateImportJobs([data, ...importJobs]);
      setIsCreatingImportJob(false);
      setIngestJourneyIsActive(false);
    }
  };

  const handleAzureBlobFormFinish = async config => {
    // Disable Azure blob form while we wait for the API
    // call to complete
    setIsCreatingImportJob(true);
    trackEvent(USER_TRACKING_EVENTS.CLICKED_IMPORT_DATA);

    const { success, data } = await apiPost(
      `/api/v2/clusters/${clusterId}/import-jobs/`,
      {
        compression: config.compression,
        destination: {
          create_table: config.create_table,
          table: config.table,
        },
        format: config.format,
        azureblob: {
          blob_name: config.blob_name,
          container_name: config.container_name,
          secret_id: config.secret,
        },
        type: 'azureblob',
      },
    );

    if (success) {
      mutateImportJobs([data, ...importJobs]);
      setIsCreatingImportJob(false);
      setIngestJourneyIsActive(false);
    }
  };

  const handleURLFormFinish = async config => {
    setIsCreatingImportJob(true);
    setIngestJourneyIsActive(false);
    trackEvent(USER_TRACKING_EVENTS.CLICKED_IMPORT_DATA);

    const { success, data } = await apiPost(
      `/api/v2/clusters/${clusterId}/import-jobs/`,
      config,
    );

    if (success) {
      mutateImportJobs([data, ...importJobs]);
      setIsCreatingImportJob(false);
    }
  };

  const handleImportJobDelete = async importJob => {
    await apiDelete(`/api/v2/clusters/${clusterId}/import-jobs/${importJob.id}/`);

    mutateImportJobs(
      importJobs.filter(existingImportJob => existingImportJob.id !== importJob.id),
    );
  };

  const handleImportJobRetry = importJob => {
    if (importJob.type === 'file') {
      // File may have been automatically deleted so
      // there may be no corresponding file.
      const retryingImportFileInFilesList = importJob?.file;
      setAttachedFile(
        retryingImportFileInFilesList
          ? {
              ...retryingImportFileInFilesList,
              size: retryingImportFileInFilesList.file_size,
              type: last(retryingImportFileInFilesList.name.split('.')),
            }
          : null,
      );
    }
    setJobToRetry(importJob);
    setIngestJourneyState(
      {
        azureblob: AZUREBLOB_INGEST_SELECTED,
        file: FILE_INGEST_SELECTED,
        s3: S3_INGEST_SELECTED,
        url: URL_INGEST_SELECTED,
      }[importJob.type],
    );
  };

  const handleImportFlowCancel = () => {
    setJobToRetry(null);
    setIngestJourneyState(
      clusterHasPreviousImportJobs ? DISPLAY_INGESTS : FILE_INGEST_SELECTED,
    );
    setErrorPostingFile(null);
    setAttachedFile(null);
  };

  const canClearImportJobs = importJobs?.some(importJob =>
    ['FAILED', 'SUCCEEDED'].includes(importJob.status),
  );

  const clearImportJobs = () => {
    if (canClearImportJobs) {
      const jobsToBeDeleted = importJobs
        .filter(importJob => ['FAILED', 'SUCCEEDED'].includes(importJob.status))
        .map(importJob => importJob.id);

      setImportJobsToBeCleared([...jobsToBeDeleted]);
      jobsToBeDeleted.forEach(async importJob =>
        handleImportJobDelete({ id: importJob }),
      );
    }
  };

  // check progress of clearing all import jobs
  useEffect(() => {
    if (
      importJobsToBeCleared.length > 0 &&
      !importJobs.some(importJob => importJobsToBeCleared.includes(importJob.id))
    ) {
      setImportJobsToBeCleared([]);
    }
  }, [importJobs, importJobsToBeCleared]);

  return (
    <ConstrainWidth>
      <LoadingContainer
        loading={!importJobs}
        render={() => (
          <>
            <ViewContainerActions>
              {ingestJourneyState === DISPLAY_INGESTS && (
                <>
                  {canClearImportJobs && (
                    <Button
                      onClick={() => setShowClearJobsModal(true)}
                      disabled={importJobsToBeCleared.length > 0}
                      type={Button.types.BUTTON}
                      kind={Button.kinds.TERTIARY}
                    >
                      <FormattedMessage id="cluster.clusterImport.clearAllButton" />
                    </Button>
                  )}
                  <Button
                    disabled={importJobsToBeCleared.length > 0}
                    onClick={() => {
                      setIngestJourneyState(FILE_INGEST_SELECTED);
                      setIngestJourneyIsActive(true);
                    }}
                  >
                    <FormattedMessage id="cluster.clusterImport.addNewImportButton" />
                  </Button>
                </>
              )}
              {ingestJourneyState !== DISPLAY_INGESTS &&
                clusterHasPreviousImportJobs && (
                  <Button
                    disabled={isCreatingImportJob || !isNull(fileUploadProgress)}
                    kind={Button.kinds.TERTIARY}
                    onClick={handleImportFlowCancel}
                  >
                    <FormattedMessage id="cluster.clusterImport.viewExistingImportsButton" />
                  </Button>
                )}
            </ViewContainerActions>

            {/* clear all import jobs progress indicator */}
            {ingestJourneyState === DISPLAY_INGESTS &&
              importJobsToBeCleared.length > 0 && (
                <SectionContainer>
                  <Loader
                    className="flex-col gap-4 pb-2 pt-4"
                    align={Loader.alignment.CENTER}
                    color={Loader.colors.PRIMARY}
                    message={
                      <FormattedMessage id="cluster.clusterImport.clearAllInProgressText" />
                    }
                  />
                </SectionContainer>
              )}

            {/* list previous ingests */}
            {ingestJourneyState === DISPLAY_INGESTS &&
              importJobsToBeCleared.length === 0 && (
                <ImportJobsList
                  importJobs={importJobs}
                  onDelete={handleImportJobDelete}
                  onRetry={handleImportJobRetry}
                />
              )}

            {/* ingest from URL form */}
            {ingestJourneyState === URL_INGEST_SELECTED && (
              <URLImportForm
                changeIngestSource={value => startNewImportFlow(value)}
                isCreatingImportJob={isCreatingImportJob}
                jobToRetry={jobToRetry}
                onFormFinish={handleURLFormFinish}
                prefillDemoData={prefillDemoData}
                isCreateImportJobBlocked={isCreateImportJobBlocked}
              />
            )}

            {/* ingest from file form */}
            {ingestJourneyState === FILE_INGEST_SELECTED && (
              <FileImportForm
                attachedFile={attachedFile}
                changeIngestSource={value => startNewImportFlow(value)}
                errorPostingFile={errorPostingFile}
                fileUploadProgress={fileUploadProgress}
                isCreatingImportJob={isCreatingImportJob}
                jobToRetry={jobToRetry}
                onAttachFile={handleAttachFile}
                onCancelUpload={handleCancelUpload}
                onFormFinish={handleFileFormFinish}
                onRemoveFile={handleRemoveAttachedFile}
                recentlyUploadedFiles={
                  files?.filter(file => file.status === FILE_STATES.UPLOADED) || []
                }
                isCreateImportJobBlocked={isCreateImportJobBlocked}
              />
            )}

            {/* ingest from s3 form */}
            {ingestJourneyState === S3_INGEST_SELECTED && (
              <S3ImportForm
                changeIngestSource={value => startNewImportFlow(value)}
                isCreatingImportJob={isCreatingImportJob}
                jobToRetry={jobToRetry}
                onFormFinish={handleS3FormFinish}
                organizationId={organizationId}
                isCreateImportJobBlocked={isCreateImportJobBlocked}
              />
            )}

            {/* ingest from Azure blob form */}
            {ingestJourneyState === AZUREBLOB_INGEST_SELECTED && (
              <AzureBlobImportForm
                changeIngestSource={value => startNewImportFlow(value)}
                isCreatingImportJob={isCreatingImportJob}
                jobToRetry={jobToRetry}
                onFormFinish={handleAzureBlobFormFinish}
                organizationId={organizationId}
                isCreateImportJobBlocked={isCreateImportJobBlocked}
              />
            )}
          </>
        )}
      />
      <Modal
        open={showClearJobsModal}
        onCancel={() => setShowClearJobsModal(false)}
        onOk={() => {
          setShowClearJobsModal(false);
          clearImportJobs();
        }}
        title={<FormattedMessage id="cluster.clusterImport.clearAllModalTitle" />}
      >
        <Text>
          <FormattedMessage id="cluster.clusterImport.clearAllModalDescription" />
        </Text>
      </Modal>
    </ConstrainWidth>
  );
}

export default ClusterImport;
