import PropTypes from 'prop-types';
import getFeatureFlag from '../../utils/data/features';
import { useGetFeaturesStatus } from '../../swrHooks';

function FeatureToggle({ featureName, showOnlyWhenDisabled, children }) {
  const { data: featureFlags } = useGetFeaturesStatus();
  const featureEnabled = getFeatureFlag(featureFlags, featureName);

  if (
    featureFlags &&
    (featureEnabled || (showOnlyWhenDisabled && !featureEnabled))
  ) {
    return children;
  }

  return null;
}

FeatureToggle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  featureName: PropTypes.string.isRequired,
  showOnlyWhenDisabled: PropTypes.bool,
};

FeatureToggle.defaultProps = {
  children: null,
  showOnlyWhenDisabled: false,
};

export default FeatureToggle;
