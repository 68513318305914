import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { Text } from '@crate.io/crate-gc-admin';
import {
  CREDITS_STATES,
  PRICE_OPTIONS_2DP,
  PRICE_OPTIONS_3DP,
} from '../../constants/defaults';
import { freeCreditPropType, pricePropType } from '../../models';
import { fromCents } from '../../utils';

function PricingBreakdown({
  freeCredits,
  loading,
  price,
  title,
  computeText,
  storageText,
}) {
  const { formatNumber } = useIntl();
  const freeCreditsAvailable =
    freeCredits &&
    freeCredits.length > 0 &&
    freeCredits[0].status === CREDITS_STATES.ACTIVE;

  const displayPrice = (key, unit, options) => {
    if (!(key in price)) {
      return 'n/a';
    }

    const regularPrice = price[key][`price_per_${unit}`];
    const promotionPrice = price[key].promotion_price[`price_per_${unit}`];

    return `${unit === 'month' ? '~' : ''}${formatNumber(
      promotionPrice === null ? regularPrice : promotionPrice,
      options,
    )}`;
  };

  return (
    <div data-testid="pricing-breakdown">
      {freeCreditsAvailable && (
        <div
          className="-mx-4 -mt-4 mb-2 rounded bg-crate-gray30 px-5 py-2 text-white"
          data-testid="free-credits"
        >
          <Text>
            <FormattedMessage
              id="pricingBreakdown.freeCreditsText"
              values={{
                value: (
                  <FormattedNumber
                    value={fromCents(freeCredits[0].remaining_amount)}
                    style={PRICE_OPTIONS_2DP.style}
                    currency={PRICE_OPTIONS_2DP.currency}
                  />
                ),
              }}
            />
          </Text>
          <Text className="text-xs">
            <FormattedMessage
              id="pricingBreakdown.freeCreditsExpiryText"
              values={{
                value: (
                  <FormattedDate
                    value={freeCredits[0].expiration_date}
                    day="numeric"
                    month="long"
                    year="numeric"
                  />
                ),
              }}
            />
          </Text>
        </div>
      )}
      <table className="w-full">
        <thead>
          <tr>
            <th className="w-full text-left">{title}</th>
            <th className="py-1.5 pl-6 text-right text-crate-gray30">
              <FormattedMessage id="common.hourly" />
            </th>
            <th className="py-1.5 pl-6 text-right">
              <FormattedMessage id="common.monthly" />
            </th>
          </tr>
        </thead>
        {!loading && price && (
          <tbody>
            <tr>
              <td className="py-1.5">
                <FormattedMessage id="common.compute" />
                {computeText && (
                  <div className="text-xs text-crate-gray30">{computeText}</div>
                )}
              </td>
              <td
                className="py-1.5 pl-6 text-right text-crate-gray30"
                data-testid="price-compute-hour"
              >
                {displayPrice('cluster_price', 'hour', PRICE_OPTIONS_3DP)}
              </td>
              <td
                className="py-1.5 pl-6 text-right"
                data-testid="price-compute-month"
              >
                {displayPrice('cluster_price', 'month', PRICE_OPTIONS_2DP)}
              </td>
            </tr>
            <tr>
              <td className="pb-2.5 pt-1.5">
                <FormattedMessage id="common.storage" />
                {storageText && (
                  <div className="text-xs text-crate-gray30">{storageText}</div>
                )}
              </td>
              <td
                className="pb-2.5 pl-6 pt-1.5 text-right text-crate-gray30"
                data-testid="price-storage-hour"
              >
                {displayPrice('storage_price', 'hour', PRICE_OPTIONS_3DP)}
              </td>
              <td
                className="pb-2.5 pl-6 pt-1.5 text-right"
                data-testid="price-storage-month"
              >
                {displayPrice('storage_price', 'month', PRICE_OPTIONS_2DP)}
              </td>
            </tr>
            <tr>
              <td className="border-t-2 pb-1.5 pt-2.5">
                <FormattedMessage id="common.total" />
              </td>
              <td
                className="border-t-2 pb-1.5 pl-6 pt-2.5 text-right text-crate-gray30"
                data-testid="price-total-hour"
              >
                {displayPrice('total_price', 'hour', PRICE_OPTIONS_3DP)}
              </td>
              <td
                className="border-t-2 pb-1.5 pl-6 pt-2.5 text-right"
                data-testid="price-total-month"
              >
                {displayPrice('total_price', 'month', PRICE_OPTIONS_2DP)}
              </td>
            </tr>
          </tbody>
        )}
        {(loading || !price) && (
          <tbody>
            <tr>
              <td className="py-1.5">
                <FormattedMessage id="common.compute" />
              </td>
              <td colSpan="2" rowSpan="3" className="p-2 text-center align-middle">
                <LoadingOutlined spin data-testid="loading-icon" />
              </td>
            </tr>
            <tr>
              <td className="pb-2.5 pt-1.5">
                <FormattedMessage id="common.storage" />
              </td>
            </tr>
            <tr>
              <td className="pb-1.5 pt-3">
                <FormattedMessage id="common.total" />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
}

PricingBreakdown.propTypes = {
  computeText: PropTypes.string,
  freeCredits: PropTypes.arrayOf(freeCreditPropType),
  loading: PropTypes.bool.isRequired,
  price: pricePropType,
  storageText: PropTypes.string,
  title: PropTypes.string,
};

PricingBreakdown.defaultProps = {
  computeText: null,
  freeCredits: null,
  price: null,
  storageText: null,
  title: null,
};

export default PricingBreakdown;
