import React from 'react';
import PropTypes from 'prop-types';

// this is an unusual component, used purely for testing.
//
// when testing SWR, we sometimes need to simulate polling, but unfortunately
// this means we likely have to async/await for long periods of time. for example,
// if SWR polls every 30s, those tests would need to run over 30s each for them to
// see the poll action. instead, we can now insert this component into the
// component we are testing, "click" it, and - depending on the function passed
// in - force SWR to revalidate when we need it.
//
// this idea came about after some discussion about this problem. we accept it
// isn't ideal and a better solution may present itself in future. by making this
// a component, replacing it with a future solution should be straightforward.

function TestEventHandler({ fn, testId }) {
  return (
    <div
      aria-hidden="true"
      className="hidden"
      data-testid={testId}
      onClick={() => fn()}
    />
  );
}

TestEventHandler.propTypes = {
  fn: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

TestEventHandler.defaultProps = {
  testId: 'test-event-handler',
};

export default TestEventHandler;
