import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, Form, Input, Select } from 'antd';
import { last } from 'lodash/fp';
import { Button, Text } from '@crate.io/crate-gc-admin';
import ImportSourceSwitch from '../ImportSourceSwitch';
import SectionContainer from '../../../../components/SectionContainer';
import {
  COMPRESSION_OPTIONS,
  DEFAULT_FIELD_VALUES,
  DEMO_DATA_FORM_VALUES,
  FIELD_NAMES,
  FORMAT_OPTIONS,
  INGEST_JOURNEY_STATES,
  STEVEDORE_FILE_FORMATS,
  UNSUPPORTED_FILE_TYPES,
} from '../constants';
import {
  FILE_NAME_REGEXP,
  INTERNAL_IP_REGEXP,
  KNOWN_IMPORT_DOMAIN_REGEXP,
} from '../../../../constants/regex';
import { importJobPropType } from '../../../../models';
import InfoPopover from '../../../../components/InfoPopover';
import SupportedFormatHelp from '../SupportedFormatHelp';
import ImportingHelpSection from '../ImportingHelpSection';
import UnsupportedFormatHelp from '../UnsupportedFormatHelp';
import KnownImportDomainHelp from '../KnownImportDomainHelp';
import INPUT_SANITIZATION from '../../../../constants/inputSanitization';

const getFormSchemaFromFieldValues = ({ compression, format, tableName, url }) => ({
  [FIELD_NAMES.COMPRESSION_OPTIONS]: compression,
  [FIELD_NAMES.FORMAT_OPTIONS]: format,
  [FIELD_NAMES.DESTINATION_NAMESPACE]: {
    [FIELD_NAMES.TABLE_NAME]: tableName,
  },
  [FIELD_NAMES.URL_NAMESPACE]: {
    [FIELD_NAMES.URL]: url,
  },
});

function URLImportForm({
  changeIngestSource,
  isCreatingImportJob,
  jobToRetry,
  onFormFinish,
  prefillDemoData,
  isCreateImportJobBlocked,
}) {
  const { formatNumber } = useIntl();
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [usingDemoData, setUsingDemoData] = useState(false);
  const [supportedFileFormat, setSupportedFileFormat] = useState(
    STEVEDORE_FILE_FORMATS.JSON,
  );
  const [unsupportedFileFormat, setUnsupportedFileFormat] = useState(null);
  const [knownDomain, setKnownDomain] = useState(null);

  const supportedFileTypes = useMemo(
    () => Object.values(STEVEDORE_FILE_FORMATS),
    [],
  );
  const unsupportedFileTypes = useMemo(
    () => Object.values(UNSUPPORTED_FILE_TYPES),
    [],
  );

  const buttonLabel = useMemo(() => {
    if (isCreatingImportJob) {
      return <FormattedMessage id="cluster.clusterImport.registeringImportButton" />;
    }

    return <FormattedMessage id="cluster.clusterImport.submitButton" />;
  }, [isCreatingImportJob]);

  useEffect(() => {
    if (jobToRetry && jobToRetry.type === 'url') {
      const {
        compression,
        destination: { table: tableName },
        format,
        url: { url },
      } = jobToRetry;

      form.setFieldsValue(
        getFormSchemaFromFieldValues({ compression, format, tableName, url }),
      );
      if (supportedFileTypes.includes(format)) {
        setSupportedFileFormat(format);
      }
    }

    return function clearformFieldsOnUnmount() {
      form.resetFields();
    };
  }, [form, jobToRetry, supportedFileTypes]);

  const setDemoData = () => {
    form.setFieldsValue(getFormSchemaFromFieldValues(DEMO_DATA_FORM_VALUES));
    setUsingDemoData(true);
    setSupportedFileFormat(null);
    setUnsupportedFileFormat(null);
  };

  const handleFormatChange = value => {
    setSupportedFileFormat(value);
    setUnsupportedFileFormat(null);
  };

  const handleUrlChange = event => {
    const enteredURL = event.target.value;
    const fileMatch = enteredURL.match(FILE_NAME_REGEXP);
    const domainMatch = enteredURL.match(KNOWN_IMPORT_DOMAIN_REGEXP);
    const internalIpMatch = enteredURL.match(INTERNAL_IP_REGEXP);
    const isUsingDemoData = event.target.value === DEMO_DATA_FORM_VALUES.url;

    // if user is importing from a source with extra instructions or warnings available
    // drive.google.com, dropbox, internal IP etc, show help
    setKnownDomain(
      domainMatch?.groups.domain || (internalIpMatch && 'internalIP') || null,
    );

    // if inferred file format of import has help available, show it.
    if (supportedFileTypes.includes(fileMatch?.groups.format)) {
      form.setFieldsValue({
        [FIELD_NAMES.FORMAT_OPTIONS]: fileMatch.groups.format,
        [FIELD_NAMES.COMPRESSION_OPTIONS]: fileMatch.groups.compression
          ? STEVEDORE_FILE_FORMATS.GZIP
          : DEFAULT_FIELD_VALUES[FIELD_NAMES.COMPRESSION_OPTIONS],
      });
      setSupportedFileFormat(fileMatch.groups.format);
    } else if (unsupportedFileTypes.includes(fileMatch?.groups.format)) {
      setUnsupportedFileFormat(fileMatch.groups.format);
    } else {
      setSupportedFileFormat(null);
      setUnsupportedFileFormat(null);
    }
    setUsingDemoData(isUsingDemoData);
  };

  useEffect(() => {
    if (prefillDemoData) {
      form.setFieldsValue(getFormSchemaFromFieldValues(DEMO_DATA_FORM_VALUES));
      setUsingDemoData(true);
      // workaround because JSON is default selected in the other views
      setSupportedFileFormat(null);
      setUnsupportedFileFormat(null);
    }
  }, [prefillDemoData, form, setUsingDemoData]);

  return (
    <>
      <SectionContainer
        actions={
          <ImportSourceSwitch
            changeIngestSource={changeIngestSource}
            currentIngestSource={INGEST_JOURNEY_STATES.URL_INGEST_SELECTED}
          />
        }
        title={
          jobToRetry ? (
            <FormattedMessage id="cluster.clusterImport.retryFailedImportSectionTitle" />
          ) : (
            <FormattedMessage id="cluster.clusterImport.importFromUrl.sectionTitle" />
          )
        }
      >
        <Form
          autoComplete="off"
          disabled={isCreatingImportJob}
          form={form}
          initialValues={{
            ...DEFAULT_FIELD_VALUES,
            [FIELD_NAMES.URL]: '',
            [FIELD_NAMES.TYPE]: 'url',
          }}
          layout="vertical"
          name="import-data-from-url-form"
          id="import-data-from-url-form"
          aria-label="import data from url form"
          onFinish={onFormFinish}
        >
          {/* hidden field type */}
          <Form.Item name={[FIELD_NAMES.TYPE]} hidden>
            <Input />
          </Form.Item>

          <div className="grid grid-cols-2 gap-x-4 lg:grid-cols-4">
            {/* import url */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={
                <FormattedMessage id="cluster.clusterImport.importFromUrl.urlLabel" />
              }
              name={[FIELD_NAMES.URL_NAMESPACE, FIELD_NAMES.URL]}
              rules={[INPUT_SANITIZATION.GENERIC_URL]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.importFromUrl.urlPlaceholder',
                })}
                onChange={handleUrlChange}
              />
            </Form.Item>

            {/* table name */}
            <Form.Item
              className="col-span-2"
              colon={false}
              label={<FormattedMessage id="cluster.clusterImport.tableLabel" />}
              name={[FIELD_NAMES.DESTINATION_NAMESPACE, FIELD_NAMES.TABLE_NAME]}
              rules={[INPUT_SANITIZATION.TABLE_NAME]}
            >
              <Input
                placeholder={formatMessage({
                  id: 'cluster.clusterImport.tableNamePlaceholder',
                })}
              />
            </Form.Item>

            {/* format */}
            <Form.Item
              label={
                <FormattedMessage id="cluster.clusterImport.formatOptionsLabel" />
              }
              name={FIELD_NAMES.FORMAT_OPTIONS}
            >
              <Select onChange={handleFormatChange} options={FORMAT_OPTIONS} />
            </Form.Item>

            {/* compression */}
            <Form.Item
              label={
                <FormattedMessage id="cluster.clusterImport.compressionOptionsLabel" />
              }
              name={FIELD_NAMES.COMPRESSION_OPTIONS}
            >
              <Select options={COMPRESSION_OPTIONS} />
            </Form.Item>

            {/* create or update table */}
            <Form.Item
              className="col-span-2"
              colon={false}
              name={[FIELD_NAMES.DESTINATION_NAMESPACE, FIELD_NAMES.CREATE_TABLE]}
              initialValue
              valuePropName="checked"
            >
              <div className="mt-8">
                <Checkbox defaultChecked>
                  <FormattedMessage id="cluster.clusterImport.schemaEvolution" />
                </Checkbox>
                <InfoPopover
                  content={
                    <div>
                      <FormattedMessage id="cluster.clusterImport.schemaEvolutionHelp" />
                    </div>
                  }
                  title={
                    <FormattedMessage id="cluster.clusterImport.schemaEvolution" />
                  }
                />
              </div>
            </Form.Item>

            {/* demo data link */}
            <div className="col-span-2 lg:col-span-3">
              {!usingDemoData && (
                <div>
                  <FormattedMessage
                    id="cluster.clusterImport.useDemoDataHelp"
                    values={{
                      demoDataButton: (
                        <button
                          className="text-crate-blue"
                          disabled={isCreatingImportJob}
                          onClick={setDemoData}
                          type="button"
                        >
                          <FormattedMessage id="cluster.clusterImport.useDemoDataPartial" />
                        </button>
                      ),
                    }}
                  />
                </div>
              )}
              {usingDemoData && (
                <div>
                  <FormattedMessage
                    id="cluster.clusterImport.demoDataExplanationText"
                    values={{
                      link: (
                        <a
                          href="https://www.nyc.gov/site/tlc/about/tlc-trip-record-data.page"
                          target="_blank"
                          rel="noreferrer"
                        >
                          taxi trip dataset
                        </a>
                      ),
                      blog_link: (
                        <a
                          href="https://cratedb.com/blog/time-series-cratedb-cloud-sql-examples"
                          target="_blank"
                          rel="noreferrer"
                        >
                          this blog post
                        </a>
                      ),
                    }}
                  />
                </div>
              )}
            </div>

            {/* submit button */}
            <div className="col-span-2 text-right lg:col-span-1">
              <Button
                loading={isCreatingImportJob}
                disabled={isCreatingImportJob || isCreateImportJobBlocked}
                form="import-data-from-url-form"
                type={Button.types.SUBMIT}
              >
                {buttonLabel}
              </Button>
            </div>
          </div>
        </Form>
        <div className="mt-2 text-right" />
      </SectionContainer>
      {jobToRetry && (
        <div className="mb-2">
          <ImportingHelpSection
            alignment={ImportingHelpSection.alignment.SECTION}
            importJob={jobToRetry}
            titleMessage={
              <FormattedMessage
                id="cluster.clusterImport.detailStatusImportFailedText"
                values={{
                  source: last(jobToRetry.url.url.split('/')),
                }}
              />
            }
          >
            <Text className="break-words" pale>
              {jobToRetry.progress.message}
            </Text>
            {jobToRetry?.progress.records > 0 && (
              <Text className="mt-2" pale>
                <FormattedMessage
                  id="cluster.clusterImport.detailStatusImportFailedImportedText"
                  values={{
                    records: formatNumber(jobToRetry?.progress.records, {
                      notation: 'compact',
                    }),
                  }}
                />
              </Text>
            )}
            {jobToRetry?.progress.failed_records > 0 && (
              <Text className="mt-2" pale>
                <FormattedMessage
                  id="cluster.clusterImport.detailStatusImportFailedFailedText"
                  values={{
                    records: formatNumber(jobToRetry?.progress.failed_records, {
                      notation: 'compact',
                    }),
                  }}
                />
              </Text>
            )}
          </ImportingHelpSection>
        </div>
      )}
      {supportedFileFormat && (
        <div className="mb-2">
          <SupportedFormatHelp fileFormat={supportedFileFormat} />
        </div>
      )}
      {unsupportedFileFormat && (
        <div className="mb-2">
          <UnsupportedFormatHelp fileFormat={unsupportedFileFormat} />
        </div>
      )}
      {knownDomain && (
        <div className="mb-2">
          <KnownImportDomainHelp knownDomain={knownDomain} />
        </div>
      )}
    </>
  );
}

URLImportForm.propTypes = {
  changeIngestSource: PropTypes.func.isRequired,
  isCreatingImportJob: PropTypes.bool.isRequired,
  jobToRetry: importJobPropType,
  onFormFinish: PropTypes.func.isRequired,
  prefillDemoData: PropTypes.bool,
  isCreateImportJobBlocked: PropTypes.bool,
};

URLImportForm.defaultProps = {
  jobToRetry: null,
  prefillDemoData: false,
  isCreateImportJobBlocked: false,
};

export default URLImportForm;
