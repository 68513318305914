import { ValueOf } from '../../types';

export const HUBSPOT_PORTAL_ID = '19927462';

export const HUBSPOT_FORMS_API_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit';

export const HUBSPOT_FORMS = {
  CLOUD_CONSOLE_DEPLOY_ENTERPRISE_CLUSTER: 'a71f9ba8-7604-4e66-8038-007fa96a909e',
  CLOUD_CONSOLE_EXCEED_QUOTA_DEPLOY_NEW: '26a7ecff-5cc3-4287-939a-6b8f669b5fb9',
  CLOUD_CONSOLE_EXCEED_QUOTA_SCALE_EXISTING: '16748991-e6ec-4469-add1-6724dd5af879',
  CLOUD_CONSOLE_REQUEST_ADD_PRIVATE_LINK: '84d6222e-446e-478b-9f4d-8af729f7e26f',
  CLOUD_CONSOLE_REQUEST_DELETE_ACCOUNT: '0369170d-7fd4-4fc5-bcaf-383015d3c3b7',
  CLOUD_CONSOLE_REQUEST_NEW_REGION: '89a40626-02ba-427b-8877-50c82ee9e2ad',
  CLOUD_CONSOLE_REQUEST_REMOVE_SUBSCRIPTION: 'b92767f8-9cd6-4865-879a-a568957579fd',
  CLOUD_CONSOLE_REQUEST_ENABLE_BANK_TRANSFER: '763c0336-daac-441a-8e01-6da0dfe04df8',
  CLOUD_CONSOLE_GENERIC_REQUEST: 'cefecf38-a258-4c2e-b396-c144cf4e398e',
} as const;
export type HubspotForm = ValueOf<typeof HUBSPOT_FORMS>;

export const HUBSPOT_REGIONS_AWS = [
  { name: 'US East (Ohio)', code: 'us-east-2' },
  { name: 'US East (N. Virginia)', code: 'us-east-1' },
  { name: 'US West (N. California)', code: 'us-west-1' },
  { name: 'US West (Oregon)', code: 'us-west-2' },
  { name: 'Africa (Cape Town)', code: 'af-south-1' },
  { name: 'Asia Pacific (Hong Kong)', code: 'ap-east-1' },
  { name: 'Asia Pacific (Hyderabad)', code: 'ap-south-2	' },
  { name: 'Asia Pacific (Jakarta)', code: 'ap-southeast-3' },
  { name: 'Asia Pacific (Mumbai)', code: 'ap-south-1' },
  { name: 'Asia Pacific (Osaka)', code: 'ap-northeast-3' },
  { name: 'Asia Pacific (Seoul)', code: 'ap-northeast-2' },
  { name: 'Asia Pacific (Singapore)', code: 'ap-southeast-1' },
  { name: 'Asia Pacific (Sydney)', code: 'ap-southeast-2' },
  { name: 'Asia Pacific (Tokyo)', code: 'ap-northeast-1' },
  { name: 'Canada (Central)', code: 'ca-central-1' },
  { name: 'Europe (Frankfurt)', code: 'eu-central-1' },
  // { name: 'Europe (Ireland)', code: 'eu-west-1' },
  { name: 'Europe (London)', code: 'eu-west-2' },
  { name: 'Europe (Milan)', code: 'eu-south-1' },
  { name: 'Europe (Paris)', code: 'eu-west-3' },
  { name: 'Europe (Spain)', code: 'eu-south-2' },
  { name: 'Europe (Stockholm)', code: 'eu-north-1' },
  { name: 'Europe (Zurich)', code: 'eu-central-2' },
  { name: 'Middle East (Bahrain)', code: 'me-south-1' },
  { name: 'Middle East (UAE)', code: 'me-central-1' },
  { name: 'South America (São Paulo)', code: 'sa-east-1' },
] as const;

export const HUBSPOT_REGIONS_AZURE = [
  { name: 'East Asia', code: 'eastasia' },
  { name: 'Southeast Asia', code: 'southeastasia' },
  { name: 'Central US', code: 'centralus' },
  { name: 'East US', code: 'eastus' },
  // { name: 'East US 2', code: 'eastus2' },
  { name: 'West US', code: 'westus' },
  { name: 'North Central US', code: 'northcentralus' },
  { name: 'South Central US', code: 'southcentralus' },
  { name: 'North Europe', code: 'northeurope' },
  // { name: 'West Europe', code: 'westeurope' },
  { name: 'Japan West', code: 'japanwest' },
  { name: 'Japan East', code: 'japaneast' },
  { name: 'Brazil South', code: 'brazilsouth' },
  { name: 'Australia East', code: 'australiaeast' },
  { name: 'Australia Southeast', code: 'australiasoutheast' },
  { name: 'South India', code: 'southindia' },
  { name: 'Central India', code: 'centralindia' },
  { name: 'West India', code: 'westindia' },
  { name: 'Canada Central', code: 'canadacentral' },
  { name: 'Canada East', code: 'canadaeast' },
  { name: 'UK South', code: 'uksouth' },
  { name: 'UK West', code: 'ukwest' },
  { name: 'West Central US', code: 'westcentralus' },
  { name: 'West US 2', code: 'westus2' },
  { name: 'Korea Central', code: 'koreacentral' },
  { name: 'Korea South', code: 'koreasouth' },
  { name: 'France Central', code: 'francecentral' },
  { name: 'France South', code: 'francesouth' },
  { name: 'Australia Central', code: 'australiacentral' },
  { name: 'Australia Central 2', code: 'australiacentral2' },
  { name: 'UAE Central', code: 'uaecentral' },
  { name: 'UAE North', code: 'uaenorth' },
  { name: 'South Africa North', code: 'southafricanorth' },
  { name: 'South Africa West', code: 'southafricawest' },
] as const;

export const HUBSPOT_REGIONS_GOOGLE = [
  { name: 'Changhua County, Taiwan', code: 'asia-east1' },
  { name: 'Hong Kong, APAC', code: 'asia-east2' },
  { name: 'Tokyo, Japan, APAC', code: 'asia-northeast1' },
  { name: 'Osaka, Japan, APAC', code: 'asia-northeast2' },
  { name: 'Seoul, South Korea, APAC', code: 'asia-northeast3' },
  { name: 'Mumbai, India APAC', code: 'asia-south1' },
  { name: 'Delhi, India APAC', code: 'asia-south2' },
  { name: 'Jurong West, Singapore, APAC', code: 'asia-southeast1' },
  { name: 'Jakarta, Indonesia, APAC', code: 'asia-southeast2' },
  { name: 'Sydney, Australia, APAC', code: 'australia-southeast1' },
  { name: 'Melbourne, Australia, APAC', code: 'australia-southeast2' },
  { name: 'Warsaw, Poland, Europe', code: 'europe-central2' },
  { name: 'Hamina, Finland, Europe', code: 'europe-north1' },
  { name: 'Madrid, Spain, Europe', code: 'europe-southwest1' },
  { name: 'St. Ghislain, Belgium, Europe', code: 'europe-west1' },
  { name: 'London, England', code: 'europe-west2' },
  { name: 'Frankfurt, Germany Europe', code: 'europe-west3' },
  { name: 'Eemshaven, Netherlands, Europe', code: 'europe-west4' },
  { name: 'Zurich, Switzerland, Europe', code: 'europe-west6' },
  { name: 'Milan, Italy, Europe', code: 'europe-west8' },
  { name: 'Paris, France, Europe', code: 'europe-west9' },
  { name: 'Tel Aviv, Israel, Middle East', code: 'me-west1' },
  { name: 'Montréal, Québec, North America', code: 'northamerica-northeast1' },
  { name: 'Toronto, Ontario, North America', code: 'northamerica-northeast2' },
  { name: 'Osasco, São Paulo, Brazil, South America', code: 'southamerica-east1' },
  { name: 'Santiago, Chile, South America', code: 'southamerica-west1' },
  { name: 'Council Bluffs, Iowa, North America', code: 'us-central1' },
  { name: 'Moncks Corner, South Carolina, North America', code: 'us-east1' },
  { name: 'Ashburn, Virginia, North America', code: 'us-east4' },
  { name: 'Columbus, Ohio, North America', code: 'us-east5' },
  { name: 'Dallas, Texas, North America', code: 'us-south1' },
  { name: 'The Dalles, Oregon, North America', code: 'us-west1' },
  { name: 'Los Angeles, California, North America', code: 'us-west2' },
  { name: 'Salt Lake City, Utah, North America', code: 'us-west3' },
  { name: 'Las Vegas, Nevada, North America', code: 'us-west4' },
];
