import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { Loader, SubmitButtonGroup, Text } from '@crate.io/crate-gc-admin';
import {
  PAYMENT_PROVIDER_BUTTON_TEST_ID,
  SUBSCRIPTION_STATES,
} from '../../../constants/defaults';
import { AZURE_MARKETPLACE_OFFER } from '../../../constants/links';
import { USER_TRACKING_EVENTS } from '../../../constants/segment';
import { useAnalytics } from '../../../hooks';
import azureLogo from '../../../assets/logo_azure_2021.svg';
import { paymentMethodPropType } from '../../../models';

function AzureSubscriptionButton({ disabled, isSelected, onClick, paymentMethod }) {
  const { trackEvent } = useAnalytics();
  const [showModal, setShowModal] = useState(false);
  const { subscription } = paymentMethod;
  const isPending = subscription?.state === SUBSCRIPTION_STATES.PENDING;

  const hideModal = () => {
    setShowModal(false);
  };

  const redirect = () => {
    hideModal();

    trackEvent(USER_TRACKING_EVENTS.NEW_CLOUD_SUBSCRIPTION);

    window.location.assign(AZURE_MARKETPLACE_OFFER);
  };

  const styles = cx(
    'border border-crate-border-mid duration-300 flex items-center mb-2 px-6 py-4 rounded text-neutral-800 text-left transition-opacity w-full disabled:text-neutral-500 disabled:border-crate-border-mid/50',
    {
      'bg-crate-blue/10': !disabled && !isPending && isSelected,
      'hover:border-crate-blue hover:bg-crate-blue/5':
        !disabled && !isPending && !isSelected,
    },
  );

  return (
    <>
      <button
        className={styles}
        data-testid={PAYMENT_PROVIDER_BUTTON_TEST_ID.AZURE}
        disabled={disabled || isPending}
        onClick={() => {
          if (subscription && !isPending) {
            onClick(paymentMethod);
          } else {
            setShowModal(true);
          }
        }}
        type="button"
      >
        <img
          src={azureLogo}
          className={cx('mr-5 h-14 w-16 object-contain', {
            'opacity-60 grayscale': isPending || disabled,
          })}
          alt="Azure logo"
        />
        {disabled && (
          <Text pale truncate>
            <FormattedMessage id="subscriptions.azure.alreadyExistsText" />
          </Text>
        )}
        {!disabled && subscription && isPending && (
          <Loader
            size={Loader.sizes.SMALL}
            message={
              <FormattedMessage id="deploy.choosePaymentProvider.pendingCloudSubscriptionSectionText" />
            }
          />
        )}
        {!disabled && subscription && !isPending && (
          <Text truncate>
            <FormattedMessage id="subscriptions.azure.useThisSubscriptionText" />
            <br />
            <span>
              <FormattedMessage id="common.ref" />: {subscription.reference}
            </span>
          </Text>
        )}
        {!disabled && !subscription && (
          <Text truncate>
            <FormattedMessage
              id="deploy.choosePaymentProvider.pristineCloudSubscriptionText"
              values={{ provider: 'Azure' }}
            />
          </Text>
        )}
      </button>
      <Modal
        footer={
          <div className="py-1">
            <SubmitButtonGroup
              confirmLabel={
                <FormattedMessage id="subscriptions.azureRedirect.confirmButton" />
              }
              form="confirm-delete"
              onCancel={hideModal}
              onSubmit={redirect}
            />
          </div>
        }
        onCancel={hideModal}
        onOk={redirect}
        title={<FormattedMessage id="subscriptions.azureRedirect.title" />}
        open={showModal}
      >
        <Text>
          <FormattedMessage id="subscriptions.azureRedirect.description1Text" />
        </Text>
        <Text>
          <FormattedMessage id="subscriptions.azureRedirect.description2Text" />
        </Text>
      </Modal>
    </>
  );
}

AzureSubscriptionButton.propTypes = {
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  paymentMethod: paymentMethodPropType.isRequired,
};

AzureSubscriptionButton.defaultProps = {
  disabled: false,
  isSelected: false,
};

export default AzureSubscriptionButton;
