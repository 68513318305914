import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { concat, flatMap, flow, reduce } from 'lodash/fp';
import ConstrainWidth from '../../../components/ConstrainWidth';
import CreditsRemaining from '../../../components/CreditsRemaining';
import ViewContainer from '../../../components/ViewContainer';
import SectionContainer from '../../../components/SectionContainer';
import { PRICE_OPTIONS_2DP } from '../../../constants/defaults';
import {
  useGetOrganizationsIdCredits,
  useGetOrganizationsIdCurrentconsumption,
  useGetProducts,
} from '../../../swrHooks';

function Usage() {
  const { organizationId } = useParams();
  const { formatNumber } = useIntl();
  const { data: currentConsumption } =
    useGetOrganizationsIdCurrentconsumption(organizationId);
  const { data: credits } = useGetOrganizationsIdCredits(organizationId);
  const { data: products } = useGetProducts();

  const noComputeConsumption = {
    consumption: {
      cost: 0,
      total: 0,
      credits: 0,
      dtuhours: 0,
      minutes: 0,
    },
    product_name: 'none',
  };

  const computeConsumption =
    currentConsumption?.compute_consumption_this_month.length > 0
      ? currentConsumption.compute_consumption_this_month
      : [noComputeConsumption];

  const totalMonthlyCost = useMemo(() => {
    const initialValue = { cost: 0, total: 0, credits: 0 };

    // in order to sum the storage and all the products
    // we first need to flatMap compute_consumption_this_month
    // because it is nested within a consumption key where
    // storage_consumption_this_month is not.
    // Then merge both storage into the array of products and reduce to a total.
    return currentConsumption
      ? flow(
          flatMap(({ consumption }) => consumption),
          concat(currentConsumption.storage_consumption_this_month),
          reduce(
            (iterator, currentValue) => ({
              cost: iterator.cost + currentValue.cost,
              credits: iterator.credits + currentValue.credits,
              total: iterator.total + currentValue.total,
            }),
            initialValue,
          ),
        )(currentConsumption.compute_consumption_this_month)
      : initialValue;
  }, [currentConsumption]);

  const currentPrice = useMemo(
    () =>
      currentConsumption
        ? {
            hour:
              currentConsumption.current_cost.hourly.compute +
              currentConsumption.current_cost.hourly.storage,
            month:
              currentConsumption.current_cost.monthly.compute +
              currentConsumption.current_cost.monthly.storage,
          }
        : 0,
    [currentConsumption],
  );

  const formatHours = (consumption, type = 'compute') => {
    const number = consumption.dtuhours;
    const suffix = number === 1 ? '' : 's';
    const unit = type === 'storage' ? `GiB-hour${suffix}` : `hour${suffix}`;
    return `${formatNumber(number, {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })} ${unit}`;
  };

  return (
    <ConstrainWidth>
      <ViewContainer
        heading={<FormattedMessage id="organization.consumption.pageTitle" />}
        loading={!currentConsumption || !credits || !products}
        render={() => (
          <div className="lg:grid lg:grid-cols-12 lg:gap-4">
            <SectionContainer
              className="mb-4 lg:col-span-8 lg:mb-0"
              title={
                <FormattedMessage id="organization.consumption.monthlyConsumptionSectionTitle" />
              }
              description={
                <FormattedMessage id="organization.consumption.monthlyConsumptionSectionText" />
              }
            >
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="w-full">&nbsp;</th>
                    <th className="w-fit py-1.5 pl-6 text-right text-neutral-500">
                      &nbsp;
                    </th>
                    <th className="py-1.5 pl-6 text-right">
                      <FormattedMessage id="organization.consumption.costColumnTitle" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {computeConsumption
                    .sort((a, b) => (a.product_name > b.product_name ? 1 : -1))
                    .map(product => (
                      <tr key={product.product_name}>
                        <td
                          className="py-1.5"
                          data-testid={`name-${product.product_name}-compute`}
                        >
                          <FormattedMessage
                            id="organization.consumption.computeLineItemText"
                            values={{
                              product:
                                products.find(p => p.name === product.product_name)
                                  ?.label || product.product_name,
                            }}
                          />
                        </td>
                        <td
                          className="whitespace-nowrap py-1.5 pl-6 text-right text-neutral-500"
                          data-testid={`price-${product.product_name}-compute-hours`}
                        >
                          {formatHours(product.consumption)}
                        </td>
                        <td
                          className="py-1.5 pl-6 text-right"
                          data-testid={`price-${product.product_name}-compute-month`}
                        >
                          {formatNumber(product.consumption.cost, PRICE_OPTIONS_2DP)}
                        </td>
                      </tr>
                    ))}
                  <tr>
                    <td className="py-1.5">
                      <FormattedMessage id="common.storage" />
                    </td>
                    <td
                      className="whitespace-nowrap py-1.5 pl-6 text-right text-neutral-500"
                      data-testid="price-storage-hours"
                    >
                      {formatHours(
                        currentConsumption.storage_consumption_this_month,
                        'storage',
                      )}
                    </td>
                    <td
                      className="py-1.5 pl-6 text-right"
                      data-testid="price-storage-month"
                    >
                      {formatNumber(
                        currentConsumption.storage_consumption_this_month.cost,
                        PRICE_OPTIONS_2DP,
                      )}
                    </td>
                  </tr>
                  <tr className="border-t-2">
                    <td className="pb-1.5 pt-2.5 font-bold">
                      <FormattedMessage id="common.total" />
                    </td>
                    <td />
                    <td
                      className="pb-1.5 pl-6 pt-2.5 text-right font-bold"
                      data-testid="sub-total-month"
                    >
                      {formatNumber(totalMonthlyCost.cost, PRICE_OPTIONS_2DP)}
                    </td>
                  </tr>
                  <tr className="border-t-2">
                    <td className="pb-1.5 pt-2.5 text-neutral-500">
                      <FormattedMessage id="organization.consumption.creditsUsedText" />
                    </td>
                    <td />
                    <td
                      className="pb-1.5 pl-6 pt-2.5 text-right text-neutral-500"
                      data-testid="credits-total-month"
                    >
                      {`-${formatNumber(
                        totalMonthlyCost.credits,
                        PRICE_OPTIONS_2DP,
                      )}`}
                    </td>
                  </tr>
                  <tr className="border-t-2">
                    <td className="pb-1.5 pt-2.5 font-bold">
                      <FormattedMessage id="organization.consumption.openBalanceText" />
                    </td>
                    <td />
                    <td
                      className="pb-1.5 pl-6 pt-2.5 text-right font-bold"
                      data-testid="price-total-month"
                    >
                      {formatNumber(totalMonthlyCost.total, PRICE_OPTIONS_2DP)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </SectionContainer>
            <div className="lg:col-span-4">
              <SectionContainer
                title={
                  <FormattedMessage id="organization.consumption.currentConsumptionSectionTitle" />
                }
                description={
                  <FormattedMessage id="organization.consumption.currentConsumptionSectionText" />
                }
              >
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="w-full">&nbsp;</th>
                      <th className="py-1.5 pl-6 text-right text-neutral-500">
                        <FormattedMessage id="common.hourly" />
                      </th>
                      <th className="py-1.5 pl-6 text-right">
                        <FormattedMessage id="common.monthly" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-1.5">
                        <FormattedMessage id="common.compute" />
                      </td>
                      <td
                        className="py-1.5 pl-6 text-right text-neutral-500"
                        data-testid="price-current-compute-hour"
                      >
                        {formatNumber(
                          currentConsumption.current_cost.hourly.compute,
                          PRICE_OPTIONS_2DP,
                        )}
                      </td>
                      <td
                        className="py-1.5 pl-6 text-right"
                        data-testid="price-current-compute-month"
                      >
                        {`~${formatNumber(
                          currentConsumption.current_cost.monthly.compute,
                          PRICE_OPTIONS_2DP,
                        )}`}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-1.5">
                        <FormattedMessage id="common.storage" />
                      </td>
                      <td
                        className="py-1.5 pl-6 text-right text-neutral-500"
                        data-testid="price-current-storage-hour"
                      >
                        {formatNumber(
                          currentConsumption.current_cost.hourly.storage,
                          PRICE_OPTIONS_2DP,
                        )}
                      </td>
                      <td
                        className="py-1.5 pl-6 text-right"
                        data-testid="price-current-storage-month"
                      >
                        {`~${formatNumber(
                          currentConsumption.current_cost.monthly.storage,
                          PRICE_OPTIONS_2DP,
                        )}`}
                      </td>
                    </tr>
                    <tr className="border-t-2">
                      <td className="pb-1.5 pt-2.5 font-bold">
                        <FormattedMessage id="common.total" />
                      </td>
                      <td
                        className="pb-1.5 pl-6 pt-2.5 text-right font-bold text-neutral-500"
                        data-testid="price-current-hour"
                      >
                        {formatNumber(currentPrice.hour, PRICE_OPTIONS_2DP)}
                      </td>
                      <td
                        className="pb-1.5 pl-6 pt-2.5 text-right font-bold"
                        data-testid="price-current-month"
                      >
                        {`~${formatNumber(currentPrice.month, PRICE_OPTIONS_2DP)}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </SectionContainer>
              <CreditsRemaining />
            </div>
          </div>
        )}
      />
    </ConstrainWidth>
  );
}

export default Usage;
