// the state in this file is not persisted to localStorage
// currently used for API error notifications only

import { create } from 'zustand';

const initialState = {
  clusterPasswords: {},
  notification: null,
};

export default create(set => ({
  ...initialState,

  clearNotification: () => {
    set({ notification: null });
  },

  setClusterPassword: (clusterId, password) => {
    set(state => ({
      clusterPasswords: { ...state.clusterPasswords, [clusterId]: password },
    }));
  },
  setNotification: (type, message, description) => {
    set({ notification: { type, message, description } });
  },
  unsetClusterPassword: clusterId => {
    set(state => {
      const clusterPasswords = { ...state.clusterPasswords };
      delete clusterPasswords[clusterId];
      return { clusterPasswords };
    });
  },
}));
