import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@crate.io/crate-gc-admin';

function SettingsOption({ label, title, read, edit, isEditing }) {
  return (
    <div aria-label={label} className="mt-4">
      <div className="mb-2">{title}</div>
      {isEditing && edit ? edit : <Text pale>{read}</Text>}
    </div>
  );
}

SettingsOption.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  read: PropTypes.string,
  edit: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  isEditing: PropTypes.bool,
};

SettingsOption.defaultProps = {
  label: null,
  read: null,
  edit: null,
  isEditing: false,
};

export default SettingsOption;
