import { ANTD_THEME_PRESET } from '../constants/defaults';

function useThemedMenuProps() {
  return {
    mode: 'inline',
    currentURLPath: window.location.pathname,
    theme: ANTD_THEME_PRESET,
  };
}

export default useThemedMenuProps;
