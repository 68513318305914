import React from 'react';
import PropTypes from 'prop-types';
import { Area, AreaChart, ResponsiveContainer, YAxis } from 'recharts';

function Sparkline({ data, dataMin, dataMax, minLength, color }) {
  const colors = {
    normal: {
      fill: '#cbe9f1',
      stroke: '#19c0ea',
    },
    high: {
      fill: '#EDCACA',
      stroke: '#dd0000',
    },
  };

  while (data && data.length < minLength) {
    data.unshift(0);
  }

  return (
    <ResponsiveContainer
      aspect={1}
      minHeight={110}
      maxHeight={176}
      minWidth={110}
      maxWidth={176}
    >
      <AreaChart data={data} margin={{ bottom: 0, left: 0, right: 0, top: 0 }}>
        <Area
          type="monotone"
          dataKey={i => i}
          fill={colors[color].fill}
          stroke={colors[color].stroke}
          strokeWidth="3"
          isAnimationActive={false}
        />
        <YAxis hide domain={[dataMin, dataMax]} />
      </AreaChart>
    </ResponsiveContainer>
  );
}

Sparkline.propTypes = {
  data: PropTypes.array.isRequired,
  dataMax: PropTypes.number.isRequired,
  dataMin: PropTypes.number.isRequired,
  minLength: PropTypes.number.isRequired,
  color: PropTypes.oneOf(['normal', 'high']),
};

Sparkline.defaultProps = {
  color: 'normal',
};

export default Sparkline;
