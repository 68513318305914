import React from 'react';
import PropTypes from 'prop-types';

function ConstrainWidth({ children }) {
  return <div className="max-w-[1200px]">{children}</div>;
}

ConstrainWidth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
};

export default ConstrainWidth;
