import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Loader, Text } from '@crate.io/crate-gc-admin';
import { OPERATION_STATES } from '../../constants/defaults';

function StatusIndicator({ status, message, progress }) {
  const renderIcon = () => {
    switch (status) {
      case OPERATION_STATES.SENT:
      case OPERATION_STATES.REGISTERED:
        return (
          <>
            <span className="absolute inset-0.5">
              <Loader color={Loader.colors.PRIMARY} size={Loader.sizes.SMALL} />
            </span>
            <Text className="ml-1" pale>
              {message}
            </Text>
          </>
        );
      case OPERATION_STATES.IN_PROGRESS:
        return (
          <>
            <div>
              <span className="absolute inset-0.5">
                <Loader color={Loader.colors.PRIMARY} size={Loader.sizes.SMALL} />
              </span>
              <div className="flex items-center justify-between">
                <Text className="ml-1" pale>
                  {message}
                </Text>
                {progress > 0 && (
                  <div className="ml-1.5 text-base text-neutral-500">
                    {progress}%
                  </div>
                )}
              </div>
            </div>
            {progress > 0 && (
              <div className="relative ml-1 mt-1 h-1 w-full rounded-none bg-neutral-100">
                <span
                  aria-labelledby="progress-label"
                  aria-valuenow={progress}
                  className="absolute bottom-0 left-0 top-0 h-full rounded-l-sm bg-crate-blue transition-all"
                  role="progressbar"
                  style={{ right: `${100 - progress}%` }}
                />
              </div>
            )}
          </>
        );
      case OPERATION_STATES.SUCCEEDED:
        return (
          <>
            <CheckCircleOutlined
              className="absolute left-0 top-1/2 -translate-y-1/2 text-base text-green-500"
              data-testid="success-icon"
            />
            <Text pale>{message}</Text>
          </>
        );
      case OPERATION_STATES.FAILED:
        return (
          <span className="flex">
            <WarningOutlined
              className="absolute left-0 top-1/2 -translate-y-1/2 text-base text-red-500"
              data-testid="error-icon"
            />
            <Text className="mr-2" pale>
              {message}
            </Text>
          </span>
        );
      default:
        return null;
    }
  };

  return <div className="relative pl-6">{renderIcon()}</div>;
}

StatusIndicator.propTypes = {
  message: PropTypes.node.isRequired,
  progress: PropTypes.number,
  status: PropTypes.oneOf(Object.values(OPERATION_STATES)).isRequired,
};

StatusIndicator.defaultProps = {
  progress: null,
};

export default StatusIndicator;
