import React from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FormattedMessage } from 'react-intl';
import { Text } from '@crate.io/crate-gc-admin';
import { CloudUISyntaxHighlighterPre } from '../../../../components/CloudUISyntaxHighlighter';
import { STEVEDORE_FILE_FORMATS } from '../constants';
import {
  CRATEDB_CLOUD_IMPORT_DATA_DOCS,
  JSON_LINES_DOCS_LINK,
} from '../../../../constants/links';
import SectionContainer from '../../../../components/SectionContainer';

const SUPPORTED_FILE_TYPE_FORMATTING_RULES = {
  [STEVEDORE_FILE_FORMATS.JSON]: [
    {
      hint: (
        <FormattedMessage
          id="cluster.clusterImport.formattingRulesJSONHint1Text"
          values={{
            link: (
              <a
                href={JSON_LINES_DOCS_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="cluster.clusterImport.formattingRulesJSONHint1Partial" />
              </a>
            ),
          }}
        />
      ),
      key: 'json-hint-1',
      language: 'json',
      snippet: `# JSON Documents. Will insert as a single row in the table.
{
  "id":1,
  "text": "example"
}

# JSON Arrays. Will insert as a row per array item.
[
  {
    "id":1,
    "text": "example"
  },
  {
    "id":2,
    "text": "example2"
  }
]

# JSON-Lines. Each line will insert as a row.
{"id":1, "text": "example"}
{"id":2, "text": "example2"}`,
    },
    {
      hint: (
        <FormattedMessage id="cluster.clusterImport.formattingRulesJSONHint2Text" />
      ),
      key: 'json-hint-2',
      language: 'json',
    },
    {
      hint: (
        <FormattedMessage id="cluster.clusterImport.formattingRulesJSONHint3Text" />
      ),
      key: 'json-hint-3',
      language: 'json',
    },
  ],
  [STEVEDORE_FILE_FORMATS.CSV]: [
    {
      hint: (
        <FormattedMessage id="cluster.clusterImport.formattingRulesCSVHint1Text" />
      ),
      key: 'csv-hint-1',
      language: 'CSV',
    },
  ],
};

function SupportedFormatHelp({ fileFormat }) {
  if (!(fileFormat in SUPPORTED_FILE_TYPE_FORMATTING_RULES)) {
    return null;
  }

  return (
    <SectionContainer>
      <div className="px-4">
        <div
          className="text-neutral-500"
          data-testid="file-formatting-pre-import-help"
        >
          <FormattedMessage
            id="cluster.clusterImport.formatHelp"
            values={{
              fileFormat,
            }}
          />
        </div>
        <ul className="list-disc pl-8 text-neutral-500">
          {SUPPORTED_FILE_TYPE_FORMATTING_RULES[fileFormat].map(
            ({ hint, key, snippet }) => (
              <li className="my-2 mb-4" key={key} data-testid={key}>
                {hint}
                {snippet && (
                  <div className="mt-2">
                    <SyntaxHighlighter
                      PreTag={CloudUISyntaxHighlighterPre}
                      language="json"
                      style={docco}
                    >
                      {snippet}
                    </SyntaxHighlighter>
                  </div>
                )}
              </li>
            ),
          )}
        </ul>
      </div>

      <Text className="px-4" pale>
        <FormattedMessage
          id="cluster.clusterImport.formattingRulesDocsText"
          values={{
            link: (
              <a
                href={CRATEDB_CLOUD_IMPORT_DATA_DOCS}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="cluster.clusterImport.docsLinkPartial" />
              </a>
            ),
          }}
        />
      </Text>
    </SectionContainer>
  );
}

SupportedFormatHelp.propTypes = {
  fileFormat: PropTypes.oneOf(Object.values(STEVEDORE_FILE_FORMATS)),
};

SupportedFormatHelp.defaultProps = {
  fileFormat: null,
};

export default SupportedFormatHelp;
