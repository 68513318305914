import React from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ConstrainWidth from '../../../components/ConstrainWidth/ConstrainWidth';
import GeneralSettings from './GeneralSettings';
import SecretList from './SecretList';
import UserList from './UserList';
import ViewContainer from '../../../components/ViewContainer';

function OrganizationSettings() {
  const { organizationId } = useParams();
  return (
    <ConstrainWidth>
      <ViewContainer
        heading={
          <FormattedMessage id="organization.organizationSettings.pageTitle" />
        }
        render={() => (
          <>
            <GeneralSettings organizationId={organizationId} />
            <UserList organizationId={organizationId} />
            <SecretList organizationId={organizationId} />
          </>
        )}
      />
    </ConstrainWidth>
  );
}

export default OrganizationSettings;
