import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { useThemedMenu } from '../../hooks';
import { SIDE_MENU_TOGGLE_DISABLED_THRESHOLD } from '../../constants/defaults';

function MenuCollapseToggle({ toggleSideMenu, collapsed }) {
  const { mode, theme } = useThemedMenu();

  return (
    <Menu
      onClick={toggleSideMenu}
      mode={mode}
      theme={theme}
      selectedKeys={[]}
      data-testid="collapse-menu-toggle"
      items={[
        {
          disabled: window.innerWidth < SIDE_MENU_TOGGLE_DISABLED_THRESHOLD,
          icon: collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />,
          key: 'collapse',
          label: (
            <FormattedMessage
              id={
                collapsed
                  ? 'sideMenu.expandMenuButton'
                  : 'sideMenu.collapseMenuButton'
              }
            />
          ),
        },
      ]}
    />
  );
}

MenuCollapseToggle.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  toggleSideMenu: PropTypes.func.isRequired,
};

export default MenuCollapseToggle;
