import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Radio } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallow } from 'zustand/shallow';
import { identity } from 'lodash/fp';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Loader, Text } from '@crate.io/crate-gc-admin';
import LabelWithTooltip from '../../../components/LabelWithTooltip';
import NotificationAside from '../../../components/NotificationAside';
import NumberStepper from '../../../components/NumberStepper';
import useDeployStore from '../state';
import { fromGibiBytes, toGibiBytes } from '../../../utils';
import { DEPLOY_OPTIONS } from '../../../constants/deploy';
import showStorageWarning from '../showStorageWarning';
import INPUT_SANITIZATION from '../../../constants/inputSanitization';

function EdgeConfiguration({ validateCustomBackupLocation }) {
  const { formatMessage } = useIntl();

  // state
  const {
    edgeBackupAccessID,
    edgeBackupAccessKey,
    edgeBackupEndpointURL,
    edgeBackupHasValidated,
    edgeBackupIsValid,
    edgeBackupIsValidating,
    edgeBackupS3BucketName,
    edgeCPUCount,
    edgeNodeCount,
    edgeRAM,
    edgeStorage,
    edgeUseDefaultBackupLocation,
    setEdgeBackupAccessID,
    setEdgeBackupAccessKey,
    setEdgeBackupEndpointURL,
    setEdgeBackupS3BucketName,
    setEdgeUseDefaultBackupLocation,
    setEdgeCPUCount,
    setEdgeNodeCount,
    setEdgeRAM,
    setEdgeStorage,
  } = useDeployStore(state => state, shallow);

  const renderBackupForm = () => (
    <>
      {/* bucket name */}
      <Form.Item
        label={
          <FormattedMessage id="deploy.backupConfiguration.backupBucketNameLabel" />
        }
        hasFeedback
        rules={[INPUT_SANITIZATION.S3_BUCKET_NAME]}
      >
        <Input
          onChange={e => {
            setEdgeBackupS3BucketName(e.target.value);
          }}
          placeholder={formatMessage({
            id: 'deploy.backupConfiguration.backupBucketNamePlaceholder',
          })}
          value={edgeBackupS3BucketName}
          data-testid="edge-backup-bucket-name"
        />
      </Form.Item>

      {/* access id */}
      <Form.Item
        label={
          <FormattedMessage id="deploy.backupConfiguration.backupAccessKeyIdLabel" />
        }
        rules={[
          {
            max: 255,
            message: formatMessage({
              id: 'deploy.backupConfiguration.backupAccessKeyIdValidationHelp',
            }),
            required: !edgeUseDefaultBackupLocation,
          },
        ]}
        hasFeedback
      >
        <Input
          autoComplete="off"
          onChange={e => {
            setEdgeBackupAccessID(e.target.value);
          }}
          placeholder={formatMessage({
            id: 'deploy.backupConfiguration.backupAccessKeyIdPlaceholder',
          })}
          value={edgeBackupAccessID}
          data-testid="edge-backup-access-id"
        />
      </Form.Item>

      {/* access key */}
      <Form.Item
        label={
          <FormattedMessage id="deploy.backupConfiguration.backupSecretAccessKeyLabel" />
        }
        rules={[
          {
            max: 255,
            message: formatMessage({
              id: 'deploy.backupConfiguration.backupSecretAccessKeyValidationHelp',
            }),
            required: !edgeUseDefaultBackupLocation,
          },
        ]}
        hasFeedback
      >
        <Input
          autoComplete="off"
          onChange={e => {
            setEdgeBackupAccessKey(e.target.value);
          }}
          placeholder={formatMessage({
            id: 'deploy.backupConfiguration.backupAccessKeyIdPlaceholder',
          })}
          value={edgeBackupAccessKey}
          data-testid="edge-backup-access-key"
        />
      </Form.Item>

      {/* endpoint url */}
      <Form.Item
        label={
          <LabelWithTooltip
            message={
              <FormattedMessage id="deploy.backupConfiguration.backupEndpointInputTooltip" />
            }
            label={
              <FormattedMessage id="deploy.backupConfiguration.backupEndpointLabel" />
            }
          />
        }
        rules={[
          {
            max: 255,
            message: formatMessage({
              id: 'deploy.backupConfiguration.backupSecretAccessKeyValidationHelp',
            }),
            required: !edgeUseDefaultBackupLocation,
          },
        ]}
        hasFeedback
      >
        <Input
          autoComplete="off"
          onChange={e => {
            setEdgeBackupEndpointURL(e.target.value);
          }}
          placeholder={formatMessage({
            id: 'deploy.backupConfiguration.backupEndpointPlaceholder',
          })}
          value={edgeBackupEndpointURL}
          data-testid="edge-backup-endpoint-url"
        />
      </Form.Item>

      <div className="flex items-center gap-4">
        <Button
          disabled={
            edgeBackupIsValidating ||
            !edgeBackupS3BucketName ||
            !edgeBackupAccessID ||
            !edgeBackupAccessKey
          }
          kind={Button.kinds.SECONDARY}
          onClick={validateCustomBackupLocation}
          size={Button.sizes.SMALL}
          type={Button.types.BUTTON}
        >
          {edgeBackupIsValidating && (
            <>
              <Loader
                color={Loader.colors.PRIMARY}
                size={Loader.sizes.SMALL}
                className="mr-2 align-middle"
              />
              <FormattedMessage id="deploy.backupConfiguration.backupTestConnectionTestingButton" />
            </>
          )}
          {!edgeBackupIsValidating && (
            <FormattedMessage id="deploy.backupConfiguration.backupTestConnectionButton" />
          )}
        </Button>
        {edgeBackupIsValid && (
          <div className="flex items-center gap-2">
            <CheckCircleOutlined className="text-base text-green-500" />
            <FormattedMessage id="deploy.backupConfiguration.backupTestConnectionSuccessText" />
          </div>
        )}
        {!edgeBackupIsValid && edgeBackupHasValidated && (
          <div className="flex items-center gap-2">
            <CloseCircleOutlined className="text-base text-red-500" />
            <FormattedMessage id="deploy.backupConfiguration.backupTestConnectionFailureText" />
          </div>
        )}
      </div>
      {!edgeBackupIsValid && edgeBackupHasValidated && (
        <p className="mt-2 text-neutral-500">
          <FormattedMessage id="deploy.backupConfiguration.backupTestConnectionFailureHelp" />
        </p>
      )}
    </>
  );

  const renderBackupLocation = () => (
    <Radio.Group
      onChange={event => setEdgeUseDefaultBackupLocation(event.target.value === 1)}
      value={edgeUseDefaultBackupLocation ? 1 : 0}
    >
      <div>
        <Text displayAs={Text.elements.span} className="pr-2">
          <FormattedMessage id="deploy.backupConfiguration.backupLocationText" />
        </Text>
        <div className="pl-2 pt-2">
          <Radio value={1}>
            <Text>
              <FormattedMessage id="deploy.backupConfiguration.defaultLabel" />
            </Text>
            <Text pale>
              <FormattedMessage id="deploy.backupConfiguration.defaultHelp" />
            </Text>
          </Radio>
        </div>
        <div className="pb-4 pl-2 pt-2">
          <Radio value={0}>
            <Text>
              <FormattedMessage id="deploy.backupConfiguration.customLabel" />
            </Text>
            <Text pale>
              <FormattedMessage id="deploy.backupConfiguration.customHelp" />
            </Text>
          </Radio>
        </div>
      </div>
    </Radio.Group>
  );

  const renderCPUCount = () => (
    <Form.Item
      className="col-span-6"
      label={<FormattedMessage id="deploy.edgeConfiguration.numberOfCPUsLabel" />}
    >
      <NumberStepper
        initialValue={edgeCPUCount}
        min={DEPLOY_OPTIONS.CPU.min}
        max={DEPLOY_OPTIONS.CPU.max}
        step={DEPLOY_OPTIONS.CPU.step}
        onValueChange={setEdgeCPUCount}
        formatter={identity}
        parser={identity}
        id="edge_cpu_count"
      />
    </Form.Item>
  );

  const renderNodeCount = () => (
    <Form.Item
      className="col-span-6"
      label={<FormattedMessage id="deploy.edgeConfiguration.numberOfNodesLabel" />}
    >
      <NumberStepper
        initialValue={edgeNodeCount}
        min={DEPLOY_OPTIONS.NODES.min + 1}
        max={DEPLOY_OPTIONS.NODES.max + 1}
        step={DEPLOY_OPTIONS.NODES.step}
        onValueChange={setEdgeNodeCount}
        formatter={identity}
        parser={identity}
        id="edge_node_count"
      />
    </Form.Item>
  );

  const renderRAM = () => (
    <Form.Item
      className="col-span-6"
      label={<FormattedMessage id="deploy.edgeConfiguration.ramInGBLabel" />}
    >
      <NumberStepper
        initialValue={edgeRAM}
        min={DEPLOY_OPTIONS.RAM.min}
        max={DEPLOY_OPTIONS.RAM.max}
        step={DEPLOY_OPTIONS.RAM.step}
        onValueChange={setEdgeRAM}
        formatter={toGibiBytes}
        parser={fromGibiBytes}
        id="edge_ram"
      />
    </Form.Item>
  );

  const renderStorage = () => (
    <Form.Item
      className="col-span-6"
      label={<FormattedMessage id="deploy.edgeConfiguration.storageInGBLabel" />}
    >
      <NumberStepper
        initialValue={edgeStorage}
        min={DEPLOY_OPTIONS.STORAGE.min}
        max={DEPLOY_OPTIONS.STORAGE.max}
        step={DEPLOY_OPTIONS.STORAGE.step}
        onValueChange={setEdgeStorage}
        formatter={toGibiBytes}
        parser={fromGibiBytes}
        id="edge_storage"
        testId="edge-storage-input"
      />
    </Form.Item>
  );

  const renderStorageWarning = () => {
    if (!showStorageWarning(edgeStorage, edgeCPUCount, edgeRAM)) {
      return null;
    }

    return (
      <div className="mb-4">
        <NotificationAside
          type={NotificationAside.types.WARN}
          message={formatMessage({
            id: 'deploy.managedConfiguration.storageWarning',
          })}
        />
      </div>
    );
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-x-4">
        {renderCPUCount()}
        {renderRAM()}
        {renderStorage()}
        {renderNodeCount()}
      </div>
      {renderStorageWarning()}
      {renderBackupLocation()}
      {!edgeUseDefaultBackupLocation && renderBackupForm()}
    </>
  );
}

EdgeConfiguration.propTypes = {
  validateCustomBackupLocation: PropTypes.func.isRequired,
};

export default EdgeConfiguration;
