import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Segmented } from 'antd';

import { INGEST_JOURNEY_STATES } from '../constants';

function ImportSourceSwitch({ changeIngestSource, currentIngestSource }) {
  const { formatMessage } = useIntl();

  const options = [
    {
      label: formatMessage({ id: 'cluster.clusterImport.typeColumnFileLabel' }),
      value: INGEST_JOURNEY_STATES.FILE_INGEST_SELECTED,
    },
    {
      label: formatMessage({ id: 'cluster.clusterImport.typeColumnUrlLabel' }),
      value: INGEST_JOURNEY_STATES.URL_INGEST_SELECTED,
    },
    {
      label: formatMessage({ id: 'cluster.clusterImport.typeColumnS3Label' }),
      value: INGEST_JOURNEY_STATES.S3_INGEST_SELECTED,
    },
    {
      label: formatMessage({ id: 'cluster.clusterImport.typeColumnAzureBlobLabel' }),
      value: INGEST_JOURNEY_STATES.AZUREBLOB_INGEST_SELECTED,
    },
  ];

  return (
    <div className="flex select-none items-center gap-2">
      <span className="text-crate-border-dark">
        <FormattedMessage id="cluster.clusterImport.typeColumnSourceLabel" />
      </span>
      <Segmented
        options={options}
        value={currentIngestSource}
        onChange={changeIngestSource}
      />
    </div>
  );
}

ImportSourceSwitch.propTypes = {
  changeIngestSource: PropTypes.func.isRequired,
  currentIngestSource: PropTypes.string.isRequired,
};

export default ImportSourceSwitch;
