import { Cluster } from '../../../types';

export const javaScriptNodeCrateCodeSnippet = (
  cluster: Cluster,
) => `const crate = require("node-crate");

crate.connect(\`https://${
  cluster.username
}:\${encodeURIComponent("<PASSWORD>")}@${cluster.fqdn.slice(0, -1)}:4200\`);

(async () => {
  const result = await crate.execute("SELECT name FROM sys.cluster");
  console.log(result.rows[0]);
})();
`;

export const javaScriptNodePostgresCodeSnippet = (
  cluster: Cluster,
) => `const { Client } = require("pg");

const crateClient = new Client({
  host: "${cluster.fqdn.slice(0, -1)}",
  port: 5432,
  user: "${cluster.username}",
  password: "<PASSWORD>",
  ssl: true,
});

(async () => {
  await crateClient.connect();
  const result = await crateClient.query("SELECT name FROM sys.cluster");
  console.log(result.rows[0]);
})();`;
