import { Cluster } from '../../../types';

export const pythonCratePythonCodeSnippet = (
  cluster: Cluster,
) => `# pip install crate
from crate import client

conn = client.connect("${cluster.url}", username="${cluster.username}", password="<PASSWORD>", verify_ssl_cert=True)

with conn:
    cursor = conn.cursor()
    cursor.execute("SELECT name FROM sys.cluster")
    result = cursor.fetchone()
    print(result)
`;

export const pythonCratePythonSqlAlchemyCodeSnippet = (
  cluster: Cluster,
) => `# pip install sqlalchemy-cratedb
import sqlalchemy as sa

dburi = "crate://${cluster.username}:<PASSWORD>@${cluster.fqdn.slice(
  0,
  -1,
)}:4200?ssl=true"
engine = sa.create_engine(dburi, echo=True)

with engine.connect() as conn:
    with conn.execute(sa.text("SELECT name FROM sys.cluster")) as cursor:
        print(cursor.fetchone())
`;

export const pythonCratePythonPandasCodeSnippet = (
  cluster: Cluster,
) => `# pip install pandas sqlalchemy-cratedb
import pandas as pd
import sqlalchemy as sa

dburi = "crate://${cluster.username}:<PASSWORD>@${cluster.fqdn.slice(
  0,
  -1,
)}:4200?ssl=true"
engine = sa.create_engine(dburi, echo=True)

with engine.connect() as conn:
    df = pd.read_sql(sql=sa.text("SELECT * FROM sys.summits"), con=conn)
    df.info()
    print(df)
`;

export const pythonPsycopg2CodeSnippet = (
  cluster: Cluster,
) => `# pip install psycopg2-binary
import psycopg2

conn = psycopg2.connect(host="${cluster.fqdn.slice(0, -1)}", port=5432, user="${
  cluster.username
}", password="<PASSWORD>", sslmode="require")

with conn:
    with conn.cursor() as cursor:
        cursor.execute("SELECT name FROM sys.cluster")
        result = cursor.fetchone()
        print(result)`;

export const pythonPsycopg3SyncCodeSnippet = (
  cluster: Cluster,
) => `# pip install psycopg[binary,pool]
import psycopg_pool

dburi = "postgres://${cluster.username}:<PASSWORD>@${cluster.fqdn.slice(0, -1)}:5432"
pool = psycopg_pool.ConnectionPool(dburi)
with pool.connection() as connection:
    with connection.cursor(row_factory=psycopg.rows.dict_row) as cursor:
        cursor.execute("SELECT name FROM sys.cluster")
        result = cursor.fetchone()
        print(result)
pool.close()
`;

export const pythonPsycopg3AsyncCodeSnippet = (
  cluster: Cluster,
) => `# pip install psycopg[binary,pool]
import psycopg_pool

dburi = "postgres://${cluster.username}:<PASSWORD>@${cluster.fqdn.slice(0, -1)}:5432"
pool = psycopg_pool.AsyncConnectionPool(dburi)
async with pool.connection() as connection:
    async with connection.cursor(row_factory=psycopg.rows.dict_row) as cursor:
        await cursor.execute("SELECT name FROM sys.cluster")
        results = await cursor.fetchone()
        print(results)
await pool.close()
`;

export const pythonAIOPGCodeSnippet = (cluster: Cluster) => `# pip install aoipg
import asyncio
import aiopg

async def run():
    async with aiopg.create_pool(host="${cluster.fqdn.slice(
      0,
      -1,
    )}", port=5432, user="${
      cluster.username
    }", password="<PASSWORD>", sslmode="require") as pool:
        async with pool.acquire() as conn:
            async with conn.cursor() as cursor:
                await cursor.execute("SELECT name FROM sys.cluster")
                result = await cursor.fetchone()
    print(result)

loop = asyncio.get_event_loop()
loop.run_until_complete(run())`;

export const pythonAsyncPGCodeSnippet = (cluster: Cluster) => `# pip install asyncpg
import asyncio
import asyncpg

async def run():
    conn = await asyncpg.connect(host="${cluster.fqdn.slice(
      0,
      -1,
    )}", port=5432, user="${cluster.username}", password="<PASSWORD>", ssl=True)
    try:
        result = await conn.fetch("SELECT name FROM sys.cluster")
    finally:
        await conn.close()
    print(result)

loop = asyncio.get_event_loop()
loop.run_until_complete(run())`;
