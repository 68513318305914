import { Cluster } from '../../../types';

export const phpPdoCodeSnippet = (cluster: Cluster) => `<?php

require 'vendor/autoload.php';

use Crate\\PDO\\PDO as PDO;

$pdo = new PDO(
    'crate:${cluster.fqdn.slice(0, -1)}:4200',
    '${cluster.username}',
    '<PASSWORD>'
);

$stm = $pdo->query('SELECT name FROM sys.cluster');
$name = $stm->fetch();
print $name[0];

?>

`;

export const phpDbalCodeSnippet = (cluster: Cluster) => `
<?php

require 'vendor/autoload.php';

$params = array(
    'driverClass' => 'Crate\\DBAL\\Driver\\PDOCrate\\Driver',
    'user' => '${cluster.username}',
    'password' = '<PASSWORD>',
    'host' => '${cluster.fqdn.slice(0, -1)}',
    'port' => 4200
);

$connection = \\Doctrine\\DBAL\\DriverManager::getConnection($params);
$sql = 'SELECT name FROM sys.cluster';
$name = $connection->query($sql)->fetch();

print $name['name'];

?>`;
