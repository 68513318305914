import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Checkbox, Form, Input, Select } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileAddOutlined, FileTextOutlined } from '@ant-design/icons';
import { last, isNull } from 'lodash/fp';
import { Button, Heading, Text } from '@crate.io/crate-gc-admin';

import ImportSourceSwitch from '../ImportSourceSwitch';
import SectionContainer from '../../../../components/SectionContainer';
import FileObject from '../../../../components/FileObject';
import { fromBytes } from '../../../../utils';
import {
  COMPRESSION_OPTIONS,
  DEFAULT_FIELD_VALUES,
  FIELD_NAMES,
  FILE_COMPRESSION_FORMATS,
  FILE_COMPRESSION_TYPES,
  FORMAT_OPTIONS,
  INGEST_JOURNEY_STATES,
  MIME_TYPES_TO_STEVEDORE_FORMATS,
  STEVEDORE_FILE_FORMATS,
  SUPPORTED_FILE_TYPES,
  UNSUPPORTED_FILE_TYPES,
} from '../constants';
import { FILE_UPLOAD_FILE_SIZE_LIMIT_BYTES } from '../../../../constants/defaults';
import InfoPopover from '../../../../components/InfoPopover';
import SupportedFormatHelp from '../SupportedFormatHelp';
import UnsupportedFormatHelp from '../UnsupportedFormatHelp';
import { CRATEDB_CLOUD_IMPORT_FILE_DOCS } from '../../../../constants/links';
import { importJobPropType } from '../../../../models';
import INPUT_SANITIZATION from '../../../../constants/inputSanitization';

const ERROR_TYPES = {
  ERROR_POSTING_FILE: 'ERROR_POSTING_FILE',
  ERROR_FILE_SIZE: 'ERROR_FILE_SIZE',
  ERROR_FILE_FORMAT: 'ERROR_FILE_FORMAT',
};

const mapMimeTypesToStevedoreFormats = mimeType =>
  MIME_TYPES_TO_STEVEDORE_FORMATS[mimeType];

const getFormSchemaFromFieldValues = ({ compression, format, tableName }) => ({
  [FIELD_NAMES.COMPRESSION_OPTIONS]: compression,
  [FIELD_NAMES.FORMAT_OPTIONS]: format,
  [FIELD_NAMES.DESTINATION_NAMESPACE]: {
    [FIELD_NAMES.TABLE_NAME]: tableName,
  },
});

function FileImportForm({
  attachedFile,
  changeIngestSource,
  errorPostingFile,
  fileUploadProgress,
  isCreatingImportJob,
  jobToRetry,
  onAttachFile,
  onCancelUpload,
  onFormFinish,
  onRemoveFile,
  recentlyUploadedFiles,
  isCreateImportJobBlocked,
}) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();
  const [isDraggingFile, setIsDraggingFile] = useState(false);
  const [supportedFileFormat, setSupportedFileFormat] = useState(
    STEVEDORE_FILE_FORMATS.JSON,
  );
  const [unsupportedFileFormat, setUnsupportedFileFormat] = useState(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);

  // useMemo necessary here for supportedFileTypes and unsupportedFileTypes
  // to stop useEffect below re-rendering when select form input changes
  const supportedFileTypes = useMemo(() => Object.values(SUPPORTED_FILE_TYPES), []);
  const unsupportedFileTypes = useMemo(
    () => Object.values(UNSUPPORTED_FILE_TYPES),
    [],
  );
  const stevedoreFileFormats = useMemo(
    () => Object.values(STEVEDORE_FILE_FORMATS),
    [],
  );
  const fileCompressionTypes = useMemo(
    () => Object.values(FILE_COMPRESSION_TYPES),
    [],
  );
  const fileCompressionFormats = useMemo(
    () => Object.values(FILE_COMPRESSION_FORMATS),
    [],
  );

  const strings = useMemo(() => {
    const getSectionContainerTitle = () => {
      if (!isNull(fileUploadProgress)) {
        return (
          <FormattedMessage id="cluster.clusterImport.importFromFile.uploadInProgressSectionTitle" />
        );
      }

      return (
        <FormattedMessage id="cluster.clusterImport.importFromFile.sectionTitle" />
      );
    };
    const getSectionContainerDescription = () => {
      if (!isNull(fileUploadProgress)) {
        return (
          <FormattedMessage id="cluster.clusterImport.importFromFile.uploadInProgressSectionDescription" />
        );
      }

      return null;
    };
    return {
      button: isCreatingImportJob ? (
        <FormattedMessage id="cluster.clusterImport.registeringImportButton" />
      ) : (
        <FormattedMessage id="cluster.clusterImport.submitButton" />
      ),
      SectionContainerTitle: getSectionContainerTitle(),
      SectionContainerDescription: getSectionContainerDescription(),
    };
  }, [fileUploadProgress, isCreatingImportJob]);

  useEffect(() => {
    if (attachedFile && !error) {
      if (attachedFile.size > FILE_UPLOAD_FILE_SIZE_LIMIT_BYTES) {
        setError({
          message: (
            <FormattedMessage
              id="cluster.clusterImport.importFromUrl.maxFileSizeExceededHelp"
              values={{
                bytes: fromBytes(attachedFile.size).format(),
              }}
            />
          ),
          type: ERROR_TYPES.ERROR_FILE_SIZE,
        });
        return;
      }

      // populate the form inputs with what we can
      // read from the attached file or selected file from reimport list.
      // "type" can be a mime type, the file extension or undefined
      const { name, type } = attachedFile;
      const fileExtension = last(name.split('.'));
      let compression = 'none';
      let fileType = type || fileExtension;

      if ([...fileCompressionTypes, ...fileCompressionFormats].includes(fileType)) {
        compression = STEVEDORE_FILE_FORMATS.GZIP;
        [fileType] = name.split('.').slice(-2, -1);
      }

      if (supportedFileTypes.includes(fileType)) {
        fileType = mapMimeTypesToStevedoreFormats(fileType);
      }

      if (stevedoreFileFormats.includes(fileType)) {
        form.setFieldValue(FIELD_NAMES.COMPRESSION_OPTIONS, compression);
        form.setFieldValue(FIELD_NAMES.FORMAT_OPTIONS, fileType);
        setSupportedFileFormat(fileType);
      } else if (unsupportedFileTypes.includes(fileType)) {
        setUnsupportedFileFormat(fileType);
      }
    }
  }, [
    attachedFile,
    error,
    form,
    formatMessage,
    supportedFileTypes,
    unsupportedFileTypes,
    stevedoreFileFormats,
    fileCompressionTypes,
    fileCompressionFormats,
  ]);

  useEffect(() => {
    if (errorPostingFile) {
      setError({ ...errorPostingFile, type: ERROR_TYPES.ERROR_POSTING_FILE });
    }
  }, [errorPostingFile]);

  useEffect(() => {
    if (jobToRetry && jobToRetry.type === 'file') {
      const {
        compression,
        destination: { table: tableName },
        format,
      } = jobToRetry;

      form.setFieldsValue(
        getFormSchemaFromFieldValues({ compression, format, tableName }),
      );

      if (supportedFileTypes.includes(format)) {
        setSupportedFileFormat(format);
      }
    }

    return function clearformFieldsOnUnmount() {
      form.resetFields();
    };
  }, [form, jobToRetry, supportedFileTypes]);

  const handleBrowseForFileButtonClick = () => {
    inputRef.current.click();
  };

  const handleFormatChange = value => {
    setSupportedFileFormat(value);
    setUnsupportedFileFormat(null);
  };

  const handleFileInputChange = event => {
    onAttachFile(event.target.files[0]);
  };

  const handleReimportChange = value => {
    const file = recentlyUploadedFiles.find(f => f.id === value);
    onAttachFile({
      ...file,
      size: file.file_size,
      type: last(file.name.split('.')),
    });
  };

  const handleDrag = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'dragenter' || event.type === 'dragover') {
      setIsDraggingFile(true);
    } else if (event.type === 'dragleave') {
      setIsDraggingFile(false);
    }
  };

  const handleDrop = event => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraggingFile(false);
    onAttachFile(event.dataTransfer.files[0]);
  };

  const handleRemoveFile = () => {
    form.resetFields();
    onRemoveFile();
    setError(null);
    setSupportedFileFormat(null);
    setUnsupportedFileFormat(null);

    if (!isNull(fileUploadProgress)) {
      onCancelUpload();
    }
  };

  const getRecentFileLabel = file => {
    const size = fromBytes(file.file_size).format();

    return (
      <FormattedMessage
        id="cluster.clusterImport.importFromFile.reimportedFileLabelText"
        values={{
          name: file.name,
          size,
        }}
      />
    );
  };

  return (
    <>
      <SectionContainer
        actions={
          <ImportSourceSwitch
            changeIngestSource={changeIngestSource}
            currentIngestSource={INGEST_JOURNEY_STATES.FILE_INGEST_SELECTED}
          />
        }
        title={strings.SectionContainerTitle}
        description={strings.SectionContainerDescription}
      >
        <div className="-mt-4 mb-4 text-neutral-500">
          <FormattedMessage id="cluster.clusterImport.importFromFile.sectionDescription" />
          <a href={CRATEDB_CLOUD_IMPORT_FILE_DOCS} target="_blank" rel="noreferrer">
            <FormattedMessage id="cluster.clusterImport.importFromFile.sectionAdditionalDescription" />
          </a>
        </div>
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-4">
          <div
            aria-live="polite"
            className="mb-4 lg:col-span-6 lg:mb-0"
            role="region"
          >
            {!attachedFile && isNull(fileUploadProgress) && (
              <form
                onDragEnter={handleDrag}
                onDrop={handleDrop}
                className={cx(
                  'flex ',
                  'flex-col',
                  'h-full',
                  'items-center',
                  'justify-center',
                  'p-4',
                  'relative',
                  'rounded',
                  'text-center',
                  'transition',
                  {
                    'bg-crate-body-background': isDraggingFile,
                    'border-2': !isDraggingFile,
                    'border-neutral-300': !isDraggingFile,
                    'border-dashed': !isDraggingFile,
                  },
                )}
              >
                <div
                  className="
                  mb-4
                  flex
                  h-12
                  w-12
                  items-center
                  justify-center
                  rounded-full
                  border
                  border-neutral-200
                  bg-white
                "
                >
                  {isDraggingFile ? (
                    <FileAddOutlined className="text-xl text-crate-blue" />
                  ) : (
                    <FileTextOutlined className="text-xl text-crate-blue" />
                  )}
                </div>

                <div className="mb-0.5 flex items-center justify-center">
                  <input
                    className="hidden"
                    data-testid="input-file-upload"
                    onChange={handleFileInputChange}
                    ref={inputRef}
                    type="file"
                  />
                  <div className="text-base font-bold text-neutral-500">
                    <FormattedMessage id="cluster.clusterImport.importFromFile.fileUploadUIHelpText" />
                    <button
                      className="ml-1 text-crate-blue"
                      type="button"
                      onClick={handleBrowseForFileButtonClick}
                    >
                      <FormattedMessage id="cluster.clusterImport.importFromFile.fileUploadUIButton" />
                    </button>
                  </div>
                </div>
                <Text pale>
                  <FormattedMessage id="cluster.clusterImport.importFromFile.allowedFileSizeText" />
                  <FormattedMessage id="cluster.clusterImport.importFromFile.allowedFileFormatsText" />
                </Text>
                {isDraggingFile && (
                  <div
                    className="absolute inset-0 rounded border-2 border-crate-blue"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  />
                )}
              </form>
            )}
            {attachedFile && (
              <FileObject
                attachedFile={attachedFile}
                disabled={isCreatingImportJob}
                error={error}
                onRemove={handleRemoveFile}
                progress={fileUploadProgress}
              />
            )}
          </div>
          <div className="lg:col-span-6">
            <Form
              autoComplete="off"
              disabled={error || isCreatingImportJob || fileUploadProgress}
              form={form}
              initialValues={{
                ...DEFAULT_FIELD_VALUES,
                [FIELD_NAMES.TYPE]: 'file',
              }}
              layout="vertical"
              name="import-data-from-file-form"
              id="import-data-from-file-form"
              aria-label="import data from url form"
              onFinish={onFormFinish}
            >
              <Form.Item
                colon={false}
                label={<FormattedMessage id="cluster.clusterImport.tableLabel" />}
                name={[FIELD_NAMES.DESTINATION_NAMESPACE, FIELD_NAMES.TABLE_NAME]}
                rules={[INPUT_SANITIZATION.TABLE_NAME]}
              >
                <Input
                  placeholder={formatMessage({
                    id: 'cluster.clusterImport.tableNamePlaceholder',
                  })}
                />
              </Form.Item>

              <div className="md:flex md:justify-between">
                <div className="md:mr-4 md:w-1/2">
                  <Form.Item
                    label={
                      <FormattedMessage id="cluster.clusterImport.formatOptionsLabel" />
                    }
                    name={FIELD_NAMES.FORMAT_OPTIONS}
                  >
                    <Select onChange={handleFormatChange} options={FORMAT_OPTIONS} />
                  </Form.Item>
                </div>
                <div className="md:w-1/2">
                  <Form.Item
                    label={
                      <FormattedMessage id="cluster.clusterImport.compressionOptionsLabel" />
                    }
                    name={FIELD_NAMES.COMPRESSION_OPTIONS}
                  >
                    <Select options={COMPRESSION_OPTIONS} />
                  </Form.Item>
                </div>
              </div>

              {/* create or update table */}
              <Form.Item
                className="col-span-2"
                colon={false}
                name={[FIELD_NAMES.DESTINATION_NAMESPACE, FIELD_NAMES.CREATE_TABLE]}
                initialValue
                valuePropName="checked"
              >
                <div>
                  <Checkbox defaultChecked>
                    <FormattedMessage id="cluster.clusterImport.schemaEvolution" />
                  </Checkbox>
                  <InfoPopover
                    content={
                      <div>
                        <FormattedMessage id="cluster.clusterImport.schemaEvolutionHelp" />
                      </div>
                    }
                    title={
                      <FormattedMessage id="cluster.clusterImport.schemaEvolution" />
                    }
                  />
                </div>
              </Form.Item>

              {/* hidden field type */}
              <Form.Item name={[FIELD_NAMES.TYPE]} hidden>
                <Input />
              </Form.Item>

              <div className="flex items-center justify-end">
                <Button
                  loading={isCreatingImportJob}
                  disabled={
                    !attachedFile ||
                    !!error ||
                    isCreatingImportJob ||
                    !!fileUploadProgress ||
                    isCreateImportJobBlocked
                  }
                  form="import-data-from-file-form"
                  type={Button.types.SUBMIT}
                >
                  {strings.button}
                </Button>
              </div>
            </Form>
          </div>
        </div>
        {recentlyUploadedFiles && recentlyUploadedFiles.length > 0 && (
          <div className="mt-5 lg:grid lg:grid-cols-12">
            <Heading level={Heading.levels.h2} className="lg:col-span-12">
              <FormattedMessage id="cluster.clusterImport.importFromFile.reimportSectionTitle" />
            </Heading>

            <div className="mt-5 lg:col-span-7">
              <Select
                className="w-full"
                data-testid="recent-file-select"
                disabled={error || isCreatingImportJob || fileUploadProgress}
                onChange={handleReimportChange}
                options={recentlyUploadedFiles.map(f => ({
                  label: getRecentFileLabel(f),
                  value: f.id,
                }))}
                optionLabelProp="label"
                placeholder={
                  <FormattedMessage id="cluster.clusterImport.importFromFile.selectReimportPlaceholderText" />
                }
              />
            </div>
          </div>
        )}
      </SectionContainer>
      {supportedFileFormat && (
        <SupportedFormatHelp fileFormat={supportedFileFormat} />
      )}
      {unsupportedFileFormat && (
        <UnsupportedFormatHelp fileFormat={unsupportedFileFormat} />
      )}
    </>
  );
}

FileImportForm.propTypes = {
  attachedFile: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.number.isRequired,
    type: PropTypes.string,
  }),
  changeIngestSource: PropTypes.func.isRequired,
  errorPostingFile: PropTypes.shape({
    message: PropTypes.string.isRequired,
    success: PropTypes.bool.isRequired,
  }),
  isCreatingImportJob: PropTypes.bool.isRequired,
  jobToRetry: importJobPropType,
  fileUploadProgress: PropTypes.number,
  onAttachFile: PropTypes.func.isRequired,
  onFormFinish: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  recentlyUploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      file_size: PropTypes.number,
      id: PropTypes.string,
    }),
  ).isRequired,
  isCreateImportJobBlocked: PropTypes.bool,
};

FileImportForm.defaultProps = {
  attachedFile: null,
  errorPostingFile: null,
  fileUploadProgress: null,
  jobToRetry: null,
  isCreateImportJobBlocked: false,
};

export default FileImportForm;
