import React from 'react';
import PropTypes from 'prop-types';
import useMessage from '../../hooks/useMessage';

function CopyToClipboard({ children, successMessage, textToCopy, testId }) {
  const { showSuccessMessage } = useMessage();

  return (
    <button
      data-testid={testId}
      onClick={() => {
        navigator.clipboard.writeText(textToCopy);
        showSuccessMessage(successMessage);
      }}
      type="button"
    >
      {children}
    </button>
  );
}

CopyToClipboard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
    .isRequired,
  successMessage: PropTypes.string,
  textToCopy: PropTypes.string.isRequired,
  testId: PropTypes.string,
};

CopyToClipboard.defaultProps = {
  successMessage: 'Copied',
  testId: 'copy-to-clipboard-button',
};

export default CopyToClipboard;
