import { HUBSPOT_PORTAL_ID, HUBSPOT_FORMS_API_URL } from './constants/hubspot';
import useSessionStore from './state/session';

const dispatchNotification = (type, message, description) => {
  useSessionStore.getState().setNotification(type, message, description);
};

const api = async (url, method, data, options = {}, notification = true) => {
  const response = await fetch(url, {
    method,
    body: data ? JSON.stringify(data) : null,
    headers: data
      ? {
          'Content-Type': 'application/json; charset=utf-8',
        }
      : {},
    ...options,
  });

  // update the output depending on the result of the fetch()
  const output = { success: true, status: response.status, data: null };
  try {
    output.data = await response.json();
  } catch (error) {
    // API returned an empty response: do nothing
  }

  // do not show a 404 error for invalid invitation tokens
  if (response.status === 404 && url.includes('/accept-invite/')) {
    output.success = false;
    return output;
  }

  if (!response.ok && notification) {
    if (response.status === 404) {
      dispatchNotification(
        'error',
        'The requested resource was not found. ' +
          'If the problem persists, please contact customer support.',
        null,
      );
    } else if (response.status >= 500) {
      dispatchNotification(
        'error',
        'An error occurred while accessing the server. ' +
          'Our team have been notified. Please try again later.',
        null,
      );
    } else if (response.status >= 400) {
      dispatchNotification('error', output.data.message, null);
    }

    output.success = false;
  }

  return output;
};

// exported functions
export const apiDelete = async (url, data, options = {}) =>
  api(url, 'DELETE', data, options);
export const apiHead = async (url, data, options = {}) =>
  api(url, 'HEAD', data, options);
export const apiPatch = async (url, data, options = {}) =>
  api(url, 'PATCH', data, options);
export const apiPost = async (url, data, options = {}, notification = true) =>
  api(url, 'POST', data, options, notification);
export const apiPut = async (url, data, options = {}) =>
  api(url, 'PUT', data, options);
export const apiGet = async (url, data, options = {}) =>
  api(url, 'GET', data, options);

// hubspot forms API
export const postHubspotForm = async (formId, fields) =>
  apiPost(
    `${HUBSPOT_FORMS_API_URL}/${HUBSPOT_PORTAL_ID}/${formId}`,
    {
      fields: Object.entries(fields).map(([key, value]) => ({
        name: key,
        value: value || '',
      })),
    },
    { cache: 'no-cache', credentials: 'omit', mode: 'cors' },
  );
