import React from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Heading, Text } from '@crate.io/crate-gc-admin';
import CopyToClipboard from '../CopyToClipboard';
import CustomPreTag from './CustomPreTag';

function CloudUISyntaxHighlighter({
  language,
  children,
  title,
  helptext,
  linkUrl,
  onClick,
}) {
  return (
    <div>
      {title && (
        <Heading level={Heading.levels.h4} className="mb-2 mt-6">
          {title}
        </Heading>
      )}

      <div className="rounded-lg bg-neutral-100 py-1 pl-2 pr-4">
        <div className="flex items-start justify-between">
          <SyntaxHighlighter
            language={language}
            PreTag={CustomPreTag}
            style={docco}
            wrapLongLines
          >
            {children}
          </SyntaxHighlighter>

          <CopyToClipboard textToCopy={children}>
            <Text className="py-2 text-sm text-crate-blue">
              <FormattedMessage id="common.copy" />
            </Text>
          </CopyToClipboard>
        </div>
      </div>
      {(helptext || linkUrl) && (
        <div className="mt-1 flex items-start justify-between px-1">
          <div className="mr-2 text-base text-neutral-500">
            {helptext && helptext}
          </div>
          {linkUrl && (
            <div className="flex items-center gap-1">
              <PlayCircleOutlined className="text-crate-blue" />
              <a
                className="text-sm text-crate-blue"
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={onClick}
              >
                <FormattedMessage id="cloudUISyntaxHighlighter.runQueryInAdminUIButton" />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CloudUISyntaxHighlighter.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  helptext: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  language: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  linkUrl: PropTypes.string,
  onClick: PropTypes.func,
};

CloudUISyntaxHighlighter.defaultProps = {
  helptext: null,
  linkUrl: null,
  onClick: null,
  title: null,
};

export default CloudUISyntaxHighlighter;
