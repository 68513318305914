import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Heading } from '@crate.io/crate-gc-admin';
import LoadingContainer from '../LoadingContainer';

function ViewContainer({ extra, heading, loading, render }) {
  const styles = cx(
    'flex',
    'flex-wrap',
    'gap-4',
    'items-center',
    'justify-between',
    'my-4',
    'pb-4',
  );

  return (
    <LoadingContainer
      isViewContainer
      loading={loading}
      loaderAlignment={LoadingContainer.loaderAlignment.CENTER}
      render={() => (
        <>
          <div className={styles}>
            <Heading displayAs={Heading.levels.h4} level={Heading.levels.h1}>
              {heading}
            </Heading>
            {extra && extra}
          </div>
          <div className="pb-4">{render()}</div>
        </>
      )}
    />
  );
}

ViewContainer.propTypes = {
  render: PropTypes.func.isRequired,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
};

ViewContainer.defaultProps = {
  heading: '',
  extra: null,
  loading: false,
};

export default ViewContainer;
