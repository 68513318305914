const getTotalCredits = credits => {
  if (!credits) {
    return 0;
  }

  return credits.reduce((prev, next) => {
    return prev + next.remaining_amount;
  }, 0);
};

export default getTotalCredits;
