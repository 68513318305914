import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input, Select } from 'antd';
import { Button } from '@crate.io/crate-gc-admin';
import SectionContainer from '../../../../components/SectionContainer';
import Tag from '../../../../components/Tag';
import {
  COMPRESSION_OPTIONS,
  DEFAULT_FIELD_VALUES,
  FIELD_NAMES,
  FORMAT_OPTIONS,
  STEVEDORE_FILE_FORMATS,
} from '../constants';
import INPUT_SANITIZATION from '../../../../constants/inputSanitization';

function CreateExportForm({
  isCreatingExportJob,
  onFormFinish,
  isCreateExportJobBlocked,
}) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm();

  const buttonLabel = useMemo(() => {
    if (isCreatingExportJob) {
      return (
        <FormattedMessage id="cluster.clusterExport.exportForm.registeringImportButton" />
      );
    }

    return <FormattedMessage id="cluster.clusterExport.exportForm.submitButton" />;
  }, [isCreatingExportJob]);

  const onFormatChange = format => {
    if (format === STEVEDORE_FILE_FORMATS.PARQUET) {
      form.setFieldValue(FIELD_NAMES.COMPRESSION_OPTIONS, 'none');
    } else {
      form.setFieldValue(
        FIELD_NAMES.COMPRESSION_OPTIONS,
        STEVEDORE_FILE_FORMATS.GZIP,
      );
    }
  };

  return (
    <SectionContainer
      actions={<Tag label={<FormattedMessage id="common.beta" />} />}
      title={<FormattedMessage id="cluster.clusterExport.newExportSectionTitle" />}
      description={
        <FormattedMessage id="cluster.clusterExport.newExportSectionDescription" />
      }
    >
      <Form
        autoComplete="off"
        disabled={isCreatingExportJob}
        form={form}
        initialValues={DEFAULT_FIELD_VALUES}
        layout="vertical"
        name="export-data-form"
        id="export-data-form"
        aria-label="export data from url form"
        onFinish={onFormFinish}
      >
        <Form.Item
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterExport.exportForm.tableOrViewLabel" />
          }
          name={[FIELD_NAMES.SOURCE_NAMESPACE, FIELD_NAMES.TABLE_OR_VIEW_NAME]}
          rules={[INPUT_SANITIZATION.TABLE_NAME]}
        >
          <Input
            placeholder={formatMessage({
              id: 'cluster.clusterExport.exportForm.tableOrViewPlaceholder',
            })}
          />
        </Form.Item>

        <div className="md:flex md:justify-between">
          <div className="md:mr-4 md:w-1/2">
            <Form.Item
              label={
                <FormattedMessage id="cluster.clusterImport.formatOptionsLabel" />
              }
              name={FIELD_NAMES.FORMAT_OPTIONS}
            >
              <Select options={FORMAT_OPTIONS} onChange={onFormatChange} />
            </Form.Item>
          </div>
          <div className="md:w-1/2">
            <Form.Item
              label={
                <FormattedMessage id="cluster.clusterImport.compressionOptionsLabel" />
              }
              name={FIELD_NAMES.COMPRESSION_OPTIONS}
            >
              <Select options={COMPRESSION_OPTIONS} />
            </Form.Item>
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            loading={isCreatingExportJob}
            disabled={isCreatingExportJob || isCreateExportJobBlocked}
            form="export-data-form"
            type={Button.types.SUBMIT}
          >
            {buttonLabel}
          </Button>
        </div>
      </Form>
    </SectionContainer>
  );
}

CreateExportForm.propTypes = {
  isCreatingExportJob: PropTypes.bool.isRequired,
  onFormFinish: PropTypes.func.isRequired,
  isCreateExportJobBlocked: PropTypes.bool,
};

CreateExportForm.defaultProps = {
  isCreateExportJobBlocked: false,
};

export default CreateExportForm;
