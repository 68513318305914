const messages = {
  // addUserToOrganization
  'organization.addUserToOrganization.addNewUserTitle': 'Add a new user',
  'organization.addUserToOrganization.emailInputLabel': 'User',
  'organization.addUserToOrganization.chooseRoleLabel': 'Role',
  'organization.addUserToOrganization.emailInputPlaceholder':
    'Enter email or user id',
  'organization.addUserToOrganization.emptyEmailHelp':
    'Please enter an email address or user id',
  'organization.addUserToOrganization.invalidEmailHelp':
    'Please enter a valid email address or user id',
  'organization.addUserToOrganization.invitingUserText':
    'Inviting {email} to your organization.',
  'organization.addUserToOrganization.userInvitedText':
    '{email} has been invited to your organization.',

  // auditLog
  'organization.auditLogs.pageTitle': 'Audit Logs',
  'organization.auditLogs.selectClusterLabel': 'Select cluster',
  'organization.auditLogs.selectActionLabel': 'Select action',
  'organization.auditLogs.allClustersOption': 'All clusters',
  'organization.auditLogs.allActionsOption': 'All actions',

  'organization.auditLogs.organizationOption': 'Organization',
  'organization.auditLogs.createOrganizationOption': 'Create Organization',
  'organization.auditLogs.editOrganizationOption': 'Edit Organization',
  'organization.auditLogs.addUserToOrganizationOption': 'Add User to Organization',
  'organization.auditLogs.updateUserInOrganizationOption':
    'Update User in Organization',
  'organization.auditLogs.removeUserFromOrganizationOption':
    'Remove User from Organization',

  'organization.auditLogs.creditsOption': 'Credits',
  'organization.auditLogs.creditsCreateOption': 'Add credits',
  'organization.auditLogs.creditsExpireOption': 'Expire credits',
  'organization.auditLogs.creditsEditOption': 'Edit credits',

  'organization.auditLogs.projectOption': 'Project',
  'organization.auditLogs.createProjectOption': 'Create Project',
  'organization.auditLogs.editProjectOption': 'Edit Project',
  'organization.auditLogs.addUserToProjectOption': 'Add User to Project',
  'organization.auditLogs.updateUserInProjectOption': 'Update User in Project',
  'organization.auditLogs.removeUserFromProjectOption': 'Remove User from Project',
  'organization.auditLogs.deleteProjectOption': 'Delete Project',

  'organization.auditLogs.consumerOption': 'Consumer',
  'organization.auditLogs.createConsumerOption': 'Create Consumer',
  'organization.auditLogs.editConsumerOption': 'Edit Consumer',
  'organization.auditLogs.deleteConsumerOption': 'Delete Consumer',

  'organization.auditLogs.clusterOption': 'Cluster',
  'organization.auditLogs.createClusterOption': 'Create Cluster',
  'organization.auditLogs.planClusterOption': 'Cluster Plan',
  'organization.auditLogs.scaleClusterOption': 'Scale Cluster',
  'organization.auditLogs.storageClusterOption': 'Cluster Storage',
  'organization.auditLogs.upgradeClusterOption': 'Upgrade Cluster',
  'organization.auditLogs.restoreSnapshotOption': 'Restore Snapshot',
  'organization.auditLogs.initiateRestoreSnapshotOption':
    'Initiate Restore Snapshot',
  'organization.auditLogs.deleteClusterOption': 'Delete Cluster',
  'organization.auditLogs.restartClusterNode': 'Restart Cluster node',

  'organization.auditLogs.loadMoreButton': 'Load more',
  'organization.auditLogs.loadingMoreButton': 'Loading more',
  'organization.auditLogs.noData': 'No logs found',

  'organization.auditLogs.subscriptionsOption': 'Subscription',
  'organization.auditLogs.saasSubscribeOption': 'Subscription created',
  'organization.auditLogs.saasUnsubscribeOption': 'Subscription canceled',
  'organization.auditLogs.saasSubscriptionActiveOption': 'Subscription activated',
  'organization.auditLogs.saasSubscriptionOrgAssignedOption':
    'Org assigned to subscription',

  // billing
  'organization.billing.pageTitle': 'Billing',
  'organization.billing.noSubscriptions': 'No billing subscriptions available',
  'organization.billing.provideInvoiceDetails':
    'Please provide your invoice details.',
  'organization.billing.vatId': 'VAT ID',
  'organization.billing.addPaymentMethod': 'Add payment method',
  'organization.billing.billingInformation': 'Billing information',

  // add subscription
  'organization.addSubscription.addNewPaymentMethod': 'Add a new payment method',
  'organization.addSubscription.addNewCreditOrDebitCard':
    'Add a new credit or debit card',

  // contract subscription card
  'organization.contractSubscriptionCard.contactUs':
    'Please contact us directly to discuss any aspect of your contract or service.',
  'organization.contractSubscriptionCard.additionalInfo': 'Additional info',
  'organization.contractSubscriptionCard.customContract': 'Custom contract',

  // credit card subscription
  'organization.creditCardSubscription.cardEndingIn': 'Card ending in {last4}.',
  'organization.creditCardSubscription.expires': 'Expires {expire}.',
  'organization.creditCardSubscription.addNewCard': 'Add new card',
  'organization.creditCardSubscription.addNewCreditOrDebitCard':
    'Add a new credit or debit card',
  'organization.creditCardSubscription.noCards': 'No cards',
  'organization.creditCardSubscription.creditOrDebitCard': 'Credit or debit card',
  'organization.creditCardSubscription.additionalInfo': 'Additional info',
  'organization.creditCardSubscription.conversionRate':
    'Usage linked to this subscription is charged in Euros, with a conversion rate of $1 = €0.9243.',

  // bank transfer subscription card
  'organization.bankTransferSubscriptionCard.conversionRate':
    'Usage linked to this subscription is charged in Euros, with a conversion rate of $1 = €0.9243.',
  'organization.bankTransferSubscriptionCard.additionalInfo': 'Additional info',
  'organization.bankTransferSubscriptionCard.bankTransfer': 'Bank transfer',

  // marketplace subscription card
  'organization.marketplaceSubscription.awsMarketplace':
    'Amazon Web Services Marketplace',
  'organization.marketplaceSubscription.azureMarketplace': 'Azure Marketplace',
  'organization.marketplaceSubscription.gcpMarketplace':
    'Google Cloud Platform Marketplace',
  'organization.marketplaceSubscription.aws': 'AWS',
  'organization.marketplaceSubscription.azure': 'Azure',
  'organization.marketplaceSubscription.gcp': 'GCP',
  'organization.marketplaceSubscription.referenceNumber': 'Reference number',
  'organization.marketplaceSubscription.additionalInfo': 'Additional info',

  // subscription card
  'organization.subscriptionCard.clusters': 'Clusters',
  'organization.subscriptionCard.noClusters': 'No clusters',

  // Usage
  'organization.consumption.pageTitle': 'Usage report',
  'organization.consumption.monthlyConsumptionSectionTitle': 'Monthly usage to date',
  'organization.consumption.monthlyConsumptionSectionText':
    'All services for the calendar month. This does not reflect your billing cycle.',
  'organization.consumption.currentConsumptionSectionTitle': 'Current usage',
  'organization.consumption.currentConsumptionSectionText':
    'Costs of all running services, before any discounts or credits are applied.',
  'organization.consumption.creditsUsedText': 'Credits applied',
  'organization.consumption.openBalanceText': 'Open Balance After Credits',
  'organization.consumption.creditsRemainingText': 'Credits remaining',
  'organization.consumption.creditsExpireText': 'Expires {date}',
  'organization.consumption.computeLineItemText':
    'Compute{product, select, none {} other { - {product}}}',
  'organization.consumption.costColumnTitle': 'Cost',

  // subscription detail
  'organization.subscriptionDetail.billingSnapshotTitle': 'Current billing snapshot',
  'organization.subscriptionDetail.billingPeriodTitle': 'Billing period',
  'organization.subscriptionDetail.paymentCardTitle': 'Payment card',
  'organization.subscriptionDetail.clustersTitle': 'Clusters',
  'organization.subscriptionDetail.noClustersText': 'No clusters',
  'organization.subscriptionDetail.marketplaceManage1':
    'You can manage your subscription on the ',
  'organization.subscriptionDetail.marketplaceManage2': ' website.',
  'organization.subscriptionDetail.marketplaceManageAWS': 'AWS Marketplace',
  'organization.subscriptionDetail.marketplaceManageAzure': 'Azure Marketplace',
  'organization.subscriptionDetail.marketplaceManageGCP': 'Google Cloud Platform',

  // payment methods
  'organization.paymentMethods.requestDeleteSubscriptionModalText':
    'Please submit this form to request deletion of subscription reference {subscription_id}. This will be passed to our customer service team who will deal with your request as soon as possible.',
  'organization.paymentMethods.bankCardLast4': 'Card ending in {last4}',
  'organization.paymentMethods.bankCardExpiry': 'Expires {month}/{year}',
  'organization.paymentMethods.bankCardDefaultText': 'DEFAULT',
  'organization.paymentMethods.bankCardMakeDefaultText': 'Make default',
  'organization.paymentMethods.addingBankCardText':
    'Securely adding your payment method',
  'organization.paymentMethods.addingBankTransferText':
    'Securely adding your payment method',
  'organization.paymentMethods.addingBankCardSuccessText':
    'Payment method securely added',
  'organization.paymentMethods.addingBankTransferSuccessText':
    'Payment method securely added',
  'organization.paymentMethods.addingBankCardFailureText': 'Payment method declined',
  'organization.paymentMethods.deletingBankCardText': 'Deleting card ending {last4}',
  'organization.paymentMethods.deletingBankCardSuccessText':
    'Card ending {last4} was successfully deleted',
  'organization.paymentMethods.updatingBankCardText':
    'Setting card ending {last4} as default',
  'organization.paymentMethods.updatingBankCardSuccessText':
    'Card ending {last4} will be used as your default bank card',

  // Clusters List
  'organization.clustersList.deployClusterButton': 'Deploy cluster',
  'organization.clustersList.clustersListEmptyHelp':
    'There are no clusters running in this organization',
  'organization.clustersList.pageTitle': 'Clusters',

  // organizationSettings
  'organization.organizationSettings.pageTitle': 'Settings',
  'organization.organizationSettings.settingsSectionTitle': 'General Settings',
  'organization.organizationSettings.enterOrganizationNameError':
    'Please enter an organization name.',
  'organization.organizationSettings.enterValidEmailError':
    'Please enter a valid email address.',
  'organization.organizationSettings.organizationLabel': 'Organization',
  'organization.organizationSettings.organizationIDLabel': 'ID: ',
  'organization.organizationSettings.notificationEmailLabel': 'Notification email',
  'organization.organizationSettings.notificationStatusLabel': 'Notification status',
  'organization.organizationSettings.organizationUpdatingText':
    'Updating your organization',
  'organization.organizationSettings.organizationUpdatedText':
    'You have successfully updated the organization',

  // organizationUsers
  'organization.organizationUsers.pageTitle': 'Users',
  'organization.organizationUsers.roleTitle': 'Role',
  'organization.organizationUsers.statusTitle': 'Status',
  'organization.organizationUsers.authMethodTitle': 'Authentication',
  'organization.organizationUsers.actionTitle': 'Actions',
  'organization.organizationUsers.addUserButton': 'Add user',
  'organization.organizationUsers.noUsersYetText': 'No users yet',
  'organization.organizationUsers.removeUserText':
    'Are you sure you want to remove this user?',
  'organization.organizationUsers.cancelInviteText':
    'Are you sure you want to cancel this invitation?',
  'organization.organizationUsers.expiredText': 'Expired',
  'organization.organizationUsers.expiresInText':
    'Expires in {expiresInHours} hours',
  'organization.organizationUsers.expiresInMinutesText':
    'Expires in {expiresInMinutes} minutes',
  'organization.organizationUsers.activeText': 'Active',
  'organization.organizationUsers.resendInvitationText': 'Resend invitation',
  'organization.organizationUsers.updatingUserText': 'Updating user.',
  'organization.organizationUsers.userUpdatedText': 'User updated successfully.',
  'organization.organizationUsers.cancellingInvitationText':
    'Cancelling invitation for {email}.',
  'organization.organizationUsers.invitationCancelledText':
    'Invitation for {email} has been cancelled.',
  'organization.organizationUsers.removingUserText':
    'Removing {email} from your organization.',
  'organization.organizationUsers.userRemovedText':
    '{email} has been removed from your organization.',
  'organization.organizationUsers.reInvitingUserText':
    'Re-inviting {email} to your organization.',
  'organization.organizationUsers.userReInvitedText':
    '{email} has been re-invited to your organization.',
  'organization.organizationUsers.authMethod.azuread': 'Microsoft',
  'organization.organizationUsers.authMethod.cognito': 'Password',
  'organization.organizationUsers.authMethod.github': 'GitHub',

  'organization.organizationUsers.inviteAddInProgress':
    'Adding you to the organization',
  'organization.organizationUsers.inviteAddSuccess':
    'You have been successfully added to this organization.',
  'organization.organizationUsers.inviteAddFailure':
    'Invitation not found or expired.',

  // regions
  'organization.regions.pageTitle': 'Regions',
  'organization.regions.requestNewRegionButton': 'Request new region',
  'organization.regions.createRegionButton': 'Create edge region',
  'organization.regions.upStatusText': 'Available',
  'organization.regions.downStatusText': 'Unavailable',
  'organization.regions.unknownStatusText': 'Unknown',
  'organization.regions.deployClusterButton': 'Deploy cluster',
  'organization.regions.edgeRegionText': 'Edge region',
  'organization.regions.installEdgeRegionButton': 'Install region',
  'organization.regions.installRegionCommandTitle':
    'Execute the following command to install this Edge region:',
  'organization.regions.upgradeEdgeRegionButton': 'Upgrade region',
  'organization.regions.upgradeRegionCommandTitle':
    'Execute the following command to upgrade this Edge region:',
  'organization.regions.createEdgeRegionTitle': 'Create new edge region',
  'organization.regions.createRegionDescriptionLabel': 'Region name',
  'organization.regions.createRegionDescriptionPlaceholder': 'Custom edge region',
  'organization.regions.createRegionValidationError':
    'Please enter a region description.',
  'organization.regions.createRegionStartedText': 'Creating a new region.',
  'organization.regions.createRegionSuccessText':
    'You have successfully created a new region.',
  'organization.regions.deleteEdgeRegionButton': 'Delete region',
  'organization.regions.deleteEdgeRegionTitle': 'Delete edge region',
  'organization.regions.deleteRegionInstructionsStep1Title': 'Step 1',
  'organization.regions.deleteRegionInstructionsStep2Title': 'Step 2',
  'organization.regions.confirmDeletionButton':
    'Please confirm you want to remove this region from CrateDB Cloud by entering the name below.',
  'organization.regions.cleanupEdgeTitle': 'Un-installing CrateDB Edge',
  'organization.regions.cleanupEdgeHelp':
    'Delete local resources on your Kubernetes cluster by running the script below:',
  'organization.regions.deleteEdgeRegionDisclaimerText':
    'Please be aware that deleting a region will permanently remove it from CrateDB Cloud.',
  'organization.regions.deleteRegionStartedText': 'Deleting your region.',
  'organization.regions.deleteRegionSuccessText':
    'You have successfully deleted your region.',
  'organization.regions.edge.createRegionBetaWarningText':
    'This feature is a Pre-release.',
  'organization.regions.edge.createRegionBetaParagraph1Text':
    'CrateDB Edge is currently in a Pre-Release phase. ' +
    'CrateDB Edge and related services are provided on an "as is" basis and may change at any time. ' +
    'Crate.io provides no guarantees or warranty regarding the usability or performance of CrateDB Edge. ' +
    'The CrateDB Cloud Service Level Agreement (SLA) is expressly disclaimed for the use of CrateDB Edge ' +
    'and related services. Crate.io  reserves the right to start charging or revise fee amounts at any time, ' +
    'at its sole discretion, under this Agreement or if after the Pre-Release phase ' +
    'CrateDB Edge is made generally available and incorporated into a Crate.io service. By using CrateDB Edge, ' +
    'you agree to these terms and conditions. ',
  'organization.regions.edge.createRegionBetaParagraph2Text':
    'Should you find any errors, bugs, or functionality problems while using ' +
    'the CrateDB Edge Pre-Release, please contact Crate.io via https://cratedb.com/contact/ ' +
    'or email support@crate.io.',
  'organization.regions.requestNewRegionModalTitle': 'Request a new region',
  'organization.regions.requestNewRegionPredefinedListLabel': 'Desired region',
  'organization.regions.requestNewRegionFreeText':
    'If your desired region is not in the above list, you can enter it here',
  'organization.regions.requestNewRegionConfirm1Text':
    'Thank you for contacting us with your request. We will review it and be in touch.',
  'organization.regions.requestNewRegionConfirm2Text':
    'In the mean time, please be aware you can launch your own edge region and deploy your own clusters on your own hardware at any time.',
  'organization.regions.requestNewRegionConfirm3Text':
    'Learn more about CrateDB Edge regions.',

  // subscriptionList
  'organization.subscriptionList.pendingNotificationTitle':
    'Pending subscription confirmation',
  'organization.subscriptionList.pendingNotificationDescriptionText':
    'Once we have confirmed your subscription we will continue setting you up.',
  'organization.subscriptionList.loadingSubscriptionsError':
    'An error occured loading one or more of your subscriptions',
  'organization.subscriptionList.chooseCloudProviderLabel':
    'Choose a cloud provider and create a new subscription',
  'organization.subscriptionList.getMoreInformationText': 'Get more information on ',
  'organization.subscriptionList.subscriptionsLink': 'subscriptions',
  'organization.subscriptionList.awsOption': 'Amazon AWS',
  'organization.subscriptionList.azureOption': 'Microsoft Azure',
  'organization.subscriptionList.useSubscriptionPromptTitle': 'Heads up',
  'organization.subscriptionList.useSubscriptionPromptDescriptionText':
    'In order to deploy a cluster you need a cloud subscription. Create a new subscription or choose an existing one.',
  'organization.subscriptionList.subscribedToContractPlanNotificationTitle':
    'Azure Contract Plan',
  'organization.subscriptionList.subscribedToContractPlanNotificationDescriptionText':
    "You've successfully subscribed to our Contract plan. Our Sales team will get in touch with you via your email shortly.",

  // subscriptionList > subscriptionsCardGrid
  'organization.subscriptionList.subscriptionCardsGrid.providerLabel': 'Provider',
  'organization.subscriptionList.subscriptionCardsGrid.typeLabel': 'Type',
  'organization.subscriptionList.subscriptionCardsGrid.stateLabel': 'Status',
  'organization.subscriptionList.subscriptionCardsGrid.accountIDLabel': 'Account-ID',

  'organization.subscriptionList.subscriptionCardsGrid.deployClusterButton':
    'Deploy cluster',
  'organization.subscriptionList.subscriptionCardsGrid.clusterAlreadyDeployedText':
    'Cluster Deployed',
  'organization.subscriptionList.subscriptionCardsGrid.AzureClusterAlreadyDeployedHelp':
    'To deploy additional clusters on Azure, a new Microsoft Azure subscription is required.',
  'organization.subscriptionList.subscriptionCardsGrid.notAllowedToDeployMultipleTrialClustersError':
    'You are only allowed to deploy one trial cluster.',

  'organization.subscriptionList.subscriptionCardsGrid.awsText': 'Amazon AWS',
  'organization.subscriptionList.subscriptionCardsGrid.azureText': 'Microsoft Azure',
  'organization.subscriptionList.subscriptionCardsGrid.cloudText': 'CrateDB Cloud',
  'organization.subscriptionList.subscriptionCardsGrid.stripeText': 'Stripe',

  'organization.subscriptionList.subscriptionCardsGrid.confirmedText': 'Active',
  'organization.subscriptionList.subscriptionCardsGrid.pendingText': 'Pending',
  'organization.subscriptionList.subscriptionCardsGrid.terminatingText':
    'Terminating',
  'organization.subscriptionList.subscriptionCardsGrid.suspendedText': 'Suspended',

  'organization.subscriptionList.subscriptionCardsGrid.payAsYouGoText':
    'Pay-as-you-go',
  'organization.subscriptionList.subscriptionCardsGrid.contractText': 'Contract',
  'organization.subscriptionList.subscriptionCardsGrid.trialText': 'Trial',

  'organization.subscriptionList.subscriptionCardsGrid.deployContractText':
    'In order to get you up and running we will get in contact with you shortly.',
  'organization.subscriptionList.subscriptionCardsGrid.clusterNameLabel': 'Name',
  'organization.subscriptionList.subscriptionCardsGrid.clusterPlanLabel': 'Plan',
  'organization.subscriptionList.subscriptionCardsGrid.cancelSubscriptionButton':
    'Cancel subscription',
  'organization.subscriptionList.subscriptionCardsGrid.confirmDeletionDisclaimerText':
    'Be aware that this action cannot be reversed. All clusters and data connected to this subscription will be lost. Billing information will still be available under the Billing tab.',
  'organization.subscriptionList.subscriptionCardsGrid.confirmDeletionText':
    'Please confirm the cancellation of this subscription by entering "{name}":',
  'organization.subscriptionList.subscriptionCardsGrid.confirmationDialogTitle':
    'Cancel subscription',
  'organization.subscriptionList.subscriptionCardsGrid.affectedClustersCountText':
    'Affected resources: {count}',

  // Billing
  'organization.billing.paymentMethodsListTitle': 'Billing method',
  'organization.billing.paymentMethodsListEmptyText':
    'There are no saved billing methods to show',
  'organization.billing.paymentMethodsListWidgetTitle': 'Credit Card',
  'organization.billing.paymentMethodsListLast4DigitsLabel': 'ending with {last4}',
  'organization.billing.setCardAsDefaultButton': 'Set as default',
  'organization.billing.defaultPaymentMethodText': 'Default',
  'organization.billing.addPaymentMethodButton': 'Add payment method',
  'organization.billing.billingInformationSectionTitle': 'Billing address',
  'organization.billing.EditBillingInformationUITitle': 'Edit billing information',
  'organization.billing.editBillingMethod.nameLabel': 'Name',
  'organization.billing.editBillingMethod.countryLabel': 'Country',
  'organization.billing.editBillingMethod.taxIdLabel': 'Tax ID',
  'organization.billing.emptyBillingAddressText': 'Please enter a billing address',
  'organization.billing.consumptionMeterBillingSnapshotTitle':
    'Current billing snapshot',
  'organization.billing.consumptionMeterTitle': 'Upcoming charges',
  'organization.billing.consumptionMeterUpcomingInvoiceTitle': 'Upcoming invoice',
  'organization.billing.consumptionMeterBillingPeriodTitle': 'Billing period',
  'organization.billing.consumptionMeterPreviousInvoiceTitle': 'Previous invoice',
  'organization.billing.consumptionMeterBillingRowSubtotalTitle': 'Subtotal',
  'organization.billing.consumptionMeterBillingRowPromotionTitle': 'Promotion: ',
  'organization.billing.consumptionMeterBillingRowPromotionProductDescriptionText':
    '{productDescription}',
  'organization.billing.consumptionMeterBillingRowFinalTotalTitle': 'Total',
  'organization.billing.consumptionMeterEmptyText':
    'There is no upcoming invoice to show',

  // secrets
  'organization.secrets.secretNameLabel': 'Secret name',
  'organization.secrets.typeLabel': 'Type',
  'organization.secrets.descriptionLabel': 'Description',
  'organization.secrets.accessKeyIdLabel': 'Access key',
  'organization.secrets.secretKeyLabel': 'Secret access key',
  'organization.secrets.connectionStringLabel': 'Connection string or SAS Token',
  'organization.secrets.secretNameDescriptionText':
    'A meaningful name for this secret, used to identify this secret in this console.',
  'organization.secrets.addSecretButton': 'Add secret',
  'organization.secrets.noSecretsDefinedText': 'No secrets defined',
  'organization.secrets.removeSecretText': 'Remove secret "{name}"?',
  'organization.secrets.addNewSecretTitle': 'Add a new secret',
  'organization.secrets.secretAddedText':
    'Secret "{name}" has been added successfully.',
  'organization.secrets.secretRemovedText': 'Secret "{name}" has been removed.',
  'organization.secrets.secretNameRequiredFieldText':
    'Secret name is a required field',
  'organization.secrets.accessKeyRequiredFieldText':
    'Access key ID is a required field',
  'organization.secrets.secretKeyRequiredFieldText':
    'Access key ID is a required field',
  'organization.secrets.connectionStringRequiredFieldText':
    'Connection string is a required field',
};

export default messages;
