const messages = {
  // organization context nav
  'sideMenu.organizationClustersListLink': 'Clusters',
  'sideMenu.organizationSettingsLink': 'Settings',
  'sideMenu.organizationUsersLink': 'Users',
  'sideMenu.organizationBillingLink': 'Billing',
  'sideMenu.organizationUsageLink': 'Usage',
  'sideMenu.organizationAuditLogsLink': 'Audit Logs',
  'sideMenu.organizationRegionsLink': 'Regions',

  // cluster context nav
  'sideMenu.backToClusters': 'Back to clusters',
  'sideMenu.clusterOverview': 'Overview',
  'sideMenu.clusterConsole': 'Console',
  'sideMenu.clusterAutomation': 'Automation',
  'sideMenu.clusterIntegrations': 'Integrations',
  'sideMenu.clusterImport': 'Import',
  'sideMenu.clusterExport': 'Export',
  'sideMenu.clusterBackups': 'Backups',
  'sideMenu.clusterScale': 'Scale',
  'sideMenu.clusterManage': 'Manage',
  'sideMenu.clusterTutorials': 'Tutorials',

  // help nav
  'sideMenu.documentationLink': 'Documentation',
  'sideMenu.communityLink': 'Community',
  'sideMenu.supportLink': 'Support Center',

  // misc
  'sideMenu.collapseMenuButton': 'Collapse',
  'sideMenu.expandMenuButton': 'Expand',
  'sideMenu.topBar.switchToText': 'Switch to',
  'sideMenu.topBar.logoutLink': 'Logout',
};

export default messages;
